/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { getSpreadSettingsByUserId, searchSettings, deleteSpreadSetting } from '../../../../redux/userSpread/userSpreadActions';
import { EditSpreadSettingModal } from './EditSpreadSettingModal';
import { AddSpreadSettingModal } from './AddSpreadSettingModal';
import { ModalContext } from '../../../../context';
import { useDebounce } from '../../../../hooks/useDebounce';
import { generateSortParams, SortWrapper, useTableSorting } from '../../../../helpers/sortHelper';
import SortIcon from '../../../../components/SortIcon';

export function SpreadSettings() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { showModal } = useContext(ModalContext);
  const [searchByPairName, setSearchByPairName] = useState();
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const settings = useSelector((state) => state.userSpread);

  const search = (value) => {
    dispatch(searchSettings(id, value));
  };

  useDebounce(searchByPairName, 300, search);

  useEffect(() => {
    dispatch(getSpreadSettingsByUserId(id, sortParams));
  }, [sortParams]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteSpreadSetting(id));
        }
      });
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('pairName')}>
            <div className="d-flex flex-row">
              <span className="d-flex ">Pair Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('pairName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="pairNane"
            value={searchByPairName}
            minLength="3"
            onChange={({ target: { value } }) => setSearchByPairName(value)}
          />
        </div>
      ),
      cell: ({ pairName }) => pairName,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('spreadMax')}>
          <div className="d-flex flex-row">
            <span>Spread Max</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('spreadMax')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: ({ spreadMax }) => spreadMax,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('spreadMin')}>
          <div className="d-flex flex-row">
            <span>Spread Min</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('spreadMin')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: ({ spreadMin }) => spreadMin,
    },
    {
      name: 'Actions',
      cell: ({
        pairName, spreadMax, spreadMin, _id, 
      }) => (
        <>
          <button
            type="button"
            onClick={() => {
              showModal({
                headerContent: <h3>EDIT SETTING</h3>,
                bodyContent: <EditSpreadSettingModal
                  userId={id}
                  data={{
                    pairName, spreadMax, spreadMin, _id, 
                  }}
                />,
              });
            }}
            className="btn btn-warning btn-sm me-1 p-1"
          >
            <FontAwesomeIcon icon={faPencil} className="header-icon" />
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleDelete(_id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </>

      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            <button
              type="button"
              className="btn-primary_light"
              onClick={() => {
                showModal({
                  headerContent: <h3>ADD SPREAD</h3>,
                  bodyContent: <AddSpreadSettingModal userId={id} />,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3 pl-30">
        <DataTable
          columns={columns}
          data={settings}
          theme="solarizedd"
          persistTableHead
        />
      </div>
    </div>
  );
};

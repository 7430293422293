import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button, Row, Col,
} from 'react-bootstrap';
import { colourStyles } from '../../../helpers/styles';
import { ModalContext } from '../../../context';
import { createCurrencyRule } from '../../../redux/currencyRule/currencyRuleActions';

export default function AddCurrencyModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [formData, setFormData] = useState({
    currency: null,
    countries: [],
  });

  const cfdAccountCurrencies = useSelector((state) => state.cfdAccountCurrencies);
  const countries = useSelector((state) => state.users.countries);

  const isBtnDisabled = !formData.currency && !formData.countries.length;

  const submit = () => {
    const data = {
      currencyId: formData.currency.value,
      countries: formData.countries.map((country) => country.label),
    };

    dispatch(createCurrencyRule(data));
    hideModal();
  };

  const handleSelectChange = (value, key) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Currency</label>
            <Select
              styles={colourStyles}
              options={cfdAccountCurrencies.map((currency) => ({
                value: currency._id,
                label: currency.symbol,
              }))}
              isSearchable
              onChange={(value) => handleSelectChange(value, 'currency')}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Countries</label>
            <Select
              styles={colourStyles}
              options={countries.map((country) => ({
                value: country._id,
                label: country.nicename,
              }))}
              isSearchable
              isMulti
              onChange={(value) => handleSelectChange(value, 'countries')}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
          <Button 
            variant="primary" 
            className="ms-2" 
            disabled={isBtnDisabled} 
            onClick={() => submit()}
          >
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_SALES_STATUSES,
  GET_SALES_STATUS,
  ADD_SALES_STATUS,
  EDIT_SALES_STATUS,
  DELETE_SALES_STATUS,
  TOGGLE_STATE,
  ERROR_STATE,
  GET_SALES_STATUSES_TABLE_DATA,
} from './salesStatusTypes';
import { apiHelper } from '../apiHelper';

export const exportFilteredDataToFile = async (query, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/sales-status/export', {
      query, columns, fileType,
    });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const getSalesStatusesTableData = ({
  limit,
  page, 
  sort = '{ "createdAt": -1 }', 
  query = {}, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/sales-status/table${url}`, '');

    if (res?.data?.success) {
      await dispatch({
        type: GET_SALES_STATUSES_TABLE_DATA,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getSalesStatuses = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/sales-status', '');
    if (res?.data && res?.data?.salesStatuses) {
      const { data } = res;
      await dispatch({
        type: GET_SALES_STATUSES,
        payload: data?.salesStatuses,
      });
    } else {
      await dispatch({
        type: GET_SALES_STATUSES,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getSalesStatus = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/sales-status/${id}`, '');
    if (res?.data && res?.data?.salesStatus) {
      const { data } = res;
      await dispatch({
        type: GET_SALES_STATUS,
        payload: data?.salesStatus,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addSalesStatus = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/sales-status/add', data);

    if (res?.data && res?.data?.salesStatus_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_SALES_STATUS,
        payload: data?.salesStatus_,
      });
    } else {
      await dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editSalesStatus = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/sales-status/${id}`, data);
    if (res?.data && res?.data?.salesStatus) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_SALES_STATUS,
        payload: data?.salesStatus,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteSalesStatus = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/sales-status/${id}`, '');
    if (res?.data && res?.data?.salesStatus) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_SALES_STATUS,
        payload: data?.salesStatus?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_VOIPS, GET_USER_VOIPS, ACTIVATE_VOIP, ADD_VOIP,
  UPDATE_VOIP,
  DELETE_VOIP,
  GET_VOIP_PROVIDERS,
} from './voipTypes';

export const getVoips = ({
  page, limit, sort, query,
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    if (query) {
      if (query.fullName) {
        url += `&fullName=${query.fullName}`;
      }

      if (query.provider && query.provider.length) {
        url += `&providerId=${query.provider}`;
      }

      if (query.extension) {
        url += `&extension=${query.extension}`;
      }

      if (query['brand name'] && query['brand name'].length) {
        url += `&brandId=${query['brand name']}`;
      }

      if (query.status && query.status.length) {
        url += `&status=${query.status}`;
      }
    }

    const res = await apiHelper('get', `/api/voip${url}`);
    if (res && res.data && res.data.success) {
      const { voips, totalCount } = res.data;

      dispatch({
        type: GET_VOIPS,
        payload: { voips, totalCount },
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't get voip list");
  }
};

export const getUserVoips = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/voip?userId=${id}&status=true`);
    if (res && res.data && res.data.success) {
      const { voips } = res.data;

      dispatch({
        type: GET_USER_VOIPS,
        payload: voips,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't get voip list");
  }
};

export const activateVoipById = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/voip/activate/${id || null}`);
    if (res && res.data && res.data.success) {
      const { message } = res.data;

      dispatch({
        type: ACTIVATE_VOIP,
        payload: id ?? null,
      });

      toast.success(message);
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't activate voip");
  }
};

export const createVoip = (data, uId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/voip', data);
    if (res && res.data && res.data.success) {
      const { message, voip } = res.data;

      dispatch({
        type: ADD_VOIP,
        payload: { voip, id: uId },
      });

      toast.success(message);
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't add voip");
  }
};

export const editVoip = (id, userId, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/voip/${id}`, data);

    if (res && res.data && res.data.success) {
      const { message, voip } = res.data;

      dispatch({
        type: UPDATE_VOIP,
        payload: { voip, userId },
      });

      toast.success(message);
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't update voip");
  }
};

export const deleteVoipById = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/voip/${id}`);

    if (res && res.data && res.data.success) {
      const { message, voip } = res.data;

      dispatch({
        type: DELETE_VOIP,
        payload: voip._id,
      });

      toast.success(message);
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't delete voip");
  }
};

export const getVoipProviders = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/voip-providers');

    if (res && res.data && res.data.success) {
      const { voipProviders } = res.data;

      dispatch({
        type: GET_VOIP_PROVIDERS,
        payload: voipProviders,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't get voip providers");
  }
};

export const exportFilteredDataToFile = async (filteredItems, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/voip/export', {
      filteredItems, columns, fileType,
    });

    if (res && res.data && res.data.success) {
      const { message, fileUrl } = res.data;

      toast.success(message);

      window.open(fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

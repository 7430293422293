export const emailTemplatesColumns = [
  {
    name: 'Brand',
    field: 'brandId',
    selected: true,
  },
  {
    name: 'Event',
    field: 'event',
    selected: true,
  },
  {
    name: 'Title',
    field: 'title',
    selected: true,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
  

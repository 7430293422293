export const variantOptions = [
  { key: 'default', value: 'default', label: 'Default' },
  { key: 'warning', value: 'warning', label: 'Warning' },
  { key: 'error', value: 'error', label: 'Error' },
];

export const optionsAlias = [
  { key: 'Alert message', value: 'Alert message', label: 'Alert Message' },
  { key: 'Self User Registration Assign', value: 'Self User Registration Assign', label: 'Self User Registration Assign' },
  { key: 'Monthly inactivity fee', value: 'Monthly inactivity fee', label: 'Monthly Inactivity Fee' },
  { key: 'Running Advertisement', value: 'Running Advertisement', label: 'Running Advertisement' },
  { key: 'Email Verification', value: 'Email Verification', label: 'Email Verification' },
  { key: 'Hide columns on platform', value: 'Hide columns on platform', label: 'Hide Columns on platform' },
  { key: 'Start Asset on Platform', value: 'Start Asset on Platform', label: 'Start Asset on Platform' },
  { key: 'Default currency for default CFD account', value: 'Default currency for default CFD account', label: 'Default currency for default CFD account' },
  { key: 'Auto logout time', value: 'Auto logout time', label: 'Auto logout time' },
  { key: 'Display Verification Notification', value: 'Display Verification Notification', label: 'Display Verification Notification' },
  { key: 'Promote User on Deposit', value: 'Promote User on Deposit', label: 'Promote User on Deposit' },
];

export const columnsOnPlatform = [
  { key: 'No.', value: 'No.', label: 'No.' },
  { key: 'Symbol', value: 'Symbol', label: 'Symbol' },
  { key: 'Id', value: 'Id', label: 'Id' },
  { key: 'Type', value: 'Type', label: 'Type' },
  { key: 'Value', value: 'Value', label: 'Value' },
  { key: 'Open Price', value: 'Open Price', label: 'Open Price' },
  { key: 'TP', value: 'TP', label: 'TP' },
  { key: 'SL', value: 'SL', label: 'SL' },
  { key: 'Open Time', value: 'Open Time', label: 'Open Time' },
  { key: 'Price', value: 'Price', label: 'Price' },
  { key: 'Commission', value: 'Commission', label: 'Commission' },
  { key: 'Close Price', value: 'Close Price', label: 'Close Price' },
  { key: 'Close Time', value: 'Close Time', label: 'Close Time' },
  { key: 'Swap', value: 'Swap', label: 'Swap' },
  { key: 'PnL', value: 'PnL', label: 'PnL' },
  { key: 'Actions', value: 'Actions', label: 'Actions' },
];

export const handlePercentageValue = (value) => {
  let sanitizedValue = value.replace(/[^\d]/g, '');

  sanitizedValue = sanitizedValue.replace(/^0+/, '');

  const numberValue = parseInt(sanitizedValue, 10);
  if (Number.isNaN(numberValue) || numberValue < 0) {
    return '0';
  } if (numberValue > 100) {
    return '100';
  }

  return numberValue.toString();
};



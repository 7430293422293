export const smtpColumns = [
  {
    name: 'Agent',
    field: 'agentName',
    selected: true,
  },
  {
    name: 'Provider',
    field: 'provider',
    selected: true,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
  },
  {
    name: 'Password',
    field: 'password',
    selected: true,
  },
  {
    name: 'Description',
    field: 'description',
    selected: true,
  },
  {
    name: 'From',
    field: 'from',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];

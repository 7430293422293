import { 
  GET_CURRENCY_RULES_TABLE, 
  CREATE_CURRENCY_RULE, 
  UPDATE_CURRENCY_RULE,
  DELETE_CURRENCY_RULE,
} from './currencyRuleTypes';

const initialState = {
  currencies: [],
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY_RULES_TABLE:
      return {
        currencies: action.payload.currencies,
        totalCount: action.payload.totalCount,
      };
    case CREATE_CURRENCY_RULE:
      return {
        currencies: [action.payload, ...state.currencies],
        totalCount: state.totalCount + 1,
      };
    case UPDATE_CURRENCY_RULE:
      return {
        ...state,
        currencies: state.currencies.map((currency) => (currency._id === action.payload._id ? action.payload : currency)),
      };
    case DELETE_CURRENCY_RULE:
      return {
        currencies: state.currencies.filter((currency) => currency._id !== action.payload),
        totalCount: state.totalCount - 1,
      };
    default:
      return state;
  }
};

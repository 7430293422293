import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { selectWithdrawalMethodsOptions } from '../../../../../redux/withdrawalMethods/withdrawalMethodsSelectors';
import { createUserWithdrawalTypes } from '../../../../../redux/userWithdrawalTypes/userWithdrawalTypesActions';

const isEnableOptions = [
  { type: 'Enable', value: true },
  { type: 'Disable', value: false },
];

export function ModalUserWithdrawalMethods({ hideModal, userId }) {
  const dispatch = useDispatch();
  const withdrawalMethodsOptions = useSelector(selectWithdrawalMethodsOptions);
  const [formData, setFormData] = useState({
    isEnabled: false,
    withdrawalTypesId: '',
  });

  useEffect(() => {
    if (!withdrawalMethodsOptions && !Array.isArray(withdrawalMethodsOptions)) return;
    const [firstElement] = withdrawalMethodsOptions;
    setFormData((prev) => ({ ...prev, withdrawalTypesId: firstElement._id }));
  }, [withdrawalMethodsOptions]);

  const submit = async () => {
    await dispatch(createUserWithdrawalTypes(userId, formData));
    hideModal();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label" htmlFor="withdrawalTypes">Withdrawal Method Type</label>
          <Select
            className="form-control"
            value={formData.withdrawalTypesId}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, withdrawalTypesId: value }))}
            name="withdrawalTypes"
            style={{ height: '40px' }}
          >
            { 
              withdrawalMethodsOptions.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>{name}</MenuItem>
              ))
            }
          </Select>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label" htmlFor="enable">Enable</label>
          <Select
            className="form-control"
            value={formData.isEnabled}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, isEnabled: value }))}
            name="enable"
            style={{ height: '40px' }}
          >
            { 
              isEnableOptions.map(({ type, value }) => (
                <MenuItem key={type} value={value}>{type}</MenuItem>
              ))
            }
          </Select>
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={submit}>Add</button>
        <button type="button" className="btn btn-danger" onClick={hideModal}>Cancel</button>
      </div>
    </>
  );
}

import { toast } from 'react-toastify';
import {
  GET_WHITELIST_IPS,
  GET_WHITELIST_IP,
  ADD_WHITELIST_IP,
  EDIT_WHITELIST_IP,
  DELETE_WHITELIST_IP,
  TOGGLE_WHITELIST_STATE,
  ERROR_WHITELIST_STATE,
  GET_WHITELIST_IP_TABLE_DATA,
} from './whitelistIPTypes';
import { apiHelper } from '../apiHelper';

export const exportFilteredDataToFile = async (query, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/whitelist-ip/export', {
      query, columns, fileType,
    });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const getWhitelistIPTableData = ({ 
  page, 
  limit,
  sort = '{ "createdAt": -1 }', 
  query = {}, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/whitelist-ip/table${url}`, '');

    if (res && res.data && res.data.success) {
      const { whitelist, totalCount } = res.data;
      dispatch({
        type: GET_WHITELIST_IP_TABLE_DATA,
        payload: { whitelist, totalCount },
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getWhitelistIPs = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/whitelist-ip', '');
    if (res && res.data && res.data.whitelistIPs) {
      const { whitelistIPs } = res.data;
      await dispatch({
        type: GET_WHITELIST_IPS,
        payload: whitelistIPs,
      });
    } else {
      await dispatch({
        type: GET_WHITELIST_IPS,
        payload: [],
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getWhitelistIP = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/whitelist-ip/${id}`, '');
    if (res?.data && res?.data?.whitelistIP) {
      const { data } = res;
      await dispatch({
        type: GET_WHITELIST_IP,
        payload: data?.whitelistIP,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addWhitelistIP = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/whitelist-ip/add', data);

    if (res && res.data && res.data.success) {
      const { message, whitelistIP } = res.data;
      toast.success(message);
      await dispatch({
        type: ADD_WHITELIST_IP,
        payload: whitelistIP,
      });
    } else {
      await dispatch({
        type: ERROR_WHITELIST_STATE,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editWhitelistIP = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/whitelist-ip/${id}`, data);
    if (res && res.data && res.data.success) {
      const { message, whitelistIP } = res.data;
      toast.success(message);
      
      await dispatch({
        type: EDIT_WHITELIST_IP,
        payload: whitelistIP,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteWhitelistIP = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/whitelist-ip/${id}`, '');
    if (res && res.data && res.data.success) {
      const { message, whitelistIP } = res.data;
      toast.success(message);
      await dispatch({
        type: DELETE_WHITELIST_IP,
        payload: whitelistIP._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_WHITELIST_STATE,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

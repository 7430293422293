import { toast } from 'react-toastify';
import {
  GET_CURRENCY_RULES_TABLE, 
  CREATE_CURRENCY_RULE, 
  UPDATE_CURRENCY_RULE,
  DELETE_CURRENCY_RULE,
} from './currencyRuleTypes';
import { apiHelper } from '../apiHelper';

export const exportFilteredDataToFile = async (query, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/currency-rule/export', {
      query, columns, fileType,
    });
  
    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }
  
    return res.data;
  } catch (error) {
    toast.error(error.response.message);
  
    return error;
  }
};

export const getCurrencyRulesTable = ({
  page, 
  limit,
  sort = '{ "createdAt": -1 }', 
  query = {}, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/currency-rule/table${url}`, '');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CURRENCY_RULES_TABLE,
        payload: res.data,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const createCurrencyRule = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/currency-rule', data);
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: CREATE_CURRENCY_RULE,
        payload: res.data.currency,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateCurrencyRule = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/currency-rule/${id}`, data);
        
    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_CURRENCY_RULE,
        payload: res.data.currency,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteCurrencyRule = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/currency-rule/${id}`, '');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_CURRENCY_RULE,
        payload: id,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { endOfDay, startOfDay } from 'date-fns';
import { createDay, parseDayIntoHours } from '../../helpers/calendar';
import styles from './styles.module.css';
import { getEventsByDateRange } from '../../../../redux/event/eventActions';
import { HourCell } from './HourCell';
import { MiniCalendar } from './MiniCalendar';

export function DayCalendar({
  selectedDate, setShowEditEventModal, setFocusedEvent, focusedEvent, setSelectedDate, sortQuery,
}) {
  const dispatch = useDispatch();
  const currentDate = new Date();

  const allEvents = useSelector((state) => state.events);
  const uid = useSelector((state) => state.crmUser.crmUserInfo._id);
  const day = createDay(selectedDate, currentDate, selectedDate, allEvents);
    
  const [draggableEvent, setDraggableEvent] = useState();
  const { date, events } = day;
    
  useEffect(() => {
    const start = startOfDay(selectedDate);
    const end = endOfDay(selectedDate);

    if (uid && end && start && sortQuery) {
      dispatch(getEventsByDateRange(
        { 
          id: uid, 
          end: new Date(end).valueOf(), 
          start: new Date(start).valueOf(),
          sortQuery,
        },
      ));
    }
  }, [selectedDate, uid, sortQuery]);

  return (
    <div className={styles.calendar}>
      <div className={styles.calendarBody}>
        <div className={styles.calendarHoursWrapper}>
          {parseDayIntoHours(date, events).map((item, index = 0) => (
            <HourCell 
              key={`hour-${index}`}
              hour={item}
              setShowEditEventModal={setShowEditEventModal}
              setFocusedEvent={setFocusedEvent}
              focusedEvent={focusedEvent}
              setDraggableEvent={setDraggableEvent}
              draggableEvent={draggableEvent}
            /> 
          ))}
        </div>
      </div>
      <MiniCalendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
    </div>
  );
}

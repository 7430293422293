import React, {
  useEffect,
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditorComponent from '../EditorComponent/EditorComponent';
import { createEmailTemplate } from '../../../redux/emailTemplates/emailTemplatesActions';

const DEFAULT_MAIL_HTML_BODY = '<p><span style="font-size: 16px;">Dear %fullName%,<br><br>Best Regards, <br>%companyName% Team  </span></p>\n';
const DEFAULT_MAIL_JSON_BODY = '{"blocks":[{"key":"5go7p","text":"Dear %fullName%,\\n\\nBest Regards, \\n%companyName% Team  ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":53,"style":"fontsize-16"}],"entityRanges":[],"data":{}}],"entityMap":{}}';

function ManualTemplateCreate({ hideModal }) {
  const dispatch = useDispatch();
  
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(true);
  const [brand, setBrand] = useState('');
  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState(DEFAULT_MAIL_HTML_BODY);
  const [mailJsonBodyStringified, setMailJsonBodyS] = useState(DEFAULT_MAIL_JSON_BODY);

  const brands = useSelector((state) => state.brands);
  
  const { handleSubmit } = useForm();
  
  const handleSave = async () => {
    const data = {
      event: 'manualSend',
      title,
      status,
      brandId: brand,
      mailBodyJson: mailJsonBodyStringified,
      mailBodyHtml: mailHtmlBodyStringified,
    };
  
    dispatch(createEmailTemplate(data, hideModal));
  };
  
  useEffect(() => {
    if (brands.length !== 0 && brand === '') {
      setBrand(brands[0]._id);
    }
  }, [brands]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="col-xl-12 col-lg-12">
        <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>
  
          <div className="form-group">
            <label className="lead" htmlFor="label">Title</label>
            <input
              type="text"
              name="title"
              value={title}
              className="form-control"
              placeholder="Enter here..."
              onInput={(e) => setTitle(e.target.value)}
            />
          </div>
          <br />

          <div className="form-group">
            <label className="lead">Brand</label>
            <select className="form-control user-status-select" name="showFtd" value={brand} onChange={({ target: { value } }) => setBrand(value)}>
              {brands.map((brand) => <option value={brand._id}>{brand.name}</option>)}
            </select>
          </div>
          <br />
  
          <div className="form-group">
            <label className="lead">Status</label>
            <select className="form-control user-status-select" name="showFtd" value={status} onChange={({ target: { value } }) => setStatus(value === 'true')}>
              <option value>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <br />
  
          <EditorComponent
            template={{
              mailBodyHtml: DEFAULT_MAIL_HTML_BODY,
              mailBodyJson: DEFAULT_MAIL_JSON_BODY,
            }}
            setMailHtmlBodyS={setMailHtmlBodyS}
            setMailJsonBodyS={setMailJsonBodyS}
          />
  
          <br />
          <div className="d-flex justify-content-end">
            <button className="btn-default" type="submit">Create Email Template</button>
          </div>
        </form>
      </div>
    </div>
  );
}
  
export default ManualTemplateCreate;
  

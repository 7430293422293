/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_CRM_ACTIVITIES,
  GET_CRM_ACTIVITY,
  ADD_CRM_ACTIVITY,
  EDIT_CRM_ACTIVITY,
  DELETE_CRM_ACTIVITY,
  TOGGLE_CRM_ACTIVITY_STATE,
  ERROR_CRM_ACTIVITY_STATE,
  GET_CRM_USER_ACIVITY,
} from './crmActivityTypes';
import { apiHelper } from '../apiHelper';

export const getCRMActivities = ({
  page, rowsPerPage, userFilters, sortParams,
}) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/crm-activity-log?page=${page}&rowsPerPage=${rowsPerPage}&filters=${JSON.stringify(userFilters)}&sort=${sortParams}`);
    if (res && res.data && res.data.success) {
      await dispatch({
        type: GET_CRM_ACTIVITIES,
        payload: res.data.crmActivityLogs,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCRMActivity = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/crm-activity-log/${id}`, '');
    if (res?.data && res?.data?.crmActivityLog) {
      const { data } = res;
      await dispatch({
        type: GET_CRM_ACTIVITY,
        payload: data?.crmActivityLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getCRMUserActivity = ({
  page, limit, id, query = {}, sortParams,
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sortParams}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/crm-activity-log/crm-user-activity/${id}${url}`, '');

    if (res && res.data) {
      const { data } = res;
      await dispatch({
        type: GET_CRM_USER_ACIVITY,
        payload: data?.paginatedData,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addCRMActivity = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/crm-activity-log/add', data);

    if (res?.data && res?.data?.crmActivityLog_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_CRM_ACTIVITY,
        payload: data?.crmActivityLog_,
      });
    } else {
      await dispatch({
        type: ERROR_CRM_ACTIVITY_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editCRMActivity = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/crm-activity-log/${id}`, data);
    if (res?.data && res?.data?.crmActivityLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_CRM_ACTIVITY,
        payload: data?.crmActivityLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteCRMActivity = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/crm-activity-log/${id}`, '');
    if (res?.data && res?.data?.crmActivityLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_CRM_ACTIVITY,
        payload: data?.crmActivityLog?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateCRMActivityState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_CRM_ACTIVITY_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

// eslint-disable-next-line no-unused-vars
export const exportCRMActivityToFile = (fileType, page, rowsPerPage, userFilters) => async () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const res = await apiHelper('post', '/api/crm-activity-log/export', {
      fileType,
      page,
      rowsPerPage,
      filters: userFilters,
    }, {}, 'application/json', { 'x-timezone': timezone });
    if (res?.data) {
      const { data } = res;
      if (data?.csvFile) {
        window.open(data?.csvFile);
      }
      if (data?.xslxFile) {
        window.open(data?.xslxFile);
      }
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  selectIsDepositMethodsLoading,
  selectDepositMethods,
  selectBrandSearchFilter,
  selectDepositMethodSearchFilter,
  selectCountriesIncludedSearchFilter,
  selectDepositMethodsTotalCount,
  selectCountriesExcludedSearchFilter,
} from '../../redux/depositMethods/depositMethodsSelectors';
import {
  fetchDepositMethods, setBrandFilterCreator, setCountriesIncludedFilterCreator, setDepositMethodFilterCreator, setCountriesExcludedFilterCreator,
} from '../../redux/depositMethods/depositMethodsActions';
import { DepositMethodsColumns } from '../../DataTableSchemas/DepositMethodsColumns';
import { DatatableFilters } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { generateSortParams } from '../../helpers/sortHelper';

function DepositMethods() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);

  const paginationStorageName = 'DataTable_depositMethods/listing_pagination';
  const filtersStorageName = 'DataTable_depositMethods/filters'; 
  const filterIdStorageName = 'DataTable_depositMethods/filters_id';
  const depositMethodsStorageFiltersJSON = localStorage.getItem(filtersStorageName);

  // templates variables
  const depositMethods = useSelector(selectDepositMethods);
  const isDepositMethodsLoading = useSelector(selectIsDepositMethodsLoading);

  // filters variables
  const brandSearchFilter = useSelector(selectBrandSearchFilter);
  const countriesIncludedSearchFilter = useSelector(selectCountriesIncludedSearchFilter);
  const countriesExcludedSearchFilter = useSelector(selectCountriesExcludedSearchFilter);
  const depositMethodSearchFilter = useSelector(selectDepositMethodSearchFilter);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectDepositMethodsTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [depositMethodsQuery, setDepositMethodsQuery] = useState({});
  const [sortParams, setSortParams] = useState(JSON.stringify({ name: -1 }));

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setCRMFilters = (filter) => {
    const { 
      brand, 
      currency, 
      includedCountries,
      excludedCountries,
    } = filter;

    setDepositMethodsQuery({
      brand,
      'deposit method': currency,
      'included countries': includedCountries,
      'excluded countries': excludedCountries,
    });

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const handleClear = () => {
    dispatch(setBrandFilterCreator([]));
    dispatch(setDepositMethodFilterCreator([]));
    dispatch(setCountriesIncludedFilterCreator([]));
    dispatch(setCountriesExcludedFilterCreator([]));

    setDepositMethodsQuery({});

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };


  const refresh = async () => {
    dispatch(fetchDepositMethods(page, rowsPerPage, sortParams, {
      brands: depositMethodsQuery.brand,
      name: depositMethodsQuery['deposit method'],
      included_countries: depositMethodsQuery['included countries'],
      excluded_countries: depositMethodsQuery['excluded countries'],
    }));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      brand: filters.brand || [],
      currency: filters['deposit method'] || [],
      includedCountries: filters['included countries'] || [],
      excludedCountries: filters['excluded countries'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        brand: filters.brand || [],
        currency: filters['deposit method'] || [],
        includedCountries: filters['included countries'] || [],
        excludedCountries: filters['excluded countries'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (depositMethodsStorageFiltersJSON) {
      const filters = JSON.parse(depositMethodsStorageFiltersJSON);
      setDepositMethodsQuery(filters || {});

      dispatch(setBrandFilterCreator(filters.brand || []));
      dispatch(setDepositMethodFilterCreator(filters['deposit method'] || []));
      dispatch(setCountriesIncludedFilterCreator(filters['included countries'] || []));
      dispatch(setCountriesExcludedFilterCreator(filters['excluded countries'] || []));
    }
  };

  useEffect(() => {
    localStorage.setItem(filtersStorageName, JSON.stringify(depositMethodsQuery));
    dispatch(fetchDepositMethods(page, rowsPerPage, sortParams, {
      brands: depositMethodsQuery.brand,
      name: depositMethodsQuery['deposit method'],
      included_countries: depositMethodsQuery['included countries'],
      excluded_countries: depositMethodsQuery['excluded countries'],
    }));
  }, [
    page, rowsPerPage, sortParams, depositMethodsQuery,
  ]);
  
  useEffect(() => {
    setStoredPagination();
    setStoredFilterData();
  }, []);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const depositMethodsCol = DepositMethodsColumns({
    depositMethodsQuery,
    setDepositMethodsQuery,
    brandSearchFilter,
    countriesIncludedSearchFilter,
    depositMethodSearchFilter,
    handleSort,
    countriesExcludedSearchFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Deposit Methods</h3>

        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {crmFilters && (
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              /> 
              )}

              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>

              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={depositMethodsCol}
                  data={depositMethods}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isDepositMethodsLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default DepositMethods;

import React, {
  useCallback,
  useContext,
  useEffect, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MultiSelect } from 'react-multi-select-component';
import { getBrands } from '../../../redux/brand/brandActions';
import { fetchCounties } from '../../../redux/countries/countriesActions';
import { fetchWithdrawalMethodsOptions, updateWithdrawalMethod } from '../../../redux/withdrawalMethods/withdrawalMethodsActions';
import { selectWithdrawalMethodsOptions } from '../../../redux/withdrawalMethods/withdrawalMethodsSelectors';
import { ModalContext } from '../../../context';

function EditWithdrawalMethods({
  id, name, status,
  brand_details = [], 
  included_countries_details = [],
  excluded_countries_details = [],
}) {
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState(status);
  const [selectedBrands, setSelectedBrands] = useState(
    brand_details.map(({ _id, name }) => ({ value: _id, label: name })),
  );
  const [selectedIncludedCountries, setSelectedIncludedCountries] = useState(
    included_countries_details.map(({ _id, nicename }) => ({ value: _id, label: nicename })),
  );
  const [selectedExcludedCountries, setSelectedExcludedCountries] = useState(
    excluded_countries_details.map(({ _id, nicename }) => ({ value: _id, label: nicename })),
  );

  const { handleSubmit } = useForm();
  const { hideModal } = useContext(ModalContext);

  const brands = useSelector((state) => state.brands);
  const countries = useSelector((state) => state.countries.countries);
  const withdrawalMethodsOptions = useSelector(selectWithdrawalMethodsOptions);

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    if (!countries.length) dispatch(fetchCounties());
    if (!withdrawalMethodsOptions.length) dispatch(fetchWithdrawalMethodsOptions());
  }, []);

  const handleSave = async () => {
    const data = {
      name,
      brandIds: selectedBrands,
      status: selectedStatus,
      includedCountries: selectedIncludedCountries,
      excludedCountries: selectedExcludedCountries,
    };
    await dispatch(updateWithdrawalMethod(id, data));
    hideModal();
  };

  const getAllBrandsOptions = useCallback((brands) => brands.map(({ _id, name }) => ({
    value: _id,
    label: name,
  })), []);

  const getAllIncludedCountryOptions = useCallback((countries) => countries.map(({ _id, nicename }) => ({
    value: _id,
    label: nicename,
  })), []);

  const [brandsOptions, setBrandsOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);

  useEffect(() => {
    const options = getAllBrandsOptions(brands);
    setBrandsOptions(options);
  }, [brands]);
  
  useEffect(() => {
    const options = getAllIncludedCountryOptions(countries);
    setCountriesOptions(options);
  }, [countries]);

  return (
    <div>
      <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>

        <div className="form-group custom-milti-select">
          <label className="lead">Brands</label>
          <MultiSelect
            name="brands"
            options={brandsOptions}
            value={selectedBrands}
            onChange={setSelectedBrands}
            labelledBy="Select"
            includeSelectAllOption="false"
          />
        </div>
        <br />

        <div className="form-group custom-milti-select">
          <label className="lead">Included Countries</label>
          <MultiSelect
            name="includedCountries"
            options={countriesOptions}
            value={selectedIncludedCountries}
            onChange={setSelectedIncludedCountries}
            labelledBy="Select"
            includeSelectAllOption="false"
          />
        </div>
        <br />

        <div className="form-group custom-milti-select">
          <label className="lead">Excluded Countries</label>
          <MultiSelect
            name="excludedCountries"
            options={countriesOptions}
            value={selectedExcludedCountries}
            onChange={setSelectedExcludedCountries}
            labelledBy="Select"
            includeSelectAllOption="false"
          />
        </div>
        <br />

        <div className="d-flex justify-content-end">
          <button className="btn-default" type="submit">Update Withdrawal Method</button>
        </div>
      </form>
    </div>
  );
}

export default EditWithdrawalMethods;

import {
  SET_WITHDRAWAL_METHODS,
  SET_IS_WITHDRAWAL_METHODS_LOADING,
  UPDATE_WITHDRAWAL_METHOD,
  SET_BRAND_SEARCH_FILTER,
  SET_WITHDRAWAL_METHOD_SEARCH_FILTER,
  SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  SET_COUNTRIES_EXCLUDED_SEARCH_FILTER,
  SET_TOTAL_COUNT_WITHDRAWAL_METHODS,
  SET_WITHDRAWAL_METHODS_OPTIONS,
} from './withdrawalMethodsTypes';

const initialState = {
  withdrawalMethods: [],
  isWithdrawalMethodsLoading: false,
  brandSearchFilter: [],
  withdrawalMethodSearchFilter: [],
  countriesIncludedSearchFilter: [],
  countriesExcludedSearchFilter: [],
  totalCount: null,
  withdrawalMethodsOptions: [],
};

const withdrawalMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_METHODS_OPTIONS:
      return {
        ...state,
        withdrawalMethodsOptions: action.payload,
      };
    case SET_TOTAL_COUNT_WITHDRAWAL_METHODS:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_BRAND_SEARCH_FILTER:
      return {
        ...state,
        brandSearchFilter: action.payload,
      };
    case SET_WITHDRAWAL_METHOD_SEARCH_FILTER:
      return {
        ...state,
        withdrawalMethodSearchFilter: action.payload,
      };
    case SET_COUNTRIES_INCLUDED_SEARCH_FILTER:
      return {
        ...state,
        countriesIncludedSearchFilter: action.payload,
      };
    case SET_COUNTRIES_EXCLUDED_SEARCH_FILTER:
      return {
        ...state,
        countriesExcludedSearchFilter: action.payload,
      };
    case SET_WITHDRAWAL_METHODS:
      return {
        ...state,
        withdrawalMethods: action.payload,
      };
    case SET_IS_WITHDRAWAL_METHODS_LOADING:
      return {
        ...state,
        isWithdrawalMethodsLoading: action.payload,
      };
    case UPDATE_WITHDRAWAL_METHOD:
      const updatedMethods = state.withdrawalMethods.map((method) => (method._id === action.payload._id ? { ...method, ...action.payload } : method));
      return {
        ...state,
        withdrawalMethods: updatedMethods,
      };
    default:
      return state;
  }
};

export default withdrawalMethodsReducer;

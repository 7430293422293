export const GET_CFD_ORDERS = 'GET_CFD_ORDERS';
export const UPDATE_CFD_ORDER = 'UPDATE_CFD_ORDER';
export const REVERT_CFD_ORDER = 'REVERT_CFD_ORDER';
export const MASS_REVERT_CFD_ORDER = 'MASS_REVERT_CFD_ORDER';
export const OPEN_CFD_ORDER = 'OPEN_CFD_ORDER';
export const STOP_CFD_ORDER = 'STOP_CFD_ORDER';
export const MASS_CFD_ORDER_CREATION = 'MASS_CFD_ORDER_CREATION';
export const MASS_CFD_ORDER_STOP = 'MASS_CFD_ORDER_STOP';
export const MASS_CFD_ORDER_EDIT = 'MASS_CFD_ORDER_EDIT';
export const All_CLOSED_ORDERS_SUM = 'All_CLOSED_ORDERS_SUM';
export const All_OPEN_ORDERS = 'All_OPEN_ORDERS';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';

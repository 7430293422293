import { toast } from 'react-toastify';
import {
  GET_CHATS,
  GET_CHAT,
  SEND_MESSAGE,
  GET_MESSAGE,
  DELETE_CHAT,
  DELETE_CHAT_MESSAGE,
  READ_MESSAGES,
} from './chatTypes';
import { apiHelper } from '../apiHelper';

export const readMessages = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/chat/read', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: READ_MESSAGES,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getChats = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/chat/${id}`, '');

    if (res && res.data) {
      dispatch({
        type: GET_CHATS,
        payload: res.data.chats || {},
      });
    } 
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getChat = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/chat/active/${id}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CHAT,
        payload: res.data.chat,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearActiveChat = () => (dispatch) => {
  dispatch({
    type: GET_CHAT,
    payload: {},
  });
};

export const addChat = async (data) => {
  try {
    const res = await apiHelper('post', '/api/chat/add', data);
    if (res && res.data && res.data.success) {
      return res.data.chat;
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getMessage = (id, message) => (dispatch) => {
  dispatch({
    type: GET_MESSAGE,
    payload: { receiver: id, message },
  });
};

export const sendMessage = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/chat/message/${id}`, data);
    if (res && res.data && res.data.success) {
      dispatch({
        type: SEND_MESSAGE,
        payload: res.data.message,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteChat = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/chat/${id}`);
    if (res?.data && res?.data?.success) {
      toast.success(res.data.message);
      dispatch({
        type: DELETE_CHAT,
        payload: res.data.deletedChat,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteChatMessage = (messageId) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/chat/message/${messageId}`);
    if (res?.data && res?.data?.success) {
      toast.success(res.data.message);
      dispatch({
        type: DELETE_CHAT_MESSAGE,
        payload: res.data.deletedMessageId,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const kycColumns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Status',
    field: 'verifiedStatus',
    selected: true,
  },
  {
    name: 'Time Uploaded',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Time Processed',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Info',
    field: 'totalInfo',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
  

import React, {
  useState, useEffect, useMemo, useRef, useContext,
} from 'react';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDebounce } from '../../hooks/useDebounce';
import {
  getCfdOrderHistory, revertCfdOrder, exportFilteredDataToFile, massRevertOrders, clearResults,
} from '../../redux/cfdOrder/cfdOrderActions';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { OrdersHistoryColumn } from '../../DataTableSchemas/OrdersHistoryColumn';
import { DatatableColumns, DatatableFilters } from '..';
import { orderHistoryColumns } from '../../columnsDefaultConfig';
import { DownloadFile } from '../DownloadFile';
import { userOrdersHistoryColumns } from '../../columnsDefaultConfig/userOrderHistoryColumns';
import { generateSortParams } from '../../helpers/sortHelper';
import { ModalContext } from '../../context';

export function OrdersHistoryTable({
  userId = '', localStorageConfig: {
    filtersStorageName = '',
    filterIdStorageName = '', 
    columnsStorageName = '',
    paginationStorageName = '', 
    sortDirectionStorageName = '',
  },
  isPositionUnset = false,
}) {
  const { pathname } = useLocation();
  const { showModal, hideModal } = useContext(ModalContext);
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const sortJSON = localStorage.getItem(sortDirectionStorageName);

  const columnConfigFile = userId && userId.length ? userOrdersHistoryColumns : orderHistoryColumns;

  const [columnConfig, setColumnConfig] = useState(columnConfigFile);
  const [filters, setFilters] = useState();
  
  const datatableFiltersRef = useRef(null);

  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [userName, setUserName] = useState();
  const [userNameValue, setUserNameValue] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [startTimeFilter, setStartTimeFilter] = useState([]);
  const [endTimeFilter, setEndTimeFilter] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  
  const dispatch = useDispatch();
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanRevertOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history_revert');
  const { orders: cfdOrders, totalCount, results } = useSelector((state) => state.cfdOrders);
  const { cfdSettings } = useSelector((state) => state.cfdSettings);
  
  useDebounce(userName, 300, () => setFilters((prev) => ({ ...prev, user: userName })));

  const handleCloseModal = () => {
    dispatch(clearResults());
    hideModal();
  };

  useEffect(() => {
    if (results && results.length) {
      showModal({
        headerContent: null,
        bodyContent: (
          <div>
            {
              results.map((result) => (
                <div
                  key={result.id}
                  style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', 
                  }}
                >
                  <span style={{ color: `${result.success ? 'green' : 'red'}` }}>{result.message}</span>
                </div>
              ))
            }
          </div>
        ),
        footerContent: (
          <button type="button" className="btn btn-default me-2" onClick={handleCloseModal}>
            Close
          </button>
        ),
      });
    }
  }, [results]);
  
  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };
  
  const handleChangeUserName = (value) => {
    setUserName(value);
    setUserNameValue(value);
  };

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedOrders([]);
  };
  
  const coinsPairs = useMemo(() => {
    const pairs = [];
  
    if (!cfdSettings || !cfdSettings.length) return pairs;
  
    cfdSettings.forEach(({ symbol }) => {
      pairs.push({
        name: symbol,
        _id: symbol,
      });
    });
  
    return pairs;
  }, [cfdSettings]);
  
  useEffect(() => {
    const fetchData = async () => {
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));
      setPage(1);
      setTableLoading(true);
      await dispatch(getCfdOrderHistory({
        page, rowsPerPage, filters, sortParams, userId, 
      }));
    };
    if (filters || sortParams) fetchData();
    setTableLoading(false);
  }, [filters, sortParams]);
  
  const handleRevertTradeHistory = async (e, order) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to Revert the order?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(revertCfdOrder(order._id));
      }
    });
  };
  
  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    setCoinPairsFilter([]);
    setTradingTypeFilter([]);
    setStartTimeFilter([]);
    setEndTimeFilter([]);
    setUserNameValue('');
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };
  
  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);
  
      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };
  
  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersJSON);
    const sortData = JSON.parse(sortJSON);
    setFilters(filters);
  
    setCoinPairsFilter(filters['trading pair'] || []);
    setTradingTypeFilter(filters['trading type'] || []);
    setStartTimeFilter(filters['start time'] || []);
    setEndTimeFilter(filters['end time'] || []);
    setUserNameValue(filters.user || '');
  };
      
  const setStoredPagination = () => {
    const ordersHistoryRowsJSON = localStorage.getItem(paginationStorageName);
    if (ordersHistoryRowsJSON) {
      const filterRows = JSON.parse(ordersHistoryRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
      
    setIsPaginationDT(true);
  };
  
  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setTableLoading(true);
    await dispatch(getCfdOrderHistory({
      page, rowsPerPage: currentRowsPerPage, filters, sortParams, userId, 
    }));
    setTableLoading(false);
  };
  
  const handlePageChange = async (page) => {
    setPage(page);
    setTableLoading(true);
    await dispatch(getCfdOrderHistory({
      page, rowsPerPage, filters, sortParams, userId, 
    }));
    setTableLoading(false);
  };
  
  const setCRMFilters = (filter) => {
    const {
      currency, status, fullName, timeOptionIds, lastComment,
    } = filter;
  
    handleChangeUserName(fullName);
    setCoinPairsFilter(currency);
    setTradingTypeFilter(status);
    setFilters({
      user: fullName,
      'trading pair': currency,
      'trading type': status,
      'start time': timeOptionIds,
      'end time': lastComment,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };
  
  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      currency: filters['trading pair'] || [],
      status: filters['trading type'] || [],
      fullName: filters.user || '',
      timeOptionIds: filters['start time'] || [],
      lastComment: filters['end time'] || [],
    };
  
    const res = await dispatch(createCRMFilter(data));
  
    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };
  
  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);
  
    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
  
      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };
  
  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);
  
    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['trading pair'] || [],
        status: filters['trading type'] || [],
        fullName: filters.user,
        timeOptionIds: filters['start time'] || [],
        lastComment: filters['end time'] || [],
      };
  
      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };
  
  const getTradeHistory = async () => {
    setTableLoading(true);
    await dispatch(getCfdOrderHistory({
      page, rowsPerPage, filters, sortParams, userId, 
    }));
    setTableLoading(false);
  };
  
  useEffect(async () => {
    setStoredColumnsData();
    setStoredPagination();
    dispatch(getCfdSettings());
    if (filtersJSON) {
      setStoredFilterData();
    } else {
      getTradeHistory();
    }
  }, []);
  
  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const handleOrderTableSelect = ({ selectedRows }) => {
    setSelectedOrders(selectedRows);
  };

  const handleRevert = () => {
    Swal.fire({
      title: 'Are you sure you want to Revert this orders?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ids = selectedOrders.map(({ _id }) => _id);
        await dispatch(massRevertOrders(ids));
        clearRows();
      }
    });
  };
  
  const ordersColumns = OrdersHistoryColumn({
    columnConfig,
    filters,
    setFilters,
    tradingTypeFilter,
    setTradingTypeFilter,
    coinsPairs,
    coinPairsFilter,
    setCoinPairsFilter,
    permissionName,
    handleRevertTradeHistory,
    userNameValue,
    handleChangeUserName,
    userId, 
    handleSort,
    startTimeFilter,
    setStartTimeFilter,
    endTimeFilter,
    setEndTimeFilter,
  });
      
  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (cfdOrders.length && columns.length) {
      setTableLoading(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportFilteredDataToFile(cfdOrders, columns, fileType); 
      setTableLoading(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  return (
    <>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {
              crmFilters && (
                <DatatableFilters
                  ref={datatableFiltersRef}
                  filters={crmFilters}
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                />
              )
          }
            <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
              <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button type="button" className="btn-secondary_dark iconed" onClick={getTradeHistory}>
              <RefreshIcon sx={{ fontSize: '20px' }} />
            </button>
          </div>
          <div className="secondary_btn-row">
            <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          </div>
          <div className="secondary_btn-row">
            <DownloadFile isPositionUnset={isPositionUnset} handleExport={handleExportOrders} />
          </div>
        </div>
        {(isUserCanRevertOrdersHistory && selectedOrders.length > 0) && (
          <div className="dt-actions__container">
            <div className="dt-actions__selected-counter">{`Actions: selected ${selectedOrders.length} ${selectedOrders.length > 1 ? 'records' : 'record'}`}</div>
            <div className="main_actions-row">
              <button 
                disabled={!selectedOrders.length}
                type="button"
                className="secondary-btn"
                onClick={handleRevert}
              >
                Revert
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        {
          isPaginationDT 
          && (
            <DataTable
              columns={ordersColumns}
              data={cfdOrders}
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              paginationResetDefaultPage={resetPaginationToggle}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              onSelectedRowsChange={handleOrderTableSelect}
              clearSelectedRows={toggleCleared}
              selectableRows
              persistTableHead
              highlightOnHover
              progressPending={tableLoading}
              theme="solarizedd"
              progressComponent={<div className="datatable-loader__background" />}
            />
          )
        }
      </div>
    </>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faCircleInfo,
  faTimes,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import {
  dateOptions, omitColumn, setTransactionActionColumn, prettyCutOutputByCellWidth, valueOutputCutLength, 
} from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUSD';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';
import styles from './styles.module.css';

export const PendingWithdrawsSchema = (
  amountRange,
  userIdFilter,
  setUserIdFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  setAmountRange,
  currencyFilter,
  setCurrencyFilter,
  currencies,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  setTransactionFilter,
  transactionFilter,
  statusFilter,
  setStatusFilter,
  handleInfoModalToggle,
  handleCommentModalToggle,
  activeInfo,
  activeComment,
  setActiveInfo,
  setActiveComment,
  permissionName,
  resolveCurrentWithDrawTransaction,
  handleRevertTransaction,
  updateIsReal,
  columnConfig,
  isRealFilter,
  setIsRealFilter,
  queryFilter,
  setQueryFilter,
  setTimeInitiatedFilter,
  handleDeleteTransaction,
  isDeleted,
  timeInitiatedFilter,
  setDebounceAmountRange,
  inputFieldError, 
  setInputFieldError,
  brandsFilters,
  setBrandsFilters,
  handleSort,
  accountIdFilter,
  setAccountIdFilter,
  ibdeskUserFilter,
  setIbdeskUserFilter,
  allIbDesks,
  timeProcessedFilter,
  setTimeProcessedFilter,
) => {
  const dispatch = useDispatch();
  const currencyRates = useSelector((state) => state.currencyRates);
  const brands = useSelector((state) => state.brands);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'userIdIsNotValid') setUserIdFilter(value);
    if (nameField === 'accountIdIsNotValid') setAccountIdFilter(value);
  };

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');
  const isUserCanWithdrawalManual = permissionName && permissionName.includes('withdrawal_manual');

  const { isTransactionLoading } = useSelector((state) => state.users);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('customId')}>
            <div className="d-flex flex-row">
              <span>Transaction ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={transactionIdFilter ?? ''}
            onChange={({ target: { value } }) => setTransactionIdFilter(value)}
          />
          <span className={styles.errorWrapper} />
        </div>),
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
          )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span>      
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
      omit: omitColumn(columnConfig, 'Transaction ID'),
      minWidth: '170px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('user.customId')}>
            <div className="d-flex flex-row">
              <span>Client ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={userIdFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span className={styles.errorWrapper}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>),
      cell: ({ user }) => (
        user?.customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={user?.customId}
          // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={user?.customId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(user?.customId, 'id')}
                  </span>
                </CopyToClipboard>
        )}
            />
          )
          : (
            <CopyToClipboard text={user?.customId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {user?.customId}
              </span>
            </CopyToClipboard>
          )
      ),
      omit: omitColumn(columnConfig, 'Client ID'),
      minWidth: '174px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('cfdaccount.customId')}>
            <div className="d-flex flex-row">
              <span>Account ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('cfdaccount.customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Account ID"
            style={inputFieldError.accountIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={accountIdFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'accountIdIsNotValid')}
          />
          <span className={styles.errorWrapper}>
            {inputFieldError.accountIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span>
        </div>),
      cell: ({ cfdaccount }) => (
        cfdaccount?.customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={cfdaccount?.customId}
          // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={cfdaccount?.customId}>
                  <span>
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(cfdaccount?.customId, 'id')}
                  </span>
                </CopyToClipboard>
        )}
            />
          )
          : (
            <CopyToClipboard text={cfdaccount?.customId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {cfdaccount?.customId}
              </span>
            </CopyToClipboard>
          )
      ),
      omit: omitColumn(columnConfig, 'Account ID'),
      minWidth: '174px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('amount')}>
            <div className="d-flex flex-row">
              <span>Amount</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('amount')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter
            value={amountRange ?? [0, 1000000]} 
            setValue={setAmountRange}
            filters={{}}
            setFilters={setDebounceAmountRange}
            filedName="amountRange"
            debounceAmountRange
          />
        </div>
      ),
      minWidth: '110px',
      cell: ({ amount }) => (
        amount?.toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={amount.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(amount, 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {amount}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'Amount'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('currency')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('currency')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Currency"
            data={currencies}
            filters={currencyFilter}
            setFilters={setCurrencyFilter}
            searchParams={{ id: 'symbol', params: ['symbol'], optionsName: ['symbol'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      minWidth: '120px',
      cell: ({ currency }) => currency,
      omit: omitColumn(columnConfig, 'Currency'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brand.name')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brand.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand"
            data={brands} 
            filters={brandsFilters} 
            setFilters={setBrandsFilters} 
            searchParams={{ id: '_id', params: ['name', '_id'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Brand'), 
      minWidth: '130px',
      selector: ({ brand }) => (
        brand?.name?.length >= valueOutputCutLength.brand 
          ? (
            <TooltipComponent 
              title={brand?.name}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(brand?.name ?? '', 'brand')}
                </span>
            )}
            />
          )
          : (
            <span>
              {brand?.name ?? ''}
            </span>
          )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('user.firstName user.lastName')}>
          <div className="d-flex flex-row">
            <span>Client</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('user.firstName user.lastName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '150px',
      cell: ({ _id, user }) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {
              fullName.length >= valueOutputCutLength.clientWithdrawal
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      (user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard) ? (
                        <Link to={`/user-detail/${user._id}`}>
                          <span data-tip={fullName} className="full-name-text">
                            {prettyCutOutputByCellWidth(fullName, 'clientWithdrawal')}
                          </span>
                        </Link>
                      ) : (
                        <span data-tip={fullName} className="full-name-text">
                          {prettyCutOutputByCellWidth(fullName, 'clientWithdrawal')}
                        </span>
                      )
                    )}
                  />
                )
                : ((user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard) ? (
                  <Link to={`/user-detail/${user._id}`}>
                    <span data-tip={fullName} className="full-name-text">
                      {fullName}
                    </span>
                  </Link>
                ) : (
                  <span data-tip={fullName} className="full-name-text">
                    {fullName}
                  </span>
                ))
            }
          </div>
        );
      },
      omit: omitColumn(columnConfig, 'Client'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToUser.firstName assignedToUser.lastName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToUser.firstName assignedToUser.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Manager"
            data={crmUsers}
            filters={assignedToFilter}
            setFilters={setAssignedToFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      minWidth: '170px',
      cell: ({ assignedToUser }) => {
        if (!assignedToUser) return '-';

        const fullManagerName = `${assignedToUser?.firstName} ${assignedToUser?.lastName}`;
        return (
          fullManagerName.length >= valueOutputCutLength.manager 
            ? (
              <TooltipComponent 
                title={fullManagerName}
                  // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/edit-admin/${assignedToUser?._id}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(fullManagerName, 'manager')}
                  </Link>
                  )}
              />
            )
            : (
              <Link
                to={`/edit-admin/${assignedToUser?._id}`}
                className="text-decoration-none"
              >
                {fullManagerName}
              </Link>
            )
        );
      },
      omit: omitColumn(columnConfig, 'Assigned To'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('ibdesk.fullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdesk.fullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdeskUser"
            data={allIbDesks}
            filters={ibdeskUserFilter}
            setFilters={setIbdeskUserFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      minWidth: '170px',
      cell: ({ ibdesk }) => {
        if (!ibdesk || !ibdesk?.fullName) return '-';
        const fullManagerName = ibdesk?.fullName;

        return (
          fullManagerName.length >= valueOutputCutLength.ibdesk
            ? (
              <TooltipComponent
                title={fullManagerName}
                  // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/edit-admin/${ibdesk?._id}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(fullManagerName, 'ibdesk')}
                  </Link>
                  )}
              />
            )
            : (
              <Link
                to={`/edit-admin/${ibdesk?._id}`}
                className="text-decoration-none"
              >
                {fullManagerName}
              </Link>
            )
        );
      },
      omit: omitColumn(columnConfig, 'Desk/IB'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('amount')}>
          <div className="d-flex flex-row">
            <span>In USD</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('amount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return (
          usdtValue?.toFixed(2).toString().length >= valueOutputCutLength.inUsd 
            ? (
              <TooltipComponent 
                title={usdtValue?.toFixed(2).toString()}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>
                    {prettyCutOutputByCellWidth(usdtValue?.toFixed(2).toString(), 'inUsd')}
                  </span>
              )}
              />
            )
            : (
              <span>
                {usdtValue?.toFixed(2) ?? '-'}
              </span>
            )
        );
      },
      omit: omitColumn(columnConfig, 'In USD'),
      minWidth: '100px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('type')}>
            <div className="d-flex flex-row">
              <span>Transaction Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('type')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Transaction Type"
            data={[{ name: 'Crypto', _id: 'Crypto' }, { name: 'Card', _id: 'Card' }, { name: 'Bank', _id: 'Bank' }, { name: 'Admin Transaction', _id: 'Admin Transaction' }]}
            filters={transactionFilter}
            setFilters={setTransactionFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      minWidth: '180px',
      cell: ({ type }) => (
        type.length >= valueOutputCutLength.transactionType 
          ? (
            <TooltipComponent 
              title={type}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(type ?? 'Admin Withdrawal', 'transactionType')}
                </span>
          )}
            />
          )
          : (
            <span>
              {type ?? 'Admin Withdrawal'}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'Transaction Type'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isResolved')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isResolved')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Status"
            data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
            filters={statusFilter}
            setFilters={setStatusFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      cell: ({
        _id, isResolved, toAddress, toCard, toIban, toAccountNumber, toSwiftCode, type, comment, additionalInfo,
      }) => (
        <>
          {isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleInfoModalToggle(_id);
              }}
            />

            {activeInfo === _id ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer', position: 'absolute', right: '10px', top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />

                {toAddress ? (
                  <>
                    <h6>To Address</h6>
                    <p>{toAddress}</p>
                  </>
                ) : null}
                {toCard ? (
                  <>
                    <h6>To Card</h6>
                    <p>{toCard}</p>
                  </>
                ) : null}
                {toIban ? (
                  <>
                    <h6>To Iban</h6>
                    <p>{toIban}</p>

                    <h6>Account Number</h6>
                    <p>{toAccountNumber}</p>

                    <h6>Swift Code</h6>
                    <p>{toSwiftCode}</p>
                  </>
                ) : null}
                {
                  type === 'Admin Transaction' ? (
                    <h6>Admin Transaction</h6>
                  ) : null
                }
                <h6>Additional Info:</h6>
                <p>{additionalInfo?.length ? additionalInfo : 'No Info'}</p>
              </div>
            ) : null}
          </span>

          <span>
            <FontAwesomeIcon
              icon={faComment}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleCommentModalToggle(_id);
              }}
            />

            {activeComment === _id && (
            <div className="depositsAdditionalInfoModal">
              <h5>Comment</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setActiveComment(null)}
              />
              <div className="depositsAdditionalCommentModalIn">
                {comment && comment.length ? comment : 'No comments'}
              </div>
            </div>
            )}
          </span>

        </>
      ),
      minWidth: '175px',
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Time Initiated</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Time Initiated"
            data={dateOptions} 
            filters={timeInitiatedFilter} 
            setFilters={setTimeInitiatedFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
            isDateOptions
          />
        </div>
      ),
      selector: ({ createdAt }) => (createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      minWidth: '154px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('processedAt')}>
            <div className="d-flex flex-row">
              <span>Time Processed</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('processedAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Time processed"
            data={dateOptions}
            filters={timeProcessedFilter}
            setFilters={setTimeProcessedFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
            isDateOptions
          />
        </div>
      ),
      selector: ({ processedAt }) => (processedAt ? moment(processedAt).format('YYYY-MM-DD HH:mm:ss') : ''),
      omit: omitColumn(columnConfig, 'Time Processed'),
      minWidth: '154px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isReal')}>
            <div className="d-flex flex-row">
              <span>Real/Fake</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isReal')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Real/Fake"
            data={[{ name: 'Real', _id: 'true' }, { name: 'Fake', _id: 'false' }]}
            filters={isRealFilter}
            setFilters={setIsRealFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={queryFilter}
            setUserFilters={setQueryFilter}
          />
        </div>
      ),
      minWidth: '130px',
      omit: omitColumn(columnConfig, 'Real/Fake'),
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),      
    },
    {
      name: 'Action(s)',
      minWidth: '360px',
      omit: omitColumn(columnConfig, 'Action'),
      cell: (row) => (
        <>
          {isUserCanWithdrawalManual && !row.isResolved && (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            disabled={isTransactionLoading}
            onClick={() => dispatch(resolveCurrentWithDrawTransaction(row._id, row.userId, 1, false))}
          >
            Fake Approve
          </button>
          )}
          {setTransactionActionColumn(
            row,
            permissionName,
            resolveCurrentWithDrawTransaction,
            updateIsReal,
            handleRevertTransaction,
            handleDeleteTransaction,
            false,
            dispatch,
          )}
        </>
      ), 
    },
  ];

  return columns;
};


import { toast } from 'react-toastify';
import {
  ADD_SAVING,
  DELETE_SAVING,
  EDIT_SAVING,
  GET_SAVING,
  GET_SAVINGS,
  GET_SAVING_OPTIONS,
} from './savingTypes';
import { apiHelper } from '../apiHelper';

export const getOptions = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/saving-option/', '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_SAVING_OPTIONS,
        payload: res.data.options,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getSavings = (sortParams) => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/saving', '', {
      sort: sortParams,
    });

    if (res && res.data && res.data.success) {
      const { savings } = res.data;
      await dispatch({
        type: GET_SAVINGS,
        payload: savings,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getSaving = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/saving/${id}`, '');
    if (res && res.data && res.data.success) {
      const { saving } = res.data;
      await dispatch({
        type: GET_SAVING,
        payload: saving,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addSaving = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/saving/add', data);

    if (res && res.data && res.data.success) {
      const { message, saving } = res.data;

      toast.success(message, {
        autoClose: 1000,
      });

      await dispatch({
        type: ADD_SAVING,
        payload: saving,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editSaving = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/saving/${id}`, bodyData);
    if (res && res.data && res.data.success) {
      const { saving, message } = res.data;

      toast.success(message, {
        autoClose: 1000,
      });

      await dispatch({
        type: EDIT_SAVING,
        payload: saving,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteSaving = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/saving/${id}`, '');
    if (res && res.data && res.data.success) {
      const { saving, message } = res.data;

      toast.success(message, {
        autoClose: 1000,
      });

      await dispatch({
        type: DELETE_SAVING,
        payload: saving._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

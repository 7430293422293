import React, {
  useEffect,
  useState, 
  useContext, 
  useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  selectEmailTemplates, selectIsEmailTemplatesLoading, selectIsSuperUser, selectTotalCount, 
} from '../../redux/emailTemplates/emailTemplatesSelectors';
import { getEmailTemplates, makeSuperUserCheck, exportFilteredDataToFile } from '../../redux/emailTemplates/emailTemplatesActions';
import { EmailTemplatesColumns } from '../../DataTableSchemas/EmailTemplatesColumns';
import EmailTemplateCreate from './EmailTemplateCreate/EmailTemplateCreate';
import { ModalContext } from '../../context';
import { generateSortParams } from '../../helpers/sortHelper';
import { DatatableColumns, DatatableFilters, DownloadFile } from '../../components';
import { emailTemplatesColumns } from '../../columnsDefaultConfig';
import { useDebounce } from '../../hooks/useDebounce';
import {
  createCRMFilter, deleteCRMFilter, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { getBrands } from '../../redux/brand/brandActions';
import ManualTemplateCreate from './ManualTemplateCreate/EmailTemplateCreate';

function EmailTemplates() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const datatableFiltersRef = useRef(null);
  const { pathname } = useLocation();

  const paginationStorageName = 'DataTable_emailTemplates/listing_pagination';
  const columnsStorageName = 'DataTable_emailTemplates/columns';
  const filtersStorageName = 'DataTable_emailTemplates/filters';
  const filterIdStorageName = 'DataTable_emailTemplates/filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);

  // templates variables
  const isSuperUser = useSelector(selectIsSuperUser);
  const emailTemplates = useSelector(selectEmailTemplates);
  const isEmailTemplatesLoading = useSelector(selectIsEmailTemplatesLoading);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [columnConfig, setColumnConfig] = useState(emailTemplatesColumns);
  const [eventSearchFilter, setEventSearchFilter] = useState(null);
  const [titleSearchFilter, setTitleSearchFilter] = useState(null);
  const [statusSearchFilter, setStatusSearchFilter] = useState('all');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filters, setFilters] = useState({});
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      if (filters.event) setEventSearchFilter(filters.event);
      if (filters.title) setTitleSearchFilter(filters.title);
      if (filters.status) setStatusSearchFilter(filters.status);
      if (filters.brand) setSelectedBrands(filters.brand);
    }
  };

  const handleClear = () => {
    setSortParams(JSON.stringify({ createdAt: -1 }));
    setEventSearchFilter(null);
    setTitleSearchFilter(null);
    setStatusSearchFilter('all');
    setSelectedBrands([]);
    setFilters({});

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleRefresh = async () => {
    dispatch(getEmailTemplates(page, rowsPerPage, sortParams, filters));
  };

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create Email Template</h3>,
      bodyContent: <EmailTemplateCreate hideModal={hideModal} />,
    });
  };

  const handleCreateManualTemplateBtn = () => {
    showModal({
      headerContent: <h3>Create Manual Template</h3>,
      bodyContent: <ManualTemplateCreate hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  const setCRMFilters = (filter) => {
    const {
      email,
      fullName,
      phone,
      brand,
    } = filter;

    setFilters({
      event: email,
      title: fullName,
      status: phone,
      brand,
    });

    setEventSearchFilter(email);
    setTitleSearchFilter(fullName);
    setStatusSearchFilter(phone);
    setSelectedBrands(brand);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      email: filters.event || '',
      fullName: filters.title || '',
      phone: filters.status || '',
      brand: filters.brand || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        email: filters.event || '',
        fullName: filters.title || '',
        phone: filters.status || '',
        brand: filters.brand || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  useEffect(() => {
    if (!isPaginationDT) return;

    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    dispatch(getEmailTemplates(page, rowsPerPage, sortParams, filters));
  }, [page, rowsPerPage, isPaginationDT, sortParams, filters]);

  useDebounce(eventSearchFilter, 1000, (value) => debounceCallback({ value, key: 'event' }));
  useDebounce(titleSearchFilter, 1000, (value) => debounceCallback({ value, key: 'title' }));

  useEffect(() => {
    setFilters({ ...filters, status: statusSearchFilter });
  }, [statusSearchFilter]);

  useEffect(() => {
    dispatch(makeSuperUserCheck());
    getAllStoredData();
    dispatch(getBrands());
  }, []);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const handleExport = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (emailTemplates.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportFilteredDataToFile(emailTemplates, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const emailTemplatesCol = EmailTemplatesColumns({
    handleSort,
    columnConfig,
    eventSearchFilter, 
    setEventSearchFilter,
    titleSearchFilter,
    setTitleSearchFilter,
    statusSearchFilter,
    setStatusSearchFilter,
    filters,
    setFilters,
    selectedBrands,
    setSelectedBrands,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Email Templates Details</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isSuperUser && (
                <button 
                  type="button" 
                  className="btn-primary_light"
                  onClick={handleCreateBtn}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                  Add New
                </button>
              )}
            </div>
            <div className="secondary_btn-row">
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={handleCreateManualTemplateBtn}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New Manual Template
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableFilters 
                ref={datatableFiltersRef}
                filters={crmFilters}
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExport} />
            </div>
          </div>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {isPaginationDT && (
            <DataTable
              columns={emailTemplatesCol}
              data={emailTemplates}
              pagination
              highlightOnHover
              paginationServer
              paginationTotalRows={totalTableCount}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              persistTableHead
              theme="solarizedd"
              progressPending={isEmailTemplatesLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )}
        </div>
        <br />
      </div>
    </div>
  );
}

export default EmailTemplates;

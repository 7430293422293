import { toast } from 'react-toastify';
import {
  GET_CFD_SETTINGS, UPDATE_CFD_SETTINGS, SEARCH_CFD_SETTINGS, MASS_UPDATE_CFD_SETTINGS,
} from './cfdTypes';
import { apiHelper } from '../apiHelper';

export const exportFilteredDataToFile = async (query, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/cfd-setting/export', {
      query, columns, fileType,
    });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const getCfdSettings = (sortParams) => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/cfd-setting', null, {
      ...(sortParams ? { sort: sortParams } : {}),
    });

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_SETTINGS,
        payload: res.data.cfdSettings,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateCfdSettings = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/cfd-setting/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_CFD_SETTINGS,
        payload: res.data.cfdSetting,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const massUpdateCfdSettings = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/cfd-setting/mass-update', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: MASS_UPDATE_CFD_SETTINGS,
        payload: data,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCfdSettingsByQuery = ({
  limit,
  page, 
  sort = '{ "label": 1 }', 
  query = {}, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/cfd-setting/query${url}`, '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: SEARCH_CFD_SETTINGS,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

import { toast } from 'react-toastify';
import {
  SET_KYC_AML_DATA,
  EDIT_KYC_AML_DATA,
  SET_KYC_AML_REQUESTS,
  SET_TOTAL_COUNT,
  SET_USER_NAME_SEARCH_FILTER,
  SET_USER_EMAIL_SEARCH_FILTER,
  SET_USER_PHONE_SEARCH_FILTER,
  SET_VERIFY_STATUS_SEARCH_FILTER,
  SET_TIME_UPLOADED_FILTER,
  SET_IS_KYC_AML_DATA_LOADING,
  SET_IS_KYA_AML_REQUESTS_LOADING,
  GET_KYC_STATUSES,
  DELETE_KYC_AML_REQUEST,
} from './kycAmlTypes';
import { apiHelper } from '../apiHelper';

// action creators below
const setKycAmlDataCreator = (templates) => ({
  type: SET_KYC_AML_DATA,
  payload: templates,
});

const editKycAmlDataCreator = (template) => ({
  type: EDIT_KYC_AML_DATA,
  payload: template,
});

export const setKycAmlRequestsCreator = (template) => ({
  type: SET_KYC_AML_REQUESTS,
  payload: template,
});

const setTotalCountCreator = (totalCount) => ({
  type: SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setUserNameFilterCreator = (value) => ({
  type: SET_USER_NAME_SEARCH_FILTER,
  payload: value,
});

export const setUserEmailFilterCreator = (value) => ({
  type: SET_USER_EMAIL_SEARCH_FILTER,
  payload: value,
});

export const setUserPhoneFilterCreator = (value) => ({
  type: SET_USER_PHONE_SEARCH_FILTER,
  payload: value,
});

export const setVerifyStatusFilterCreator = (value) => ({
  type: SET_VERIFY_STATUS_SEARCH_FILTER,
  payload: value,
});

export const setTimeUploadedFilterCreator = (value) => ({
  type: SET_TIME_UPLOADED_FILTER,
  payload: value,
});

const setIsKycAmlDataLoadingCreator = (status) => ({
  type: SET_IS_KYC_AML_DATA_LOADING,
  payload: status,
});

const setIsKycAmlRequestsLoadingCreator = (status) => ({
  type: SET_IS_KYA_AML_REQUESTS_LOADING,
  payload: status,
});


// Thunks below

export const deleteKycAmlRequest = (kycRequestId, userId) => async (dispatch) => {
  try {
    const response = await apiHelper('delete', `/api/kyc-verification/${userId}`, '');

    if (response.data && response.data.success) {
      dispatch({
        type: DELETE_KYC_AML_REQUEST,
        payload: kycRequestId,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getKycAmlData = (page = 1, rowsPerPage = 25, filters = {}, sortParams) => async (dispatch) => {
  dispatch(setIsKycAmlDataLoadingCreator(true));

  const params = {
    page,
    limit: rowsPerPage,
    sort: sortParams,
  };

  if (filters.fullName) params.fullName = filters.fullName;
  if (filters.status && filters.status.length) params.verifyStatus = JSON.stringify(filters.status);
  if (filters['time uploaded'] && filters['time uploaded'].length) params.createdAt = JSON.stringify(filters['time uploaded']);

  try {
    const response = await apiHelper('get', '/api/kyc-verification', '', params);

    if (response.data && response.data?.kyc) {
      const result = response.data.kyc;

      const kycAmlData = result.paginatedResult ?? [];
      const totalCount = result.totalCount ?? 25;

      dispatch(setTotalCountCreator(totalCount));
      dispatch(setKycAmlDataCreator(kycAmlData));
    } else {
      dispatch(setKycAmlDataCreator([]));
    }

    dispatch(setIsKycAmlDataLoadingCreator(false));
  } catch (error) {
    toast.error(error.message);
    dispatch(setIsKycAmlDataLoadingCreator(false));
  }
};

export const getKycAmlRequestsByUserId = (userId) => async (dispatch) => {
  dispatch(setIsKycAmlRequestsLoadingCreator(true));
  dispatch(setKycAmlRequestsCreator([]));

  try {
    const response = await apiHelper('get', `/api/kyc-verification/${userId}`, '');

    if (response.data) {
      const kycAmlRequests = response.data?.kycRequestIds ?? [];
      dispatch(setKycAmlRequestsCreator(kycAmlRequests));
    }

    dispatch(setIsKycAmlRequestsLoadingCreator(false));
  } catch (error) {
    toast.error(error.message);
    dispatch(setIsKycAmlRequestsLoadingCreator(false));
  }
};

export const setStatusToKycRequest = (kycRequestId, userId, status, info) => async (dispatch) => {
  try {
    const response = await apiHelper('patch', `/api/kyc-verification/set-status-to-kyc-request/${kycRequestId}`, { status, userId, info });

    if (response.data && response.data.success) {
      const { result } = response.data;

      toast.success(`Status changed to: ${status}`);
      dispatch(editKycAmlDataCreator(result));
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getKycStatuses = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/kyc-verification/statuses');

    if (res.data && res.data.success) {
      const { result } = res.data;


      await dispatch({
        type: GET_KYC_STATUSES,
        payload: result,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

import React, {
  useContext, useEffect, useRef, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAffiliatesTableData, exportFilteredDataToFile } from '../../redux/affiliate/affiliateActions';
import { useDebounce } from '../../hooks/useDebounce';
import { AffiliateColumns } from '../../DataTableSchemas/AffiliateColumns';
import { ModalContext } from '../../context';
import { AddAffiliateModal } from './components';
import { generateSortParams } from '../../helpers/sortHelper';
import { DatatableFilters, DatatableColumns, DownloadFile } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { affiliateColumns } from '../../columnsDefaultConfig';

function Affiliate() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const [fullNameFilter, setFullNameFilter] = useState(null);
  const [idFilter, setIdFilter] = useState(null);
  const [emailFilter, setEmailFilter] = useState(null);
  const [filters, setFilters] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ customId: -1 }));
  const [columnConfig, setColumnConfig] = useState(affiliateColumns);

  const paginationStorageName = 'DataTable_affiliate/listing_pagination';
  const filtersStorageName = 'DataTable_affiliate/filters';
  const filtersStorageJSON = localStorage.getItem(filtersStorageName);
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const filterIdStorageName = 'DataTable_affiliate/filters_id'; 
  const columnsStorageName = 'DataTable_affiliate/columns';
  const columnsStorageJSON = localStorage.getItem(columnsStorageName);

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const { affiliates, totalCount } = useSelector((state) => state?.affiliate);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddUsers = permissionName && permissionName.length && permissionName.includes('affiliate_add');

  const datatableFiltersRef = useRef(null);

  const debounceCallback = ({ value, key, limit }) => {
    if (value !== null && (value.length >= limit || value.length === 0) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(fullNameFilter, 300, (value) => debounceCallback({ value, key: 'fullName', limit: 3 }));
  useDebounce(idFilter, 300, (value) => debounceCallback({ value, key: 'id', limit: 1 }));
  useDebounce(emailFilter, 300, (value) => debounceCallback({ value, key: 'email', limit: 3 }));

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredColumnsData = () => {
    if (columnsStorageJSON) {
      const columns = JSON.parse(columnsStorageJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersStorageJSON) ?? {};
    setFilters(filters);

    setIdFilter(filters.id || '');
    setFullNameFilter(filters.fullName || '');
    setEmailFilter(filters.email || '');
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };
        
  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
  }, []);
    
  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  useEffect(async () => {
    if (!isPaginationDT) return;
    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    setTableLoading(true);

    await dispatch(getAffiliatesTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));

    setTableLoading(false);
  }, [page, rowsPerPage, isPaginationDT, filters, sortParams]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const handleClear = () => {
    setIdFilter('');
    setFullNameFilter('');
    setEmailFilter('');
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getAffiliatesTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));
    setTableLoading(false);
  };

  const setCRMFilters = (filter) => {
    const {
      fullName,
      uid,
      email,
    } = filter;

    setFullNameFilter(fullName || null);
    setEmailFilter(email || null);
    setIdFilter(uid || null);
    setFilters({
      fullName,
      email,
      id: uid,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || '',
      email: filters.email || '',
      uid: filters.id || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        email: filters.email || '',
        uid: filters.id || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const handleExportStatuses = (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (affiliates.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(filters, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const affiliateCol = AffiliateColumns({
    columnConfig,
    handleSort,
    fullNameFilter,
    setFullNameFilter,
    idFilter, 
    setIdFilter,
    emailFilter,
    setEmailFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Affiliate Details</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddUsers && (
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={() => {
                  showModal({
                    headerContent: <h3>Add Affiliate</h3>,
                    bodyContent: <AddAffiliateModal />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </button>
              )}
            </div>
            <div className="secondary_btn-row">
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters}
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExportStatuses} />
            </div>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={affiliateCol}
                  data={affiliates}
                  paginationTotalRows={totalCount}
                  paginationDefaultPage={page}
                  pagination
                  highlightOnHover
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  theme="solarizedd"
                  progressPending={tableLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
      </div>
    </div>
  );
}

export default Affiliate;

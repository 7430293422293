import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from './helper';
import { ModalFilters } from '../components';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const ClientActivityColumns = (
  userFilters, 
  setUserFilters,
  crmUsersFilter, 
  setCrmUsersFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  clientNameFilter,
  setClientNameFilter,
  handleSort,
) => {
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const activityTypes = useSelector((state) => state?.activityClientType?.activityClientTypes);

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const clientActivityColumns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => (
        <span>{formatDate(new Date(row?.createdAt))}</span>
      ),
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('crmUser.firstName crmUser.lastName')}>
            <div className="d-flex flex-row">
              <span>CRM User</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('crmUser.firstName crmUser.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="CRM User" 
            data={crmUsers} 
            filters={crmUsersFilter} 
            setFilters={setCrmUsersFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={userFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      selector: (row) => {
        if (row.crmUser) return `${row?.crmUser?.firstName} ${row?.crmUser.lastName}`;
        return '-';
      },
      cell: (row) => {
        if (
          row.crmUser
        ) {
          return (
            permissionName && permissionName.length > 0 && permissionName.includes('edit_crm_user')
              ? (
                <Link
                  to={`/edit-admin/${row.crmUser._id}`}
                  className="text-decoration-none"
                >
                  <span className="full-name-text">{`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
                </Link>
              )
              : <span>{`${row.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
          );
        }
        return '-';
      },
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('client._id')}>
            <div className="d-flex flex-row">
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('client._id')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientNameFilter}
            minLength="3"
            onChange={(e) => setClientNameFilter(e.target.value)}
          />
          {
            (!!clientNameFilter && clientNameFilter.length >= 1 && clientNameFilter.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>)
          }
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) return `${row?.client?.firstName} ${row?.client?.lastName}`;
        return '-';
      },
      cell: (row) => {
        if (
          row?.userId
        ) {
          return (
            permissionName && permissionName.length && permissionName.includes('edit_user')
              ? (
                <Link
                  to={`/user-detail/${row?.client?._id}`}
                  className="text-decoration-none"
                >
                  <span className="full-name-text">
                    {`${row?.client?.firstName} ${row?.client?.lastName}`}
                  </span>
                </Link>
              )
              : <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('activityType.name')}>
            <div className="d-flex flex-row">
              <span>Activity</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('activityType.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Activity" 
            data={activityTypes} 
            filters={activityTypeFilter} 
            setFilters={setActivityTypeFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={userFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      selector: (row) => (row?.activityType ? row?.activityType?.name : ''),
      cell: (row) => (
        <span>
          {row?.activityType?.name}
          {' '}
        </span>
      ),
      minWidth: '150px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('information')}>
          <div className="d-flex flex-row">
            <span>Information</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('information')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row?.information,
      cell: (row) => (
        <span>{row?.information}</span>
      ),
      minWidth: '150px',
    },
  ];
  return clientActivityColumns;
};

import React, { useState, useEffect } from 'react';
import {
  Modal, Button, ModalHeader, ModalBody, 
} from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-multi-date-picker/styles/colors/teal.css';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addAdminComment, getAdminComments, deleteAdminComment } from '../../redux/adminComment/adminCommentActions';
import styles from './styles.module.css';

const authorId = JSON.parse(localStorage.getItem('userId'));
const author = JSON.parse(localStorage.getItem('user'));

export function AddCommentsModal({
  showModal, setShowModal, row, refresh,
}) {
  const [inputCommentValue, setInputCommentValue] = useState('');
  const dispatch = useDispatch();
  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanDeleteComments = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('delete_comment');

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const inputCommentHandler = ({ target: { value } }) => {
    setInputCommentValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAdminComments(row?._id));
    };
    fetchData();
  }, []);

  const handleDelSingleComment = async (e, commentId) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminComment(commentId));
      }
    });
  };

  const handleSubmit = () => {
    const data = {
      text: inputCommentValue,
      authorId,
      userId: row?._id,
      author,
    };
    dispatch(addAdminComment(data));
    refresh();
    handleCloseModal();
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  return (
    <Modal
      className="widthdrawal-modal"
      centered
      show={showModal}
      onHide={setShowModal}
    >
      <ModalHeader closeButton>
        <h5>
          Comments for User 
          {' '}
          {`#${row?.customId}`}
        </h5>
      </ModalHeader>
      <ModalBody>
        <div className={styles.addCommentModal}>
          <label htmlFor="commentAdd">Add new comment</label>
          <textarea type="text" onChange={inputCommentHandler} value={inputCommentValue} name="commentAdd" id="commentAdd" className={styles.commentInput} rows={4} />
        </div>
        <div className={styles.commentsHistoryWrapper}>
          {adminComments.length 
            ? (
              <ul className={styles.commentsHistory}>
                { adminComments.map((el) => (
                  <li className={styles.commentsHistoryCell} key={el?._id}> 
                    {
                      isUserCanDeleteComments && (
                      <div className={styles.commentsHistoryCellBtns}>
                        <button 
                          type="button" 
                          className={styles.btnsCell}
                          onClick={(event) => handleDelSingleComment(event, el._id)}
                        >
                          <DeleteIcon sx={{ color: 'red' }} />
                        </button>
                      </div>
                      )
                    }
                    <div className={styles.commentsHistoryCellInfo}>
                      <div className={styles.commentsHistoryCellRowInfo}>
                        <p className={styles.commentsHistoryCellInfoText}>{formatDate(new Date(el?.updatedAt)) ?? ''}</p>
                        <p className={styles.commentsHistoryCellInfoTextBold}>{`${el?.author?.firstName ?? ''} ${el?.author?.lastName ?? ''}`}</p>
                      </div>
                      <div className={styles.commentsHistoryCellInfoText}>{`${el.text.replace(/<br\s*\/?>/gi, '\n')}` ?? ''}</div>
                    </div>
                  </li>
                )) }
              </ul>
            )
            : null }
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Add Comment
        </Button>
      </Modal.Footer>

    </Modal>
  );
};


import React, { useState } from 'react';
import sortAsc from '../assets/images/sort/sortAsc.svg';
import sortDesc from '../assets/images/sort/sortDesc.svg';
import sortDefault from '../assets/images/sort/sortDefault.svg';

const getSortImg = (direction = 'default') => {
  switch (direction) {
    case 'asc':
      return sortAsc;
    case 'desc':
      return sortDesc;
    default:
      return sortDefault;
  }
};

export const generateSortParams = (sortField, sortDirection) => {
  const direction = sortDirection === 'asc' ? 1 : -1;
  const params = {};

  const fields = sortField.split(' ');

  fields.forEach((field) => {
    params[field] = direction;
  });

  const sort = JSON.stringify(params);
  return sort;
};

export const useTableSorting = (handleSort) => {
  const [sortColumn, setSortColumn] = useState('');
  const [direction, setDirection] = useState('asc');

  const sortFields = (fieldName) => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    handleSort(fieldName, direction);
    setSortColumn(fieldName);
    setDirection(newDirection);
  };

  const getSortIcon = (columnName) => {
    const sortDirection = direction === 'asc' ? 'desc' : 'asc';
    return columnName === sortColumn ? getSortImg(sortDirection) : getSortImg();
  };

  return {
    sortColumn, direction, sortFields, getSortIcon,
  };
};

export function SortWrapper({ handleSort, children, style }) {
  return (
    <div onClick={handleSort} style={{ cursor: 'pointer', display: 'inline-block', ...style }}>
      {children}
    </div>
  );
}

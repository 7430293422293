import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { ModalContext } from '../../context';
import SmsServiceEdit from '../../pages/SmsServices/SmsServiceEdit/SmsServiceEdit';
import {
  deleteSmsServiceById, setAgentFilter, setDescriptionFilter, setIsActiveFilter, setServiceNameFilter,
  setStatusSmsServiceById, 
} from '../../redux/smsServices/smsServicesActions';
import {
  selectAgentFilter,
  selectDescriptionFilter, selectIntegratedServices, selectIsActiveFilter, selectServiceNameFilter, 
} from '../../redux/smsServices/smsServicesSelectors';
import styles from './SmsServicesColumns.module.css';
import { omitColumn, prettyCutOutputByCellWidth, valueOutputCutLength } from '../helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';

export const SmsServicesColumns = ({ columnConfig, handleSort }) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditTemplatePermission = permissions && permissions.length > 0 && permissions.includes('edit_sms_service'); 

  const integratedServices = useSelector(selectIntegratedServices);

  // filters variables
  const descriptionFilter = useSelector(selectDescriptionFilter);
  const serviceNameFilter = useSelector(selectServiceNameFilter);
  const isActiveFilter = useSelector(selectIsActiveFilter);
  const agentFilter = useSelector(selectAgentFilter);

  const { sortFields, getSortIcon } = useTableSorting(handleSort);
  
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const filterAgents = [
    {
      _id: '',
      firstName: 'All',
      lastName: '',
    },
    ...crmUsers,
  ];

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSmsServiceById(id));
      }
    });
  };

  const editAction = (id) => {
    showModal({
      headerContent: <h3>Edit Sms Service</h3>,
      bodyContent: <SmsServiceEdit id={id} />,
    });
  };

  const changeStatus = (id, status) => {
    dispatch(setStatusSmsServiceById(id, status));
  };

  const copyMethod = async (value) => {
    await navigator.clipboard.writeText(value);
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('description')}>
            <div className="d-flex flex-row">
              <span>Description</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('description')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            name="description"
            autoComplete="off"
            value={descriptionFilter}
            placeholder="Filter by description"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setDescriptionFilter(e.target.value))}
          />
        </div>
      ),
      minWidth: '180px',
      selector: (row) => (
        <span>
          {row?.description}
        </span>
      ),
      cell: (row) => (
        row?.description.length >= valueOutputCutLength.sms
          ? (
            <TooltipComponent
              title={row?.description}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(row?.description, 'sms')}
                </span> 
            )}
            />
          )
          : (
            <span>{row?.description}</span>
          )
      ),
      omit: omitColumn(columnConfig, 'Description'), 
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('crmUserInfo.firstName crmUserInfo.lastName')}>
            <div className="d-flex flex-row">
              <span>Agent</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('crmUserInfo.firstName crmUserInfo.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <select
            name="assigned_to"
            value={agentFilter}
            onChange={({ target: { value } }) => dispatch(setAgentFilter(value))}
            className="email-templates-search-bar-filter"
          >
            {filterAgents.map((user, key) => (
              <option key={user._id} selected={key === 0} value={user._id}>
                {`${user.firstName} ${user.lastName}`}
              </option>
            ))}
          </select>
        </div>
      ),
      minWidth: '180px',
      selector: ({ assigned_to }) => `${assigned_to}`,
      cell: ({ assigned_to }) => {
        const agent = crmUsers.find((user) => user._id === assigned_to);
        const agentFullName = `${agent?.firstName} ${agent?.lastName}`;

        return (
          agentFullName.length >= valueOutputCutLength.sms
            ? (
              <TooltipComponent
                title={agentFullName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span data-tip={assigned_to} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    {prettyCutOutputByCellWidth(agentFullName, 'sms')}
                  </span>
                )}
              />
            )
            : (
              <span data-tip={assigned_to}>
                {agentFullName}
              </span>
            )
        );
      },
      omit: omitColumn(columnConfig, 'Agent'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('service_name')}>
            <div className="d-flex flex-row">
              <span>Service Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('service_name')} />
              </button>
            </div>
          </SortWrapper>
          <select
            name="status"
            value={serviceNameFilter}
            className="email-templates-search-bar-filter"
            style={{ width: '120px' }}
            onChange={({ target: { value } }) => dispatch(setServiceNameFilter(value))}
          >
            <option value="">All</option>
            {integratedServices.map((service) => <option key={service}>{service}</option>)}
          </select>
        </div>
      ),
      minWidth: '144px',
      selector: ({ service_name }) => `${service_name}`,
      cell: ({ service_name }) => (
        <span data-tip={service_name}>
          {service_name}
        </span>
      ),
      omit: omitColumn(columnConfig, 'Service Name'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('is_active')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('is_active')} />
              </button>
            </div>
          </SortWrapper>
          <select
            name="status"
            value={isActiveFilter}
            className="email-templates-search-bar-filter"
            style={{ width: '80px' }}
            onChange={({ target: { value } }) => dispatch(setIsActiveFilter(value))}
          >
            <option value="">All</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
      ),
      selector: ({ is_active }) => `${is_active}`,
      minWidth: '90px',
      cell: ({ is_active }) => (
        <span data-tip={is_active}>
          {is_active ? 'active' : 'inactive'}
        </span>
      ),
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('auth_data')}>
            <div className="d-flex flex-row">
              <span>Auth Data</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('auth_data')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      cell: ({ auth_data = {} }) => {
        const [token, sid] = Object.entries(auth_data)
          .map(([key, value]) => `${key}: '${value}'`);

        const valueInline = `${token}\n${sid}`;

        if (valueInline.length >= valueOutputCutLength.smsAuthData) {
          return (
            <TooltipComponent
              title={(
                <div>
                  {token}
                  <br />
                  {sid}
                </div>
              )}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyMethod(valueInline)}
                  data-tip={valueInline}
                >
                  {prettyCutOutputByCellWidth(token, 'smsAuthData') ?? ''}
                  <br />
                  {prettyCutOutputByCellWidth(sid, 'smsAuthData') ?? ''}
                </div>
                )}
            />
          );
        }

        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => copyMethod(valueInline)}
            data-tip={valueInline}
          >
            {token ?? '-'} 
            <br />
            {sid ?? '-'}
          </div>
        );
      },
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Auth Data'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('additional_params')}>
            <div className="d-flex flex-row">
              <span>Additional Params</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('additional_params')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      cell: ({ additional_params = {} }) => {
        const [firstElem, secondElem] = Object.entries(additional_params)
          .map(([key, value]) => `${key}: '${value}'`);

        const valueInline = `${firstElem ?? '-'}, ${secondElem ?? '-'}`;

        if (valueInline.length >= valueOutputCutLength.smsAuthData) {
          return (
            <TooltipComponent
              title={(
                <div>
                  {firstElem ?? ''}
                  <br />
                  {secondElem ?? ''}
                </div>
)}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyMethod(valueInline)}
                  data-tip={valueInline}
                >
                  {prettyCutOutputByCellWidth(firstElem ?? '-', 'smsAuthData')}
                  <br />
                  {prettyCutOutputByCellWidth(secondElem ?? '-', 'smsAuthData')}
                </div>
              )}
            />
          );
        }

        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => copyMethod(valueInline)}
            data-tip={valueInline}
          >
            {firstElem ?? '-'} 
            <br />
            {secondElem ?? '-'}
          </div>
        );
      },
      minWidth: '164px',
      omit: omitColumn(columnConfig, 'Additional Params'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'end', width: '100%' }}>
          <div className={styles.actionTitleHead}>
            Actions
          </div>
        </div>
      ),
      minWidth: '180px',
      cell: ({ _id, is_active }) => (
        <div className={styles.actionContainerHeader}>
          <div className={styles.actionCell}>
            {isUserHasEditTemplatePermission ? (
              <button
                className={is_active ? styles.disableBtn : styles.enableBtn}
                type="button"
                onClick={() => changeStatus(_id, !is_active)}
              >
                {is_active ? 'Disable' : 'Enable'}
              </button>
            ) : null}
            {isUserHasEditTemplatePermission ? (
              <button
                className={styles.editBtn}
                type="button"
                onClick={() => editAction(_id)}
              >
                Edit
              </button>
            ) : null}
            {isUserHasEditTemplatePermission ? (
              <button
                type="button"
                className={styles.deleteBtn}
                onClick={() => deleteAction(_id)}
              >
                <BsTrashFill size={18} />
              </button>
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from '@mui/material';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { deleteUserWithdrawalTypes, getUserWithdrawalTypesById, updateUserWithdrawalTypes } from '../../../../redux/userWithdrawalTypes/userWithdrawalTypesActions';
import { selectUserWithdrawalTypesData, selectUserWithdrawalTypesTotalCount } from '../../../../redux/userWithdrawalTypes/userWithdrawalTypesSelector';
import { generateSortParams, SortWrapper, useTableSorting } from '../../../../helpers/sortHelper';
import SortIcon from '../../../../components/SortIcon';
import { fetchWithdrawalMethodsOptions } from '../../../../redux/withdrawalMethods/withdrawalMethodsActions';
import { ModalContext } from '../../../../context';
import { ModalUserWithdrawalMethods } from './ModalUserWithdrawalMethods/ModalUserWithdrawalMethods';

export function UserWithdrawalMethods() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const { id } = useParams();

  const userData = useSelector((state) => state?.users?.user);
  const userWithdrawalTypesData = useSelector(selectUserWithdrawalTypesData);
  const userWithdrawalTypesTotalCount = useSelector(selectUserWithdrawalTypesTotalCount);
  
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  useEffect(() => {
    if (id) {
      setTableLoading(true);

      dispatch(getUserWithdrawalTypesById({
        id, limit: rowsPerPage, page, sortParams, 
      }));
  
      setTableLoading(false);
    }
  }, [id, page, rowsPerPage, sortParams]);

  useEffect(() => {
    dispatch(fetchWithdrawalMethodsOptions());
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const switchHandler = async (id, isEnabled) => {
    await dispatch(updateUserWithdrawalTypes(id, { isEnabled }));
  };

  const deleteHandler = async (id) => {
    await dispatch(deleteUserWithdrawalTypes(id));
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('type')}>
          <div className="d-flex flex-row">
            <span>Withdrawal Method</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('type')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ type }) => type,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isEnabled')} style={{ width: '100%' }}>
          <div className="d-flex flex-row" style={{ justifyContent: 'center' }}>
            <span>Status</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isEnabled')} />
            </button>
          </div>
        </SortWrapper>
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ isEnabled }) => (
        <div style={{ textAlign: 'center', width: '100%' }}>
          {isEnabled ? 'Enabled' : 'Disabled'}
        </div>
      ),
    },
    {
      name: (
        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingRight: '8px', 
        }}
        >
          Actions
        </div>
      ),
      minWidth: '200px',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ isEnabled, _id }) => (
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: '100%',
        }}
        >
          <Switch checked={isEnabled} color="success" onChange={(_, checked) => switchHandler(_id, checked)} />
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteHandler(_id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            <button 
              type="button" 
              className="btn-primary_light"
              onClick={() => showModal({ 
                bodyContent: <ModalUserWithdrawalMethods hideModal={hideModal} userId={userData._id} />, 
                headerContent: <h5>{`Add Withdrawals Method Config For ${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}</h5>, 
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add New
            </button>
          </div>
        </div>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        <DataTable
          columns={columns}
          data={userWithdrawalTypesData}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={userWithdrawalTypesTotalCount}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          paginationDefaultPage={page}
          persistTableHead
          highlightOnHover
          progressPending={tableLoading}
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}

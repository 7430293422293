import React, { useMemo, useState } from 'react';
import {
  Modal, Button, ModalHeader, ModalBody, 
} from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import transition from 'react-element-popper/animations/transition';
import opacity from 'react-element-popper/animations/opacity';
import 'react-multi-date-picker/styles/colors/teal.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { updateEventById } from '../../redux/event/eventActions';

export function EditEventModal({ showModal, setShowModal, focusedEvent }) {
  const dispatch = useDispatch();
  const {
    _id, crmUserId, userId, duration, time, name, description, remind, 
  } = focusedEvent;

  const [newTime, setNewTime] = useState(new Date(time));
  const [newDuration, setNewDuration] = useState(duration);
  const [newDescription, setNewDescription] = useState(description);
  const [newRemind, setNewRemind] = useState(remind);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    const data = {
      name,
      crmUserId,
      userId,
      time: new Date(newTime).valueOf(),
      duration: newDuration,
      description: newDescription,
      remind: newRemind,
    };
    dispatch(updateEventById(_id, data));
    handleCloseModal();
  };

  const isBtnDisabled = useMemo(() => !(new Date(newTime).valueOf() !== time || newDuration !== duration || newDescription !== description || newRemind !== remind), [newTime, newDuration, newDescription, newRemind]);

  return (
    <Modal
      className="widthdrawal-modal"
      centered
      show={showModal}
      onHide={setShowModal}
    >
      <ModalHeader closeButton>
        <h5>{name}</h5>
      </ModalHeader>

      <ModalBody>
        <div>
          <div className={styles.modalTime}>
            Time
            <DatePicker 
              value={newTime}
              onChange={setNewTime}
              format="MM/DD/YYYY HH:mm"
              className="bg-dark teal" 
              inputClass={styles.modalTimeInput}
              animations={[
                opacity(), 
                transition({ from: 35, duration: 800 }),
              ]}
              plugins={[
                <TimePicker hideSeconds />,
              ]}
            />
          </div>
          <div className={styles.modalDuration}>
            Duration
            <Select 
              className={styles.modalDurationSelector} 
              style={{ color: '#fff' }}
              value={newDuration}
              onChange={(e) => setNewDuration(e.target.value)}
              data-testid="Duration"
            >
              <MenuItem value={5}>5 minutes</MenuItem>
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={120}>2 hour</MenuItem>
              <MenuItem value={160}>3 hour</MenuItem>
              <MenuItem value={180}>6 hour</MenuItem>
            </Select>
          </div>

          <div className={styles.modalDescriptionWrapper}>
            Description
            <textarea 
              className={styles.eventModalDescription}
              placeholder="Event Description..."
              rows={4}
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
            />
          </div>

          <div className={styles.modalDuration}>
            Remind me at
            <Select 
              className={styles.modalDurationSelector} 
              style={{ color: '#fff' }}
              value={newRemind}
              onChange={(e) => setNewRemind(e.target.value)}
            >
              <MenuItem value={5}>5 minutes</MenuItem>
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={120}>2 hour</MenuItem>
              <MenuItem value={160}>3 hour</MenuItem>
              <MenuItem value={180}>6 hour</MenuItem>
            </Select>
          </div>

        </div>
      </ModalBody>

      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="success" disabled={isBtnDisabled} onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';
import Switch from '@mui/material/Switch';
import { colourStyles } from '../../../../helpers/styles';
import { ModalContext } from '../../../../context';
import { getCRMUsers } from '../../../../redux/crmUser/crmUserActions';
import { getCfdSettings } from '../../../../redux/cfdSettings/cfdActions';
import { createNewVariables } from '../../../../redux/variables/variablesActions';
import {
  optionsAlias, variantOptions, handlePercentageValue, columnsOnPlatform, 
} from '../../helper';
import { geAccountCurrencies } from '../../../../redux/cfdAccountCurrency/cfdAccountCurrencyActions';

import styles from './AddVariablesModal.module.css';


export function AddVariablesModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const allBrands = useSelector((state) => state.brands);
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const { cfdSettings: settings } = useSelector((state) => state.cfdSettings);

  const [aliasValue, setAliasValue] = useState('');
  const [brandIds, setBrandIds] = useState('');
  const [variant, setVariant] = useState('');
  const [asset, setAsset] = useState({});
  const [managersOptions, setManagersOptions] = useState([]);
  const [alertMessageText, setAlertMessageText] = useState('');
  const [percentageFee, setPercentageFee] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [runningAds, setRunningAds] = useState(false);
  const [emailVerfication, setEmailVerfication] = useState(false);
  const [platfomColumns, setPlatformColumns] = useState([]);
  const cfdAccountCurrencies = useSelector((state) => state.cfdAccountCurrencies);

  const [formData, setFormData] = useState({ actions: optionsAlias[0]?.value || 'Alert message' });

  useEffect(() => {
    if (!asset) return;

    setFormData((prevValue) => ({
      ...prevValue,
      value: asset.value,
    }));
  }, [asset]);

  useEffect(() => {
    Promise.all([
      dispatch(getCfdSettings()), 
      dispatch(getCRMUsers()),
      dispatch(geAccountCurrencies()),
    ]);
  }, []);

  const brandsOptions = allBrands.map(({ name, _id }) => ({
    key: _id,
    value: _id,
    label: name,
  }));

  const crmUsersOptions = crmUsers.map(({ _id, firstName, lastName }) => ({
    key: _id,
    value: _id,
    label: `${firstName} ${lastName}`,
  }));

  const assetOptions = settings.map(({ symbol, _id, label }) => ({
    key: _id,
    value: symbol,
    label,
  }));

  const accountCurrenciesOptions = cfdAccountCurrencies.map(({ symbol, _id }) => ({
    key: _id,
    value: symbol,
    label: symbol,
  }));

  const handleSelect = ({ target: { value, name } }) => {
    if (name === 'value') setAlertMessageText(value);
    if (name === 'actions') setAliasValue(value);
    if (name === 'brandIds') setBrandIds(value);
    if (name === 'variant') setVariant(value);
    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePercentageSelect = ({ target: { value } }) => {
    setPercentageFee(handlePercentageValue(value));
    setFormData((prev) => ({ ...prev, value: Number(handlePercentageValue(value)) }));
  };

  const handleRunningAdsSelect = (checked) => {
    setRunningAds(checked);
    setFormData((prev) => ({ ...prev, value: checked }));
  };

  const handleEmailVerificationSelect = (checked) => {
    setEmailVerfication(checked);
    setFormData((prev) => ({ ...prev, value: checked }));
  };

  const handleManagers = (value) => {
    setManagersOptions(value);
    const managerIds = value.map(({ value }) => value);
    const managerNames = value.map(({ label }) => label);
    setFormData((prevValue) => ({
      ...prevValue,
      value: managerIds,
      managerNames,
    }));
  };

  const submitForm = async (event) => {
    event.preventDefault();
    await dispatch(createNewVariables(formData));
    hideModal();
  };

  const handleMultiSelectChange = (columns) => {
    setPlatformColumns(columns);

    setFormData((prevValue) => ({
      ...prevValue,
      value: columns.map(({ value }) => value),
    }));
  };

  const handleNumberValue = ({ target: { value } }) => {
    const positiveIntegerRegex = /^[1-9]\d*$/;
    if (positiveIntegerRegex.test(value) || value === '') {
      setNumberValue(value);
      setFormData((prev) => ({ ...prev, value: value === '' ? '' : Number(value) }));
    }
  };

  const variantElements = {
    'Self User Registration Assign': (
      <div className="form-group mt-2 custom-milti-select">
        <label className="control-label" htmlFor="crmUser">Crm User</label>
        <MultiSelect
          name="value"
          options={crmUsersOptions}
          value={managersOptions}
          onChange={handleManagers}
          labelledBy="Select"
          includeSelectAllOption="true"
        />
      </div>
    ),
    'Alert message': (
      <>
        <div className="form-group">
          <label className="lead" htmlFor="variant">Variant</label>
          <select
            name="variant"
            value={variant}
            className={styles.selector}
            onChange={handleSelect}
          >
            <option value="">Choose variant</option>
            { variantOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>) }
          </select>
        </div>
        <div className="form-group col-md-12 pt-2 mb-4">
          <label className="control-label" htmlFor="value">Add new alert message</label>
          <textarea 
            rows="4" 
            placeholder="Type Alert Message" 
            className="form-control" 
            name="value" 
            value={alertMessageText} 
            onChange={handleSelect}
          />
        </div>
      </>
    ),
    'Display Verification Notification': (
      <>
        <div className="form-group">
          <label className="lead" htmlFor="variant">Variant</label>
          <select
            name="variant"
            value={variant}
            className={styles.selector}
            onChange={handleSelect}
          >
            <option value="">Choose variant</option>
            { variantOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>) }
          </select>
        </div>
        <div className="form-group col-md-12 pt-2 mb-4">
          <label className="control-label" htmlFor="value">Add new notification message</label>
          <textarea 
            rows="4"  
            className="form-control" 
            name="value" 
            value={alertMessageText} 
            onChange={handleSelect}
          />
        </div>
      </>
    ),
    'Monthly inactivity fee': (
      <div className="form-group">
        <label className="control-label">Value</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter value in %"
          aria-label=""
          aria-describedby=""
          value={percentageFee}
          name="value"
          onChange={handlePercentageSelect}
        />
      </div>
    ),
    'Running Advertisement': (
      <div className="form-group">
        <label className="control-label">Value</label>
        <Switch checked={runningAds} color="success" onChange={(_, checked) => handleRunningAdsSelect(checked)} />
      </div>
    ),
    'Email Verification': (
      <div className="form-group">
        <label className="control-label">Value</label>
        <Switch checked={emailVerfication} color="success" onChange={(_, checked) => handleEmailVerificationSelect(checked)} />
      </div>
    ),
    'Hide columns on platform': (
      <div className="form-group custom-milti-select mt-2">
        <label className="lead">Columns to hide</label>
        <MultiSelect
          name="columns"
          options={columnsOnPlatform}
          value={platfomColumns}
          onChange={handleMultiSelectChange}
          labelledBy="Select"
          includeSelectAllOption="false"
        />
      </div>
    ),
    'Start Asset on Platform': (
      <div className="form-group mt-2">
        <label className="lead">Asset</label>
        <Select
          styles={colourStyles}
          options={assetOptions}
          value={asset}
          isSearchable
          onChange={(option) => setAsset(option)}
        />
      </div>
    ),
    'Default currency for default CFD account': (
      <div className="form-group mt-2">
        <label className="lead">Currency</label>
        <Select
          styles={colourStyles}
          options={accountCurrenciesOptions}
          value={asset}
          isSearchable
          onChange={(option) => setAsset(option)}
        />
      </div>
    ),
    'Auto logout time': (
      <div className="form-group mt-2">
        <label className="control-label">Value (in minutes)</label>
        <input
          type="text"
          className="form-control"
          aria-label=""
          aria-describedby=""
          value={numberValue}
          name="value"
          onChange={handleNumberValue}
        />
      </div>
    ),
    'Promote User on Deposit': (
      <div className="form-group mt-2">
        <label className="control-label">Amount (in USD)</label>
        <input
          type="text"
          className="form-control"
          aria-label=""
          aria-describedby=""
          value={numberValue}
          name="value"
          onChange={handleNumberValue}
        />
      </div>
    ),
  };

  return (
    <form onSubmit={submitForm}>
      <div className="form-group">
        <label className="lead" htmlFor="actions">Process</label>
        <select
          name="actions"
          value={aliasValue}
          className={styles.selector}
          onChange={handleSelect}
        >
          { optionsAlias.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>) }
        </select>
      </div>
      <div className="form-group">
        <label className="lead" htmlFor="brandIds">Brand</label>
        <select
          name="brandIds"
          value={brandIds}
          className={styles.selector}
          onChange={handleSelect}
        >
          <option value="">Choose brand</option>
          { brandsOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>) }
        </select>
      </div>
      { variantElements[formData.actions] }
      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Add
        </button>
      </div>
    </form>
  );
}

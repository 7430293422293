import React, {
  useEffect, useState, 
} from 'react';
import {
  EditorState, convertToRaw, ContentState, convertFromRaw, Modifier, convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import './react-draft-wysiwyg.css';
import s from './EditorComponent.module.css';

function EditorComponent({
  template, setMailHtmlBodyS, setMailJsonBodyS, customButtonTitles = null, 
}) {
  // blocks must be empty, for correct Editor rendering
  const initJsonContentValue = '{"blocks":[],"entityMap":{}}';
  const defaultJsonContentValue = '{"blocks":[{"text":""}],"entityMap":{}}';

  if (!customButtonTitles) {
    customButtonTitles = [
      'firstName',
      'lastName',
      'fullName',
      'email',
      'phone',
      'country',
      'state',
      'address',
      'companyName',
      'siteUrl',
      'marginRatio',
      'paid_amount',
      'paid_currency',
      'received_amount',
      'received_currency',
      'conversion_fee',
      'amount',
      'currency',
      'heading',
      'order_type',
      'order_direction',
      'order_value',
      'order_value_symbol',
      'order_qty',
      'order_qty_symbol',
      'order_price',
      'order_price_symbol',
      'browser',
      'os',
    ];
  }

  const mailBodyJSON = JSON.parse(initJsonContentValue);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(mailBodyJSON)));
  const [contentState, setContentState] = useState(mailBodyJSON);

  useEffect(() => {
    if (template && Object.entries(template).length > 0) {
      const mailHtmlStringified = template.mailBodyHtml ?? '<p>This is a sample HTML content</p>';
      const mailJsonStringified = (template.mailBodyJson ?? defaultJsonContentValue).trim() || defaultJsonContentValue;

      setMailHtmlBodyS(mailHtmlStringified);
      setMailJsonBodyS?.(mailJsonStringified);

      const contentJsonState = JSON.parse(mailJsonStringified);
      const newEditorState = EditorState.createWithContent(convertFromRaw(contentJsonState));

      setEditorState(newEditorState);
      setContentState(contentJsonState);
    }
  }, [setContentState, template]);

  const onEditorStateChange = (editorState) => setEditorState(editorState);
  const onContentStateChange = (newContentState) => {
    const mailHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const contentStringify = JSON.stringify(newContentState);

    setMailHtmlBodyS(mailHtml);
    setMailJsonBodyS?.(contentStringify);

    setContentState(newContentState);
  };

  if (template && contentState.blocks.length === 0) {
    return <>loading</>;
  }

  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'], // Customize this array
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
  };

  return (
    <Editor 
      initialEditorState={editorState}
      initialContentState={contentState}
      toolbar={toolbarOptions}
      toolbarClassName={s.toolbar}
      editorClassName={s.editor}
      onEditorStateChange={onEditorStateChange}
      onContentStateChange={onContentStateChange}
      toolbarCustomButtons={
        [
          <ActionButtonCreator />,
          ...customButtonTitles.map((title) => <CustomOption key={title} title={title} />),
        ]
      }
    />
  );
}

function CustomOption({ title, editorState, onChange }) {
  const handleClick = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ` %${title}% `,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return (
    <div onClick={handleClick} className={s.customEditorOption}>{title}</div>
  );
}

function ActionButtonCreator({
  editorState, onChange, 
}) {
  const [customBtnLink, setLink] = useState('');
  const [customBtnTitle, setTitle] = useState('');
  const [isExpanded, setExpand] = useState(false);

  const handleActionBtnClick = () => setExpand(true);
  const handleCancel = () => setExpand(false);

  const handleOkBtn = () => {
    const html = `<a style="color:#fff !important;" href="${customBtnLink}" target="_blank" class="button">${customBtnTitle}</a>`;

    if (customBtnLink === '' || customBtnTitle === '') {
      toast.error('Link or Title is empty');
      return;
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      html,
      editorState.getCurrentInlineStyle(),
    );

    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    setExpand(false);
  };

  return (
    <div>
      {isExpanded && (
        <div className={s.expandedAreaWrapper}>
          <div>
            <small>Link</small>
            <br />
            <input 
              className="rdw-embedded-modal-link-input" 
              value={customBtnLink} 
              onInput={(e) => setLink(e.target.value)} 
              type="text" 
            />
          </div>

          <div>
            <small>Title</small>
            <br />
            <input 
              className="rdw-embedded-modal-link-input" 
              value={customBtnTitle} 
              onInput={(e) => setTitle(e.target.value)} 
              type="text" 
            />
          </div>

          <div>
            <button className="rdw-embedded-modal-btn" onClick={handleOkBtn} type="button">Ok</button>
            <button className="rdw-embedded-modal-btn" onClick={handleCancel} type="button">Cancel</button>
          </div>
        </div>
      )}
      <div onClick={handleActionBtnClick} className={s.customEditorBtn}>
        Add action button
      </div>
    </div>
  );
}

export default EditorComponent;

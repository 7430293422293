import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  SET_DEPOSIT_METHODS,
  SET_IS_DEPOSIT_METHODS_LOADING,
  UPDATE_DEPOSIT_METHOD,
  SET_BRAND_SEARCH_FILTER,
  SET_DEPOSIT_METHOD_SEARCH_FILTER,
  SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  SET_TOTAL_COUNT_DEPOSIT_METHODS,
  SET_DEPOSIT_METHODS_OPTIONS,
  SET_COUNTRIES_EXCLUDE_SEARCH_FILTER,
} from './depositMethodsTypes';

export const setDepositMethodsOptionsCreator = (value) => ({
  type: SET_DEPOSIT_METHODS_OPTIONS,
  payload: value,
});

export const setBrandFilterCreator = (value) => ({
  type: SET_BRAND_SEARCH_FILTER,
  payload: value,
});

export const setDepositMethodFilterCreator = (value) => ({
  type: SET_DEPOSIT_METHOD_SEARCH_FILTER,
  payload: value,
});

export const setCountriesIncludedFilterCreator = (value) => ({
  type: SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  payload: value,
});

export const setCountriesExcludedFilterCreator = (value) => ({
  type: SET_COUNTRIES_EXCLUDE_SEARCH_FILTER,
  payload: value,
});

export const setDepositMethods = (methods) => ({
  type: SET_DEPOSIT_METHODS,
  payload: methods,
});

export const setTotalCount = (totalCount) => ({
  type: SET_TOTAL_COUNT_DEPOSIT_METHODS,
  payload: totalCount,
});

export const setIsDepositMethodsLoading = (status) => ({
  type: SET_IS_DEPOSIT_METHODS_LOADING,
  payload: status,
});

export const fetchDepositMethodsOptions = () => async (dispatch) => {
  try {
    const { data: result } = await apiHelper('get', '/api/deposit-type/options');

    if (result?.success && result?.options) {
      dispatch(setDepositMethodsOptionsCreator(result.options));
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const fetchDepositMethods = (
  page = 1,
  rowsPerPage = 25,
  sortParams,
  filters = {},
) => async (dispatch) => {
  dispatch(setIsDepositMethodsLoading(true));
  try {
    const { data: result } = await apiHelper('get', '/api/deposit-type', '', {
      page, limit: rowsPerPage, sort: sortParams, ...filters,
    });

    if (result?.success && result?.data) {
      dispatch(setDepositMethods(result.data));
      dispatch(setTotalCount(result.totalCount));
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    dispatch(setIsDepositMethodsLoading(false));
  }
};

export const updateDepositMethod = (id, data) => async (dispatch) => {
  try {
    const { data: result } = await apiHelper('put', `/api/deposit-type/${id}`, data);
    if (result?.success && result?.depositType) {
      dispatch({
        type: UPDATE_DEPOSIT_METHOD,
        payload: result.depositType,
      });

      toast.success(result.message);
    }
  } catch (error) {
    console.log(error.response);
  }
};

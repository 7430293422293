import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo, faSort } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ModalFilters } from '../components';
import {
  omitColumn, dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength,  
} from './helper';
import { ModalContext } from '../context';
import { EditOrderModal } from '../pages/OrdersHistory/EditOrderModal';
import { toFixed } from '../helpers/utils';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const OrdersHistoryColumn = ({
  columnConfig,
  filters,
  setFilters,
  tradingTypeFilter,
  setTradingTypeFilter,
  coinsPairs,
  coinPairsFilter,
  setCoinPairsFilter,
  permissionName,
  handleRevertTradeHistory,
  userNameValue,
  handleChangeUserName,
  userId = '', 
  handleSort,
  startTimeFilter,
  setStartTimeFilter,
  endTimeFilter,
  setEndTimeFilter,
}) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');
  const isUserCanEditOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history_edit');
  const isUserCanRevertOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history_revert');

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('customId')}>
          <div className="d-flex flex-row">
            <span>ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('customId')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(customId, 'id')}
                </span>
              )}
            />
          )
          : (
            <span>
              {customId}
            </span>
          )
      ),
      selector: () => 'customId',
      minWidth: '100px',
      omit: omitColumn(columnConfig, 'ID'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-1" style={{ alignItems: 'start', height: '80px' }}>
          <SortWrapper handleSort={() => sortFields('user.firstName user.lastName')}>
            <div className="d-flex flex-row">
              <span>User</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.firstName user.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="userName"
            value={userNameValue}
            minLength="3"
            onChange={({ target: { value } }) => handleChangeUserName(value)}
          />
          {
            (userNameValue.length >= 1 && userNameValue.length < 3) && (
            <span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>
            )  
          }
        </div>
      ),
      selector: (row) => {
        const userFullName = `${row.user.firstName} ${row.user.lastName}`;
        if ((row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard)) {
          return (
            userFullName.length >= valueOutputCutLength.clientWithdrawal
              ? (
                <TooltipComponent
                  title={userFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <Link
                      to={`/user-detail/${row?.user?._id}`}
                      className="text-decoration-none text-white"
                    >
                      {prettyCutOutputByCellWidth(userFullName, 'clientWithdrawal')}
                    </Link>
                  )}
                />
              )
              : (
                <Link
                  to={`/user-detail/${row?.user?._id}`}
                  className="text-decoration-none text-white"
                >
                  {userFullName}
                </Link>
              )
          );
        }
        return (
          userFullName.length >= valueOutputCutLength.clientWithdrawal
            ? (
              <TooltipComponent
                title={userFullName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(userFullName, 'clientWithdrawal')}</span>
                )}
              />
            )
            : (
              <span>{userFullName}</span>
            )
        );
      },
      omit: !!userId.length || omitColumn(columnConfig, 'User'),
      minWidth: '174px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('primary')}>
            <div className="d-flex flex-row">
              <span>Trading Pair</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('primary')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Trading Pair" 
            data={coinsPairs}
            filters={coinPairsFilter}
            setFilters={setCoinPairsFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      selector: (row) => (row.primary || 0) + (row.secondary || 0),
      omit: omitColumn(columnConfig, 'Trading Pair'),
      minWidth: '140px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('tradeType')}>
            <div className="d-flex flex-row">
              <span>Trading Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('tradeType')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Trading Type" 
            data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
            filters={tradingTypeFilter}
            setFilters={setTradingTypeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      selector: (row) => (row?.tradeType === 1 ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Trading Type'),
      minWidth: '144px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('tradeStartPrice')}>
          <div className="d-flex flex-row">
            <span>Price Start</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('tradeStartPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        toFixed(row.tradeStartPrice, 4)?.toString().length >= valueOutputCutLength.balance
          ? (
            <TooltipComponent
              title={toFixed(row.tradeStartPrice, 4).toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(toFixed(row.tradeStartPrice, 4).toString(), 'balance')}
                </span>
            )}
            />
          )
          : (
            <span>
              {toFixed(row.tradeStartPrice, 4)}
            </span>
          )
      ),
      selector: () => 'tradeStartPrice',
      minWidth: '130px',
      omit: omitColumn(columnConfig, 'Price Start'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('position')}>
          <div className="d-flex flex-row">
            <span>Volume</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('position')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ position }) => (
        toFixed(position, 3)?.toString().length >= valueOutputCutLength.volume
          ? (
            <TooltipComponent
              title={toFixed(position, 3).toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(toFixed(position, 3).toString(), 'volume')}
                </span>
          )}
            />
          )
          : (
            <span>
              {toFixed(position, 3)}
            </span>
          )
      ),
      selector: () => 'position',
      minWidth: '100px',
      omit: omitColumn(columnConfig, 'Volume'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('tradeEndPrice')}>
          <div className="d-flex flex-row">
            <span>Price End</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('tradeEndPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        parseFloat(row.tradeEndPrice).toFixed(4)?.toString().length >= valueOutputCutLength.balance
          ? (
            <TooltipComponent
              title={parseFloat(row.tradeEndPrice).toFixed(4)?.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(parseFloat(row.tradeEndPrice).toFixed(4)?.toString(), 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {parseFloat(row.tradeEndPrice).toFixed(4)}
            </span>
          )
      ),
      selector: () => 'tradeEndPrice',
      minWidth: '120px',
      omit: omitColumn(columnConfig, 'Price End'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('userInvestedAmount')}>
          <div className="d-flex flex-row">
            <span>Margin</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('userInvestedAmount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ userInvestedAmount }) => (
        toFixed(userInvestedAmount, 2)?.toString()?.length >= valueOutputCutLength.investAmount
          ? (
            <TooltipComponent
              title={toFixed(userInvestedAmount, 2)?.toString()}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(toFixed(userInvestedAmount, 2)?.toString(), 'investAmount')}
                </span>
              )}
            />
          )
          : (
            <span>
              {toFixed(userInvestedAmount, 2).toString()}
            </span> 
          )

      ),
      selector: () => 'userInvestedAmount',
      minWidth: '100px',
      omit: omitColumn(columnConfig, 'Margin'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('exitPrice')}>
          <div className="d-flex flex-row">
            <span>Profit/Loss</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('exitPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        parseFloat(row.exitPrice).toFixed(2)?.toString()?.length >= valueOutputCutLength.profitLoss
          ? (
            <TooltipComponent
              title={parseFloat(row.exitPrice).toFixed(2)?.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(parseFloat(row.exitPrice).toFixed(2)?.toString(), 'profitLoss')}
                </span>
            )}
            />
          )
          : (
            <span>
              {parseFloat(row.exitPrice).toFixed(2)?.toString()}
            </span> 
          )
      ),
      selector: () => 'exitPrice',
      minWidth: '130px',
      omit: omitColumn(columnConfig, 'Profit/Loss'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('endSwap')}>
          <div className="d-flex flex-row">
            <span>Swap</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('endSwap')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ endSwap }) => (endSwap ? `-${endSwap}` : 0),
      selector: () => 'endSwap',
      minWidth: '80px',
      omit: omitColumn(columnConfig, 'Swap'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('endCommission')}>
          <div className="d-flex flex-row">
            <span>Commission</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('endCommission')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ endCommission }) => (endCommission ? `-${endCommission}` : 0),
      selector: () => 'endCommission',
      minWidth: '130px',
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Start Time</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Start Time" 
            onClick={(e) => e.stopPropagation()}
            data={dateOptions}
            filters={startTimeFilter}
            setFilters={setStartTimeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
            isDateOptions
          />
        </div>
      ),
      cell: (row) => moment(row.timeOpened).format('YYYY-MM-DD HH:mm:ss'),
      selector: () => 'createdAt',
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'Start Time'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('closedAt')}>
            <div className="d-flex flex-row">
              <span>End Time</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('closedAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="End Time" 
            onClick={(e) => e.stopPropagation()}
            data={dateOptions}
            filters={endTimeFilter}
            setFilters={setEndTimeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
            isDateOptions
          />
        </div>
      ),
      cell: (row) => moment(row.closedAt).format('YYYY-MM-DD HH:mm:ss'),
      selector: () => 'closedAt',
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'End Time'),
    },
    {
      name: 'Action',
      selector: (row) => (
        row.isResolved !== false
          ? (
            <>
              {isUserCanEditOrdersHistory && (
                <button
                  type="button"
                  className="btn btn-success btn-sm me-1 p-1"
                  onClick={() => {
                    showModal({
                      headerContent: <h3>Edit Order</h3>,
                      bodyContent: <EditOrderModal order={row} hideModal={hideModal} />,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
                </button>
              )}
              {isUserCanRevertOrdersHistory && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm me-1 p-1"
                  onClick={(e) => handleRevertTradeHistory(e, row)}
                >
                  <FontAwesomeIcon icon={faUndo} className="header-icon text-white" />
                </button>
              )}
            </>
          )
          : <span>-</span>
      ),
      minWidth: '170px',
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];

  return columns;
};

import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_USER_KYC_COMMENTS,
  DELETE_USER_KYC_COMMENT,
  UPDATE_USER_KYC_COMMENT,
  CREATE_USER_KYC_COMMENT,
} from './kycCommentTypes';

export const getUserKYCComments = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/kyc-comment/user-comments/${id}`, '');

    if (res?.data?.success) {
      dispatch({
        type: GET_USER_KYC_COMMENTS,
        payload: res.data.kycComments,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteUserKYCComment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/kyc-comment/${id}`, '');

    if (res?.data?.success) {
      dispatch({
        type: DELETE_USER_KYC_COMMENT,
        payload: res.data.kycComment,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateUserKYCComment = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/kyc-comment/${id}`, data);
  
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_USER_KYC_COMMENT,
        payload: res.data.kycComment,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const createUserKYCComment = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/kyc-comment', data);
    
    if (res?.data?.success) {
      dispatch({
        type: CREATE_USER_KYC_COMMENT,
        payload: res.data.kycComment,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_CLIENT_LOGINS,
  GET_CLIENT_LOGIN,
  ADD_CLIENT_LOGIN,
  EDIT_CLIENT_LOGIN,
  DELETE_CLIENT_LOGIN,
  TOGGLE_CLIENT_LOGIN_STATE,
  ERROR_CLIENT_LOGIN_STATE,
} from './clientLoginTypes';
import { apiHelper } from '../apiHelper';

export const getClientLogins = ({
  page, rowsPerPage, userFilters, userId, sortParams,
}) => async (dispatch) => {
  try {
    let url = `/api/client-login-log?page=${page}&rowsPerPage=${rowsPerPage}&filters=${JSON.stringify(userFilters)}`;

    if (userId) url += `&userId=${userId}`;
    if (sortParams) url += `&sort=${sortParams}`;

    const res = await apiHelper('get', url);

    if (res && res.data && res.data.success) {
      const { clientLoginLogs } = res.data;

      await dispatch({
        type: GET_CLIENT_LOGINS,
        payload: clientLoginLogs,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getClientLogin = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/client-login-log/${id}`, '');
    if (res?.data && res?.data?.clientLoginLog) {
      const { data } = res;
      await dispatch({
        type: GET_CLIENT_LOGIN,
        payload: data?.clientLoginLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addClientLogin = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/client-login-log/add', data);

    if (res?.data && res?.data?.clientLoginLog_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_CLIENT_LOGIN,
        payload: data?.clientLoginLog_,
      });
    } else {
      await dispatch({
        type: ERROR_CLIENT_LOGIN_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editClientLogin = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/client-login-log/${id}`, data);
    if (res?.data && res?.data?.clientLoginLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_CLIENT_LOGIN,
        payload: data?.clientLoginLog,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteClientLogin = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/client-login-log/${id}`, '');
    if (res?.data && res?.data?.clientLoginLog) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_CLIENT_LOGIN,
        payload: data?.clientLoginLog?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateClientLoginState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_CLIENT_LOGIN_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

// eslint-disable-next-line no-unused-vars
export const exportClientLoginToFile = (data) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const res = await apiHelper('post', '/api/client-login-log/export', data, {}, 'application/json', { 'x-timezone': timezone });
    if (res?.data) {
      const { data } = res;
      if (data?.csvFile) {
        window.open(data?.csvFile);
      }
      if (data?.xslxFile) {
        window.open(data?.xslxFile);
      }
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

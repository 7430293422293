import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditSalesStatusModalBody } from './EditSalesStatusModal';
import { deleteSalesStatus } from '../../../redux/salesStatus/salesStatusActions';
import { ModalContext } from '../../../context';
import { ModalFilters } from '../../../components';
import { SortWrapper, useTableSorting } from '../../../helpers/sortHelper';
import SortIcon from '../../../components/SortIcon';
import { omitColumn } from '../../../DataTableSchemas/helper';

export const StatusSchema = ({
  columnConfig,
  filters,
  setFilters,
  statusNameFilter,
  setStatusNameFilter,
  statusTypeFilter, 
  setStatusTypeFilter,
  handleSort,
}) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanEditSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('edit_sales_status');
  const isUserCanDelteSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('delete_sales_status');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteSalesStatus(id));
      }
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('name')}>
            <div className="d-flex flex-row">
              <span>Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('name')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Name"
            autoComplete="off"
            value={statusNameFilter || ''}
            onChange={({ target: { value } }) => setStatusNameFilter(value)}
          />
        </div>
      ),
      cell: ({ name }) => name,
      omit: omitColumn(columnConfig, 'Name'),
    },
    // {
    //   name: (
    //     <div className="d-flex flex-row">
    //       <SortWrapper handleSort={() => sortFields('type')}>
    //         <div className="d-flex flex-row">
    //           <span>Type</span>
    //           <button type="button" className="sort-btn">
    //             <SortIcon image={getSortIcon('type')} />
    //           </button>
    //         </div>
    //       </SortWrapper>
    //       <ModalFilters
    //         data={[
    //           { name: 'Sales', _id: '0' },
    //           { name: 'Retention', _id: '1' },
    //           { name: 'Both', _id: '2' },
    //         ]}
    //         filters={statusTypeFilter}
    //         setFilters={setStatusTypeFilter}
    //         tabName="Type"
    //         searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
    //         usersFilters={{}}
    //         storageName="Type"
    //         setUserFilters={() => {}}
    //       />
    //     </div>
    //   ),
    //   cell: ({ type }) => (type === 2 ? 'Both' : (type === 1 ? 'Retention' : 'Sales')),
    //   omit: omitColumn(columnConfig, 'Type'),
    // },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('color')}>
          <div className="d-flex flex-row">
            <span>Color</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('color')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ color }) => (
        <span className="picked-value" style={{ borderLeftColor: color ?? '#aabbcc' }}>
          {color ?? '#aabbcc'}
        </span>
      ),
      omit: omitColumn(columnConfig, 'Color'),
    },
  ];

  if (isUserCanEditSalesStatuses || isUserCanDelteSalesStatuses) {
    columns.push({
      name: 'Actions',
      cell: ({ _id }) => (
        <div className="actions">
          {isUserCanEditSalesStatuses && (

          <button
            type="button"
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({ 
              bodyContent: <EditSalesStatusModalBody id={_id} />, 
              headerContent: <h3>Edit Status</h3>, 
            })}
          >
            <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
          </button>
          )}
          {isUserCanDelteSalesStatuses && (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(_id)}
            >
              <FontAwesomeIcon icon={faTrash} className="header-icon text-white" />
            </button>
          )}
        </div>
      ),
      omit: omitColumn(columnConfig, 'Actions'),
    });
  }

  return columns;
};

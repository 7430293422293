import React, {
  useState, useEffect, useContext, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { ModalContext } from '../../../context';
import { updateState, getSalesStatus, editSalesStatus } from '../../../redux/salesStatus/salesStatusActions';
import { useClickOutside } from '../../../hooks/useClickOutside';

export function EditSalesStatusModalBody({ id }) {
  const dispatch = useDispatch();
  const hexColorPickerRef = useRef(null);
  const { hideModal } = useContext(ModalContext);

  const [currStatus, setCurrStatus] = useState({ name: '', type: 0 });
  const [color, setColor] = useState('#aabbcc');
  const [nameErr, setNameErr] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const salesStatusData = useSelector((state) => state?.salesStatus?.salesStatus);
  const salesStatusfetched = useSelector((state) => state?.salesStatus?.salesStatusfetched);
  const salesStatusEditted = useSelector((state) => state?.salesStatus?.salesStatusEditted);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getSalesStatus(id));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (salesStatusfetched) {
        await dispatch(updateState());
      }
    }
    fetchData();
  }, [salesStatusfetched]);

  useEffect(() => {
    if (salesStatusEditted) {
      dispatch(updateState());
    }
  }, [salesStatusEditted]);

  useEffect(() => {
    if (salesStatusData) {
      setColor(salesStatusData?.color);
      setCurrStatus(salesStatusData);
    }
  }, [salesStatusData]);

  const handleChange = (e) => {
    setCurrStatus({ ...currStatus, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = currStatus;
    if (name === '') {
      setNameErr('Status name is required');
    } else {
      const data = {
        name: currStatus.name,
        type: currStatus.type,
        color,
      };
      await dispatch(editSalesStatus(id, data));
      hideModal();
    }
  };

  useClickOutside({ onClickOutside: () => setDisplayColorPicker(false), ref: hexColorPickerRef });

  return (
    <form>
      <div className="form-group col-md-12">
        <label className="control-label">Status Name</label>
        <input
          type="text"
          required="required"
          className="form-control"
          name="name"
          onChange={handleChange}
          value={currStatus.name}
          placeholder="Enter status name"
        />
        {nameErr ? (<span className="errMsg">{nameErr}</span>) : ('')}
      </div>
      {/* <div className="form-group col-md-12">
        <label className="control-label">Select Type</label>
        <select className="form-control" required="required" name="type" value={currStatus.type} onChange={handleChange}>
          <option value={0}>Sales</option>
          <option value={1}>Retention</option>
          <option value={2}>Both</option>
        </select>
      </div> */}
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Status Color</label>
        <div>
          <button 
            className="btn btn-secondary" 
            onClick={() => setDisplayColorPicker(!displayColorPicker)} 
            type="button"
          >
            Pick Color
          </button>
          {displayColorPicker && (
            <div ref={hexColorPickerRef} style={{ position: 'absolute' }}>
              <HexColorPicker 
                color={color} 
                onChange={(e) => {
                  setColor(e);
                }}
              />
              <HexColorInput 
                color={color} 
                onChange={setColor} 
                placeholder="Type a color" 
                prefixed 
                alpha
              />
            </div>
          )}
          <span className="picked-value" style={{ borderLeftColor: color }}>
            Picked color is
            {color}
          </span>
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default mt-3" onClick={handleSubmit}>Save</button>
      </div>
    </form>
  ); 
};

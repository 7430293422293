import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import EditorComponent from '../../../EmailTemplates/EditorComponent/EditorComponent';
import PreviewComponent from './PreviewComponent/PreviewComponent';
import { selectSmtpSelectedUserPreset } from '../../../../redux/smtpPresets/smtpPresetsSelectors';
import { selectEmailTemplates, selectEmailTemplate } from '../../../../redux/emailTemplates/emailTemplatesSelectors';
import { getEmailTemplate, getEmailTemplates, setEmailTemplateCreator } from '../../../../redux/emailTemplates/emailTemplatesActions';
import { sendEmail } from '../../../../redux/crmUser/crmUserActions';
import s from './SendSmtpEmailModal.module.css';

const COOLDOWN_TIME_MS = 15000;
const DEFAULT_SUBJECT = 'Stage Support Team';
const CUSTOM_BUTTON_TITLES = [
  'firstName',
  'lastName',
  'fullName',
  'email', 
  'phone',
  'country',
  'companyName',
  'siteUrl',
];

function SendSmtpEmailModal({ userId, brandId, handleClose }) {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState(DEFAULT_SUBJECT);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [emailTemplateId, setEmailTemplateId] = useState('');
  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState('');

  const emailTemplates = useSelector(selectEmailTemplates);
  const emailTemplate = useSelector(selectEmailTemplate);
  const selectedUserPreset = useSelector(selectSmtpSelectedUserPreset);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);
  const isEmailError = useSelector((state) => state.crmUser.isEmailError);

  const filteredEmailTemplates = emailTemplates.filter((item) => item.brand._id === brandId && item.event === 'manualSend' && item.status);
  const sanitizedHTML = DOMPurify.sanitize(mailHtmlBodyStringified);
  const HTMLWithEncodedBtn = sanitizedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const HTMLWithProperClass = HTMLWithEncodedBtn.replace(/class="button"/g, `class="${s.button}"`);

  const handlePreview = () => setShowEmailPreview((isShow) => !isShow);
  const handleSubjectChange = (event) => setSubject(event.target.value);
  const handleSubmitEmail = () => {
    const crmUserId = JSON.parse(localStorage.getItem('userId'));
    const emailBody = mailHtmlBodyStringified;
    setIsCooldown(true);

    dispatch(sendEmail({
      crmUserId,
      userId,
      subject,
      emailBody,
      selectedUserPreset,
    }, `${crmUser.firstName} ${crmUser.lastName}`));
  };
  const handleChangeSelectedId = (event) => setEmailTemplateId(event.target.value);


  const emailEditor = useMemo(() => {
    if (emailTemplate && Object.entries(emailTemplate).length > 0 && emailTemplateId === emailTemplate._id) {
      return (
        <EditorComponent
          key={emailTemplateId}
          template={emailTemplate}
          setMailHtmlBodyS={setMailHtmlBodyS}
          customButtonTitles={CUSTOM_BUTTON_TITLES}
        />
      );
    }
  }, [emailTemplate, emailTemplateId]);


  useEffect(() => {
    if (!emailTemplates.length) {
      dispatch(getEmailTemplates(1, 10000, { createdAt: -1 }, { status: 'active' }));
    }
  }, [emailTemplates]);

  useEffect(() => {
    if (filteredEmailTemplates && filteredEmailTemplates.length) {
      setEmailTemplateId(filteredEmailTemplates[0]._id);
    }
  }, [emailTemplates]);

  useEffect(() => {
    if (emailTemplateId.length) {
      dispatch(getEmailTemplate(emailTemplateId));
    }
    return () => {
      dispatch(setEmailTemplateCreator({}));
    };
  }, [emailTemplateId, dispatch]);

  useEffect(() => {
    let timer;
    if (isCooldown) {
      if (isEmailError) {
        setIsCooldown(false);
      } else {
        timer = setTimeout(() => {
          setIsCooldown(false);
        }, COOLDOWN_TIME_MS);
      }
    }
  
    return () => clearTimeout(timer);
  }, [isCooldown, isEmailError]);


  return (
    <>
      <div className={s.wrapper}>
        <div className="form-group me-2">
          <label className="control-label mb-2">Subject</label>
          <div className="input-wrapper">
            <input type="text" className="form-control" name="addAmountInfo" value={subject} onChange={handleSubjectChange} />
          </div>
        </div>
        <div className="form-group me-2">
          <label className="control-label mb-2">Template</label>
          <div className="input-wrapper">
            <select className="form-control" name="template" onChange={handleChangeSelectedId} value={emailTemplateId}>
              {filteredEmailTemplates.map(({ _id, title }) => (
                <option 
                  key={_id}
                  value={_id}
                >
                  {title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group me-2">
          <label className="control-label mb-2">Email Body</label>
          <div className="input-wrapper">
            {emailEditor}
          </div>
        </div>
        {showEmailPreview && (
          <div className="form-group me-2">
            <label className="control-label mb-2">Preview</label>
            <div className="input-wrapper">
              <PreviewComponent HTMLWithProperClass={HTMLWithProperClass} />
            </div>
          </div>
        )}
      </div>
      <div className={s.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" onClick={handlePreview}>
          Preview
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitEmail} disabled={isCooldown}>
          Send Email
        </Button>
      </div>
    </>
  );
}

export default SendSmtpEmailModal;

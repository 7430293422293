import React, {
  useContext, useEffect, useState, useRef, 
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { toast } from 'react-toastify';
import { getSalesStatusesTableData, exportFilteredDataToFile } from '../../redux/salesStatus/salesStatusActions';
import { useDebounce } from '../../hooks/useDebounce';
import { AddSalesStatusModalBody } from './components';
import { ModalContext } from '../../context';
import { StatusSchema } from './components/StatusSchema';
import { generateSortParams } from '../../helpers/sortHelper';
import { DatatableColumns, DatatableFilters, DownloadFile } from '../../components';
import { statusColumns } from '../../columnsDefaultConfig';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';

function SalesStatus() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const [columnConfig, setColumnConfig] = useState(statusColumns);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [filters, setFilters] = useState({});
  const [statusNameFilter, setStatusNameFilter] = useState(null);
  const [statusTypeFilter, setStatusTypeFilter] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const { statuses, totalCount } = useSelector((state) => state?.salesStatus);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('add_sales_status');

  const paginationStorageName = 'DataTable_statuses/pagination';
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const columnsStorageName = 'DataTable_statuses/columns';
  const columnsStorageJSON = localStorage.getItem(columnsStorageName);
  const filtersStorageName = 'DataTable_statuses/filters';
  const filtersStorageJSON = localStorage.getItem(filtersStorageName);
  const filterIdStorageName = 'DataTable_statuses/filters_id'; 

  const datatableFiltersRef = useRef(null);

  const debounceCallback = ({ value, key }) => {
    if (value !== null && (value.length >= 3 || value.length === 0) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceArrayCallback = ({ value, key }) => {
    if (value !== null && (value.length >= 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(statusNameFilter, 1000, (value) => debounceCallback({ value, key: 'name' }));
  useDebounce(statusTypeFilter, 200, (value) => debounceArrayCallback({ value, key: 'type' }));


  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 15);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersStorageJSON) ?? {};
    setFilters(filters);

    setStatusNameFilter(filters.name || '');
    setStatusTypeFilter(filters.type || []);
  };

  const setStoredColumnsData = () => {
    if (columnsStorageJSON) {
      const columns = JSON.parse(columnsStorageJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
  }, []);

  useEffect(async () => {
    if (!isPaginationDT) return;
    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    setTableLoading(true);

    await dispatch(getSalesStatusesTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));

    setTableLoading(false);
  }, [page, rowsPerPage, isPaginationDT, filters, sortParams]);

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getSalesStatusesTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));
    setTableLoading(false);
  };

  const handleClear = () => {
    setStatusNameFilter('');
    setStatusTypeFilter([]);
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const {
      fullName,
      type,
    } = filter;

    setStatusNameFilter(fullName || null);
    setStatusTypeFilter(type || null);
    setFilters({
      name: fullName,
      type,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      type: filters.type || [],
      fullName: filters.name || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        type: filters.type || [],
        fullName: filters.name || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleExportStatuses = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (statuses.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(filters, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const columns = StatusSchema({
    columnConfig,
    filters,
    setFilters,
    statusNameFilter,
    setStatusNameFilter,
    statusTypeFilter, 
    setStatusTypeFilter,
    handleSort,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Statuses</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddSalesStatuses
                      && (
                      <button 
                        type="button" 
                        className="btn-primary_light"
                        onClick={() => showModal({ 
                          bodyContent: <AddSalesStatusModalBody />, 
                          headerContent: <h3>Add Status</h3>, 
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                        Add New
                      </button>
                      )}
            </div>
            <div className="secondary_btn-row">
              {crmFilters && (
                <DatatableFilters
                  ref={datatableFiltersRef}
                  filters={crmFilters}
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                />
              )}
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExportStatuses} />
            </div>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT && (
              <DataTable
                columns={columns}
                data={statuses}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationDefaultPage={page}
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[15, 30, 50]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                persistTableHead
                highlightOnHover
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            )
          }

        </div>
      </div>
    </div>
  );
}

export default SalesStatus;

import {
  GET_CHATS,
  GET_CHAT,
  ADD_CHAT,
  SEND_MESSAGE,
  GET_MESSAGE,
  DELETE_CHAT,
  DELETE_CHAT_MESSAGE,
  ERROR_CHAT,
  READ_MESSAGES,
} from './chatTypes';

const initialState = {
  chats: {},
  activeChat: {},
};
  
// eslint-disable-next-line default-param-last
const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_MESSAGES:
      return {
        ...state,
        activeChat: state.activeChat._id === action.payload.chatId
          ? { 
            ...state.activeChat,
            messages: state.activeChat.messages.map((message) => {
              if (message.sender !== action.payload.sender) {
                return { ...message, isRead: true };
              }

              return { ...message };
            }), 
          } : state.activeChat,
        chats: {
          ...state.chats,
          [action.payload.sender]: state.chats[action.payload.sender].map((chat) => {
            if (chat._id === action.payload.chatId) {
              return { 
                ...chat,
                messages: chat.messages.map((message) => {
                  if (message.sender !== action.payload.sender) {
                    return { ...message, isRead: true };
                  }
    
                  return { ...message };
                }), 
              };
            }

            return chat;
          }),
        },
      };
    case GET_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    case GET_CHAT:
      return {
        ...state,
        activeChat: action.payload,
      };
    case ADD_CHAT:
      return {
        ...state,
        chats: [action.payload, ...state.chats],
      };
    case GET_MESSAGE:
      return {
        ...state,
        activeChat: state.activeChat._id === action.payload.message.chatId 
          ? { ...state.activeChat, messages: [...state.activeChat.messages, action.payload.message] }
          : state.activeChat,
        chats: {
          ...state.chats,
          [action.payload.receiver]: state.chats[action.payload.receiver].map((chat) => {
            if (chat._id === action.payload.message.chatId) {
              return { ...chat, messages: [...chat.messages, action.payload.message] };
            }

            return chat;
          }),
        },
      };
    case SEND_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: [...state.activeChat.messages, action.payload],
        },
        chats: {
          ...state.chats,
          [action.payload.sender]: state.chats[action.payload.sender].map((chat) => {
            if (chat._id === action.payload.chatId) {
              return { ...chat, messages: [...chat.messages, action.payload] };
            }

            return chat;
          }),
        },
      };
    case DELETE_CHAT:
      const newChats = { ...state.chats };

      const filteredChats = state.chats[action.payload.crmUser].filter(
        (chat) => chat._id !== action.payload._id,
      );
      
      if (filteredChats.length === 0) {
        delete newChats[action.payload.crmUser];
      } else {
        newChats[action.payload.crmUser] = filteredChats;
      }
      return {
        ...state,
        chats: newChats,
      };
    case DELETE_CHAT_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: state.activeChat.messages.filter((message) => message._id !== action.payload),
        },
      };
    case ERROR_CHAT:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
  
export default chatReducer;  

import {
  SET_DEPOSIT_METHODS,
  SET_IS_DEPOSIT_METHODS_LOADING,
  UPDATE_DEPOSIT_METHOD,
  SET_BRAND_SEARCH_FILTER,
  SET_DEPOSIT_METHOD_SEARCH_FILTER,
  SET_COUNTRIES_INCLUDED_SEARCH_FILTER,
  SET_TOTAL_COUNT_DEPOSIT_METHODS,
  SET_DEPOSIT_METHODS_OPTIONS,
  SET_COUNTRIES_EXCLUDE_SEARCH_FILTER,
} from './depositMethodsTypes';
  
const initialState = {
  depositMethods: [],
  isDepositMethodsLoading: false,
  brandSearchFilter: [],
  depositMethodSearchFilter: [],
  countriesIncludedSearchFilter: [],
  countriesExcludedSearchFilter: [],
  totalCount: null,
  depositMethodsOptions: [],
};
  
const depositMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPOSIT_METHODS_OPTIONS:
      return {
        ...state,
        depositMethodsOptions: action.payload,
      };
    case SET_TOTAL_COUNT_DEPOSIT_METHODS:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_BRAND_SEARCH_FILTER:
      return {
        ...state,
        brandSearchFilter: action.payload,
      };
    case SET_DEPOSIT_METHOD_SEARCH_FILTER:
      return {
        ...state,
        depositMethodSearchFilter: action.payload,
      };
    case SET_COUNTRIES_INCLUDED_SEARCH_FILTER:
      return {
        ...state,
        countriesIncludedSearchFilter: action.payload,
      };
    case SET_COUNTRIES_EXCLUDE_SEARCH_FILTER:
      return {
        ...state,
        countriesExcludedSearchFilter: action.payload,
      };
    case SET_DEPOSIT_METHODS:
      return {
        ...state,
        depositMethods: action.payload,
      };
    case SET_IS_DEPOSIT_METHODS_LOADING:
      return {
        ...state,
        isDepositMethodsLoading: action.payload,
      };
    case UPDATE_DEPOSIT_METHOD:
      const updatedMethods = state.depositMethods.map((method) => (method._id === action.payload._id ? { ...method, ...action.payload } : method));
      return {
        ...state,
        depositMethods: updatedMethods,
      };
    default:
      return state;
  }
};
  
export default depositMethodsReducer;
  

export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_AFFILIATE = 'GET_AFFILIATE';
export const ADD_AFFILIATE = 'ADD_AFFILIATE';
export const EDIT_AFFILIATE = 'EDIT_AFFILIATE';
export const DELETE_AFFILIATE = 'DELETE_AFFILIATE';
export const GET_AFFILIATED_DEPOSITS = 'GET_AFFILIATED_DEPOSITS';
export const UPDATE_AFFILIATE_TOKEN = 'UPDATE_AFFILIATE_TOKEN';
export const TOGGLE_AFFILIATE_STATE = 'TOGGLE_AFFILIATE_STATE';
export const ERROR_AFFILIATE_STATE = 'ERROR_AFFILIATE_STATE';
export const GET_AFFILIATES_TABLE_DATA = 'GET_AFFILIATES_TABLE_DATA';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MonthCalendar, 
  CalendarSubHeader, 
  WeekCalendar, 
  DayCalendar,
  CalendarFilter, 
} from './components';
import { EditEventModal } from '../../components';
import styles from './style.module.css';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';

function Calendar() {
  const dispatch = useDispatch();
  const [focusedEvent, setFocusedEvent] = useState('');
  const [calendarStyle, setCalendarStyle] = useState('month');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sortQuery, setSortQuery] = useState({
    sortBy: 'startAt',
    manager: '',
  });
  const [agentUsers, setAgentUsers] = useState([]);
  const [showEditEventModal, setShowEditEventModal] = useState(false);

  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const events = useSelector((state) => state.events);

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    dispatch(getSubCRMUsers(id, uid));
  }, []);


  useEffect(() => {
    if (crmUsers?.length > 0 && events?.length > 0) {
      if (crmUsers && events) {
        const allAgents = crmUsers.map((agent) => ({ label: `${agent.firstName} ${agent.lastName}`, value: agent._id }));
        const eventsCrmUserIds = events.map((event) => event.crmUserId);
        const usersInEvents = allAgents.filter((user) => eventsCrmUserIds.includes(user.value));

        setAgentUsers(usersInEvents);
      }
    }
  }, [crmUsers, events]);

  return (
    <div className="content-wrapper right-content-wrapper text-white" style={{ height: '100%' }}>
      <CalendarFilter sortQuery={sortQuery} setSortQuery={setSortQuery} agentUsers={agentUsers} />

      <CalendarSubHeader 
        selectedDate={selectedDate} 
        setSelectedDate={setSelectedDate} 
        calendarStyle={calendarStyle}
        setCalendarStyle={setCalendarStyle}
      />

      <div className={styles.calendarWarpper}>
        {calendarStyle === 'month' && (
          <MonthCalendar 
            selectedDate={selectedDate}
            setShowEditEventModal={setShowEditEventModal}
            setFocusedEvent={setFocusedEvent}
            focusedEvent={focusedEvent}
            sortQuery={sortQuery}
          />
        )}
                
        {calendarStyle === 'week' && (
          <WeekCalendar 
            selectedDate={selectedDate}
            setShowEditEventModal={setShowEditEventModal}
            setFocusedEvent={setFocusedEvent}
            focusedEvent={focusedEvent}
            sortQuery={sortQuery}
          />
        )}

        {calendarStyle === 'day' && (
          <DayCalendar 
            selectedDate={selectedDate}
            setShowEditEventModal={setShowEditEventModal}
            setFocusedEvent={setFocusedEvent}
            focusedEvent={focusedEvent}
            setSelectedDate={setSelectedDate}
            sortQuery={sortQuery}
          />
        )}
      </div>

      {showEditEventModal && (
        <EditEventModal 
          showModal={showEditEventModal} 
          setShowModal={setShowEditEventModal}
          focusedEvent={focusedEvent}
        />
      )}
    </div>
  );
}

export default Calendar;

export const DISPLAY_TRANSACTIONS = 'DISPLAY_TRANSACTIONS';
export const DISPLAY_ALL_ADMIN_DEPOSITS = 'DISPLAY_ALL_ADMIN_DEPOSITS';
export const DISPLAY_ALL_ADMIN_WITHDRAWS = 'DISPLAY_ALL_ADMIN_WITHDRAWS';
export const GET_ASSET_DETAILS = 'GET_ASSET_DETAILS';
export const GET_DEPOSIT_ORDER_DETAILS = 'GET_DEPOSIT_ORDER_DETAILS';
export const TOGGLE_TRANSACTION_STATE = 'TOGGLE_TRANSACTION_STATE';
export const TOGGLE_DEPOSIT_ORDER_DETAILS_STATE = 'TOGGLE_DEPOSIT_ORDER_DETAILS_STATE';
export const ALL_PENDING_DEPOSITS = 'ALL_PENDING_DEPOSITS';
export const ALL_PENDING_WITHDRAWALS = 'ALL_PENDING_WITHDRAWALS';
export const ALL_WITHDRAWALS = 'ALL_WITHDRAWALS';
export const ALL_DEPOSITS = 'ALL_DEPOSITS';
export const ALL_USER_WITHDRAWALS = 'ALL_USER_WITHDRAWALS';
export const UPDATE_ISREAL_TRANSACTION = 'UPDATE_ISREAL_TRANSACTION';
export const DELETE_TRANSACTION_DEP = 'DELETE_TRANSACTION_DEP';
export const EXPORT_FILTERED_DATA_TO_FILE = 'EXPORT_FILTERED_DATA_TO_FILE';
export const GET_TRANSACTIONS_BY_QUERY = 'GET_TRANSACTIONS_BY_QUERY';
export const GET_WITHDRAWS_BY_QUERY = 'GET_WITHDRAWS_BY_QUERY';
export const UPDATE_TRANSACTION_FIELDS = 'UPDATE_TRANSACTION_FIELDS';
export const MASS_DELETE_TRANSACTIONS = 'MASS_DELETE_TRANSACTIONS';

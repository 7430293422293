import {
  GET_CFD_SETTINGS, 
  UPDATE_CFD_SETTINGS, 
  SEARCH_CFD_SETTINGS, 
  GET_CFD_SETTINGS_BY_TYPE, 
  MASS_UPDATE_CFD_SETTINGS,
} from './cfdTypes';

const initialState = {
  cfdSettings: [],
  totalCount: 0,
};

const cfdSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CFD_SETTINGS:
      return {
        ...state,
        cfdSettings: action.payload,
      };
    case UPDATE_CFD_SETTINGS:
      return {
        ...state,
        cfdSettings: state.cfdSettings.map((item) => {
          if (item._id === action.payload._id) {
            return { ...action.payload };
          } 
          return item;
        }),
      }; 
    case SEARCH_CFD_SETTINGS:
      return {
        ...state,
        totalCount: action.payload.totalCount,
        cfdSettings: action.payload.cfdSettings,
      };
    case GET_CFD_SETTINGS_BY_TYPE:
      return {
        ...state,
        cfdSettings: action.payload,
      };
    case MASS_UPDATE_CFD_SETTINGS:
      return {
        ...state,
        cfdSettings: state.cfdSettings.map((setting) => {
          if (action.payload.settingIds.includes(setting._id)) {
            return {
              ...setting, 
              leverage: action.payload.leverage || 0,
              lotStep: action.payload.lotStep || 0,
              lotSize: action.payload.lotSize || 0,
              commission: action.payload.commission || 0,
              swapLong: action.payload.swapLong || 0,
              swapShort: action.payload.swapShort || 0,
              maxVolume: action.payload.maxVolume || 0,
              minVolume: action.payload.minVolume || 0,
            }; 
          }
  
          return setting;
        }),
      }; 
    default:
      return state;
  }
};

export default cfdSettingsReducer;  

import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { 
  GET_USER_WITHDRAWAL_TYPE_BY_ID, 
  CREATE_USER_WITHDRAWAL_TYPE, 
  UPDATE_USER_WITHDRAWAL_TYPE, 
  DELETE_USER_WITHDRAWAL_TYPE, 
} from './userWithdrawalTypesTypes';

export const getUserWithdrawalTypesById = ({
  id, page, limit, sortParams,
}) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-withdrawal-types/${id}?limit=${limit}&page=${page}&sort=${sortParams}`);

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_USER_WITHDRAWAL_TYPE_BY_ID,
        payload: res.data.userWithdrawalTypes,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const createUserWithdrawalTypes = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/user-withdrawal-types/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: CREATE_USER_WITHDRAWAL_TYPE,
        payload: res.data.userWithdrawalType,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateUserWithdrawalTypes = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/user-withdrawal-types/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_USER_WITHDRAWAL_TYPE,
        payload: res.data.userWithdrawalType,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteUserWithdrawalTypes = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/user-withdrawal-types/${id}`);

    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_USER_WITHDRAWAL_TYPE,
        payload: res.data.userWithdrawalType,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

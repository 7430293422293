import React from 'react';

function SortIcon({ image }) {
  return (
    <img
      style={{ height: '20px' }}
      src={image}
      alt="sort"
    />
  );
}

export default SortIcon;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { colourStyles } from '../../helpers/styles';
import { editUser } from '../../redux/users/userActions';
import { getCRMUserOptions } from '../../redux/crmUser/crmUserActions';

export function AssignToManagerModal({
  hideModal, 
  selectedUserType, 
  user, 
  defaultManagerOption,
  isBulkAssign = false,
  ids = [],
  massEdit,
}) {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.crmUser);

  const [assignedToManager, setAssignedToManager] = useState(defaultManagerOption);

  useEffect(() => {
    dispatch(getCRMUserOptions());
  }, []);

  const handleUserTypeChange = () => {
    const clientType = Number(selectedUserType) === 1 ? 2 : 1;

    const data = {
      clientType,
      assignedTo: assignedToManager?.value ?? null,
    };

    if (isBulkAssign) data.ids = ids;

    hideModal();

    const method = isBulkAssign 
      ? massEdit(data, `Are you sure you want to move user(s) to ${Number(selectedUserType) === 1 ? 'client' : 'lead'} category?`) 
      : dispatch(editUser(user._id, data));

    method();
  };

  return (
    <>
      <div className="mb-3">
        <label htmlFor="managerSelect" className="mb-2">Manager</label>
        <Select
          styles={colourStyles}
          options={[
            { value: null, label: 'Unassigned' },
            ...options,
          ]}
          isSearchable
          defaultValue={assignedToManager}
          onChange={(option) => setAssignedToManager(option)}
        />
      </div>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
        <Button variant="primary" className="ms-2" onClick={() => handleUserTypeChange()}>Save</Button>
      </div>
    </>
  );
}

/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useContext, useState, useMemo, 
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash, faPlus, faPencil, faExternalLink, 
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import CreateAccountModal from './CreateAccountModal';
import CreateSavingAccountModal from './CreateSavingAccountModal';
import EditAccountModal from './EditAccountModal';
import { deleteSavingAccount, deleteUserAccount, getCfdAccountsByQuery } from '../../../../redux/cfdAccount/cfdAccountActions';
import { ModalContext } from '../../../../context';
import { toFixed } from '../../../../helpers/utils';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from '../../../../DataTableSchemas/helper';
import { TooltipComponent } from '../../../../components/TooltipComponent/TooltipComponent';
import socket from '../../../../services/socket';
import { generateSortParams, SortWrapper, useTableSorting } from '../../../../helpers/sortHelper';
import SortIcon from '../../../../components/SortIcon';

function Accounts({ userId }) {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [tableLoading, setTableLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const { cfdAccounts = [], totalCount } = useSelector((state) => state.cfdAccounts?.allCfdAccounts);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanAddCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_add');
  const isUserCanEditCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_edit');
  const isUserCanDeleteCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_delete');

  const orders = useMemo(() => {
    const allOrders = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const account of cfdAccounts) {
      if (account.orders && account.orders.length) allOrders.push(...account.orders);
    }

    return allOrders;
  }, [cfdAccounts]);

  useEffect(() => {
    setIsPaginationDT(true);
  }, []);

  useEffect(async () => {
    async function fetchData() {
      if (isPaginationDT) {
        setTableLoading(true);

        await dispatch(getCfdAccountsByQuery({
          page,
          limit: rowsPerPage,
          sort: sortParams,
          query: {
            userId,
            isSaving: JSON.stringify([false, true]),
          }, 
        }));

        setTableLoading(false);
      }
    }
    fetchData();
  }, [page, rowsPerPage, sortParams, userId, isPaginationDT]);

  useEffect(() => {
    const uniqueId = Date.now();

    if (orders.length) {
      socket.emit('getOpenOrderMarket', { orders, uniqueId });
    }

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setOrderData(data);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      socket.emit('removeOpenOrdersMarketListener');
      setOrderData({});
    };
  }, [orders]);

  const deleteAccount = (id, isSaving = false) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          if (isSaving) {
            dispatch(deleteSavingAccount(id));
          } else {
            dispatch(deleteUserAccount(id));
          }
        }
      });
  };

  const calcSumByKey = ({ orders = [], key }) => {
    if (!orders.length) return 0;
    let margin = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        margin += Number(orderData[order._id][key]);
      }
    }

    return margin;
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('customId')}>
          <div className="d-flex flex-row">
            <span>ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('customId')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      selector: ({ customId }) => (
        <span>{customId}</span>
      ), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('type.name')}>
          <div className="d-flex flex-row">
            <span>Type</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('type.name')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      selector: ({ type, isSaving }) => (!isSaving ? type.name : '-'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isSaving')}>
          <div className="d-flex flex-row">
            <span>Product</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isSaving')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '90px',
      selector: ({ isSaving }) => (isSaving ? 'Saving' : 'CFD'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('currencySymbol')}>
          <div className="d-flex flex-row">
            <span>Currency</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('currencySymbol')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '118px',
      selector: ({ currencySymbol }) => currencySymbol || '-', 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('balance')}>
          <div className="d-flex flex-row">
            <span>Balance</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('balance')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '110px',
      // using .toFixed(8) here to convert scientific notation like 3e-8 to 0.00000003
      selector: ({ balance }) => toFixed(balance.toFixed(8), 2), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('credit')}>
          <div className="d-flex flex-row">
            <span>Credit</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('credit')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '90px',
      selector: ({ credit }) => (credit ? toFixed(credit, 2) : '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('leverage')}>
          <div className="d-flex flex-row">
            <span>Leverage</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('leverage')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '120px',
      selector: ({ leverage }) => (leverage || '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotStep')}>
          <div className="d-flex flex-row">
            <span>Lot Step</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotStep')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '120px',
      selector: ({ lotStep }) => (lotStep || '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotSize')}>
          <div className="d-flex flex-row">
            <span>Lot Size</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotSize')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '100px',
      selector: ({ lotSize }) => (lotSize || '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('commission')}>
          <div className="d-flex flex-row">
            <span>Commission%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('commission')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '140px',
      selector: ({ commission }) => (commission || '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapLong')}>
          <div className="d-flex flex-row">
            <span>Swap Long%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapLong')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '136px',
      selector: ({ swapLong }) => (swapLong || '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapShort')}>
          <div className="d-flex flex-row">
            <span>Swap Short%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapShort')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '146px',
      selector: ({ swapShort }) => (swapShort || '-'), 
    },
    {
      name: 'Margin',
      minWidth: '80px',
      selector: ({ orders }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });

        return (<span style={margin !== 0 ? { color: margin > 0 ? 'green' : 'red' } : {}}>{margin.toFixed(2)}</span>);
      }, 
    },
    {
      name: 'Margin Level',
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });
        const pnl = calcSumByKey({ orders, key: 'pnl' });
        const equity = Number(balance) + pnl + Number(credit);
        const marginLevel = margin !== 0 ? (equity / margin) * 100 : 0;

        return (<span style={marginLevel !== 0 ? { color: marginLevel > 0 ? 'green' : 'red' } : {}}>{`${toFixed(marginLevel, 2)} %`}</span>);
      }, 
    },
    {
      name: 'Free Margin',
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });
        const pnl = calcSumByKey({ orders, key: 'pnl' });
        const equity = Number(balance) + pnl + Number(credit);
        const freeMargin = equity - margin;

        return (<span style={freeMargin !== 0 ? { color: freeMargin > 0 ? 'green' : 'red' } : {}}>{toFixed(freeMargin, 2)}</span>);
      }, 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isActive')}>
          <div className="d-flex flex-row">
            <span>Is Active</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isActive')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '90px',
      selector: ({ isActive, isSaving }) => (!isSaving ? (isActive ? 'Yes' : 'No') : '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isEnabled')}>
          <div className="d-flex flex-row">
            <span>Enabled account</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isEnabled')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '160px',
      selector: ({ isEnabled }) => (isEnabled !== undefined ? (isEnabled ? 'Yes' : 'No') : '-'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('user.manager.firstName user.manager.lastName')}>
          <div className="d-flex flex-row">
            <span>Assigned To</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('user.manager.firstName user.manager.lastName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '120px',
      selector: ({ user }) => {
        if (user && user.manager) {
          const userFullName = `${user.manager.firstName} ${user.manager.lastName}`; 
          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { userFullName.length >= valueOutputCutLength.accountsAssignTo
                ? (
                  <TooltipComponent
                    title={userFullName}
                      // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
                        <FontAwesomeIcon icon={faExternalLink} size="xs" />
                        <span 
                          className="cfdSccounts-row_fullName"
                        >
                          {prettyCutOutputByCellWidth(userFullName, 'accountsAssignTo')}
                        </span>
                      </Link>
                )}
                  />
                )
                : (
                  <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
                    <FontAwesomeIcon icon={faExternalLink} size="xs" />
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      {userFullName}
                    </span>
                  </Link>
                )}
            </div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('user.status.name')}>
          <div className="d-flex flex-row">
            <span>Status</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('user.status.name')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '120px',
      cell: ({ user }) => (user && user.status ? user.status.name : '-'),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: (row) => (
        <div style={{ display: 'flex' }}>
          {
            !row.isSaving && isUserCanEditCFDAccount && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  headerContent: <h3>{`Edit Account #${row.customId}`}</h3>,
                  bodyContent: <EditAccountModal afterUpdate row={row} />,
                })}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon" />
              </button>
            )
          }
          {isUserCanDeleteCFDAccount && (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAccount(row._id, row.isSaving)}
            >
              <FontAwesomeIcon icon={faTrash} className="header-icon" />
            </button>
          )}
        </div>

      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {isUserCanAddCFDAccount && (
            <button
              type="button"
              className="btn-primary_light"
              onClick={() => showModal({
                headerContent: <h3>ADD ACCOUNT</h3>,
                bodyContent: <CreateAccountModal closeModal={hideModal} userId={userId} />,
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add Account
            </button>
            )}
            <button
              type="button"
              className="btn-primary_light"
              onClick={() => showModal({
                headerContent: <h3>ADD SAVING ACCOUNT</h3>,
                bodyContent: <CreateSavingAccountModal closeModal={hideModal} userId={userId} />,
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add Saving Account
            </button>
          </div>
        </div>
        
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        { 
          isPaginationDT && (
            <DataTable
              columns={columns}
              data={cfdAccounts}
              theme="solarizedd"
              fixedHeader
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCount}
              paginationRowsPerPageOptions={[15, 25, 50]}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage) => setRowsPerPage(currentRowsPerPage)}
              paginationDefaultPage={page}
              persistTableHead
              highlightOnHover
              progressPending={tableLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )
        }
      </div>
    </div>
  );
}

export default Accounts;

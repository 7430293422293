export const currenciesRulesColumns = [
  {
    name: 'Currency',
    field: 'symbol',
    selected: true,
  },
  {
    name: 'Included Countries',
    field: 'countries',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
    
    
  

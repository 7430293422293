import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faPencil } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';
import { ModalContext } from '../../context';
import {
  omitColumn, formatDate, dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength, 
} from '../helper';
import { ModalFilters } from '../../components';
import { stopOrderById } from '../../redux/cfdOrder/cfdOrderActions';
import { LoadingDots } from '../../components/LoadingDots/LoadingDots';
import { toFixed, getTradeTypeNameByIndex } from '../../helpers/utils';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';
import styles from './styles.module.css';

export const OpenOrdersSchema = ({
  onlineUsers,
  columnConfig,
  isCheckOnlineStatuses,
  setIsCheckOnlineStatuses,
  userNameFilter,
  setUserNameFilter,
  filters,
  setFilters,
  tradingTypeFilter,
  setTradingTypeFilter,
  crmUserFilter,
  setCrmUserFilter, 
  coinPairsFilter, 
  setCoinPairsFilter,
  EditOpenOrderModal,
  timeOpenedFilter, 
  setTimeOpenedFilter,
  orderIdToPnl,
  userId = '',
  openOrderId,
  setOpenOrderId,
  inputFieldError,
  setInputFieldError,
  handleRefresh,
  handleSort,
}) => {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const onlineUserIds = Object.values(onlineUsers);

  const onlineFilterPresets = [
    { _id: true, firstName: 'Online', lastName: '' },
    { _id: false, firstName: 'Offline', lastName: '' },
  ];

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const { cfdSettings } = useSelector((state) => state.cfdSettings);

  const coinsPairs = useMemo(() => {
    const pairs = [];

    if (!cfdSettings || !cfdSettings.length) return pairs;

    cfdSettings.forEach(({ symbol }) => {
      pairs.push({
        name: symbol,
        _id: symbol,
      });
    });

    return pairs;
  }, [cfdSettings]);

  const permissionName = useSelector((state) => state.crmUser.currentUserPermissions);
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);

  const isUserCanSeeClientCard = permissionName && permissionName.length > 0 && permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName && permissionName.length > 0 && permissionName.includes('leads');
  const isUserCanEditCrmUser = permissionName && permissionName.length > 0 && permissionName.includes('edit_crm_user');
  const isUserCanEditOpenOrders = permissionName && permissionName.length > 0 && permissionName.includes('open_orders_edit');
  const isUserCanStopOpenOrders = permissionName && permissionName.length > 0 && permissionName.includes('open_orders_edit');

  const stopAction = (id, stopRate) => {
    Swal.fire({
      title: `Are you sure want to stop it at ${Number(stopRate).toFixed(4) ?? 0} rate?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(stopOrderById(id, stopRate));
      }
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'userIdIsNotValid') setOpenOrderId(value);
    if (nameField === 'fullNameIsNotValid') setUserNameFilter(value);
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const activeOrdersColumns = [
    {
      name: (
        <div className={`d-flex flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <div style={{ display: 'flex', width: '100%' }}>
            <SortWrapper handleSort={() => sortFields('customId')}>
              <div className="d-flex flex-row">
                <span className={styles.titleContainer}>ID</span>
                <button type="button" className="sort-btn">
                  <SortIcon image={getSortIcon('customId')} />
                </button>
              </div>
            </SortWrapper>
          </div>
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="ID"
            value={openOrderId || ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span>
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
            )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
      omit: omitColumn(columnConfig, 'ID'),
      minWidth: '150px',
    },
    {
      name: (
        <div className={`d-flex flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <div style={{ display: 'flex', width: '100%' }}>
            <SortWrapper handleSort={() => sortFields('user.firstName user.lastName')}>
              <div className="d-flex flex-row">
                <span className={styles.titleContainer}>
                  Client
                </span>
                <button type="button" className="sort-btn">
                  <SortIcon image={getSortIcon('user.firstName user.lastName')} />
                </button>
              </div>
            </SortWrapper>
          </div>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            name="userName"
            autoComplete="off"
            value={userNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.fullNameIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      cell: (row) => {
        const clientFullName = `${row?.user?.firstName} ${row?.user?.lastName}`;

        if (clientFullName.length >= valueOutputCutLength.clientWithdrawal) {
          return (
            <TooltipComponent
              title={clientFullName}
              // eslint-disable-next-line react/no-children-prop
              children={(
                (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard)
                  ? (
                    <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
                      {prettyCutOutputByCellWidth(clientFullName, 'clientWithdrawal')}
                    </Link>
                  ) 
                  : <span>{prettyCutOutputByCellWidth(clientFullName, 'clientWithdrawal')}</span>
            )}
            />
          );
        }
        return (
          (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard)
            ? (
              <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
                {clientFullName}
              </Link>
            ) 
            : `${clientFullName}`
        );
      },
      omit: !!userId.length || omitColumn(columnConfig, 'Client'),
      minWidth: '160px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('user.isOnline')}>
            <div className="d-flex flex-row">
              <span>Online</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.isOnline')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Online" 
            data={onlineFilterPresets} 
            filters={isCheckOnlineStatuses} 
            setFilters={setIsCheckOnlineStatuses} 
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '120px',
      omit: omitColumn(columnConfig, 'Online'),
      sortField: 'online',
      selector: ({ user }) => (
        onlineUserIds.includes(user._id)
          ? (
            <span className="online">
              Online
            </span>
          )
          : (
            <span className="offline">
              Offline
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('tradeType')}>
            <div className="d-flex flex-row">
              <span>Trading Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('tradeType')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Trading Type" 
            data={[
              { name: 'Buy', _id: '1' }, 
              { name: 'Sell', _id: '0' },
              { name: 'Buy Limit', _id: '2' },
              { name: 'Buy Stop', _id: '3' },
              { name: 'Sell Limit', _id: '4' },
              { name: 'Sell Stop', _id: '5' },
            ]}
            filters={tradingTypeFilter}
            setFilters={setTradingTypeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '145px',
      cell: (row) => {
        const tradeTypeName = getTradeTypeNameByIndex(row.tradeType);
        const isBuy = tradeTypeName.includes('Buy');
        return (
          <span style={{ color: isBuy ? '#29bb64' : '#df4747' }}>{tradeTypeName}</span> 
        );
      },
      omit: omitColumn(columnConfig, 'Type'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('user.assignedTo.firstName user.assignedTo.lastName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.assignedTo.firstName user.assignedTo.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Assigned To" 
            data={crmUsers}
            filters={crmUserFilter} 
            setFilters={setCrmUserFilter} 
            usersFilters={filters}
            setUserFilters={setFilters}
            searchParams={{ 
              id: '_id',
              params: ['firstName', 'lastName'], 
              optionsName: ['firstName', 'lastName'], 
            }}
          />
        </div>
      ),
      minWidth: '170px',
      cell: (row) => {
        if ((row.user && row.user.assignedTo) && isUserCanEditCrmUser) {
          const managerFullName = `${row.user.assignedTo.firstName} ${row.user.assignedTo.lastName}`;
          return (
            managerFullName?.length >= valueOutputCutLength.assignedTo
              ? (
                <TooltipComponent
                  title={managerFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <Link to={`/edit-admin/${row.user.assignedTo._id}`} className="text-decoration-none">
                      {prettyCutOutputByCellWidth(managerFullName, 'assignedTo')}
                    </Link>
                )}
                />
              )
              : (
                <Link to={`/edit-admin/${row.user.assignedTo._id}`} className="text-decoration-none">
                  {managerFullName}
                </Link>
              )
          );
        }
        return (
          <span>-</span> 
        );
      },
      omit: !!userId.length || omitColumn(columnConfig, 'Assigned To'),
    }, 
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('setting.symbol')}>
            <div className="d-flex flex-row">
              <span>Symbol</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('setting.symbol')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Symbol" 
            data={coinsPairs}
            filters={coinPairsFilter}
            setFilters={setCoinPairsFilter}
            usersFilters={filters}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '110px',
      cell: (row) => (
        <span>{row?.setting?.symbol}</span>
      ),
      omit: omitColumn(columnConfig, 'Symbol'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('tradeStartPrice')}>
          <div className="d-flex flex-row">
            <span>Open Price</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('tradeStartPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '140px',
      cell: (row) => (
        <span>{toFixed(row?.tradeStartPrice, 4)}</span>
      ),
      selector: () => 'tradeStartPrice',
      omit: omitColumn(columnConfig, 'Open Price'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('position')}>
          <div className="d-flex flex-row">
            <span>Volume</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('position')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '100px',
      cell: (row) => (
        <span>{toFixed(row?.position, 3)}</span>
      ),
      selector: () => 'position',
      omit: omitColumn(columnConfig, 'Volume'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('timeOpened')}>
            <div className="d-flex flex-row">
              <span>Time Opened</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('timeOpened')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Time Opened" 
            data={dateOptions} 
            filters={timeOpenedFilter} 
            setFilters={setTimeOpenedFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
            isDateOptions
          />
        </div>
      ),
      minWidth: '154px',
      cell: (row) => (
        <span>{formatDate(new Date(row?.timeOpened))}</span>
      ),
      omit: omitColumn(columnConfig, 'Time Opened'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('takeProfitPrice')}>
          <div className="d-flex flex-row">
            <span>TP</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('takeProfitPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      cell: ({ takeProfitPrice }) => (
        takeProfitPrice?.toString().length >= valueOutputCutLength.tpSl
          ? (
            <TooltipComponent
              title={takeProfitPrice.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span>
                  {prettyCutOutputByCellWidth(takeProfitPrice.toString(), 'tpSl')}
                </span>
            )}
            />
          )
          : (
            <span>
              {takeProfitPrice ?? '-'}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'TP'),
      selector: () => 'takeProfitPrice',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('stopLossPrice')}>
          <div className="d-flex flex-row">
            <span>SL</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('stopLossPrice')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      cell: ({ stopLossPrice }) => (
        stopLossPrice?.toString().length >= valueOutputCutLength.tpSl
          ? (
            <TooltipComponent
              title={stopLossPrice.toString()}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span>
                  {prettyCutOutputByCellWidth(stopLossPrice.toString(), 'tpSl')}
                </span>
          )}
            />
          )
          : (
            <span>
              {stopLossPrice ?? '-'}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'SL'),
      selector: () => 'stopLossPrice',
    },
    {
      name: (
        <>
          <span>PNL</span>
          {/* <button type="button" className="sort-btn" onClick={() => sortFields('stopLossPrice')}>
            <SortIcon image={getSortIcon('stopLossPrice')} />
          </button> */}
        </>
      ),
      minWidth: '110px',
      selector: (row) => {
        if (row.status === 'pending') {
          return '-';
        }
        
        if (Object.keys(orderIdToPnl).length) {
          const pnl = orderIdToPnl[row._id]?.pnl ?? 0;

          return (
            toFixed(pnl, 2).toString().length >= valueOutputCutLength.pnl
              ? (
                <TooltipComponent
                  title={toFixed(pnl, 2).toString()}
                      // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span style={{ color: pnl > 0 ? 'green' : 'red', cursor: 'pointer' }}>
                      {prettyCutOutputByCellWidth(toFixed(pnl, 2).toString(), 'pnl')}
                    </span>
                    )}
                />
              )
              : (
                <span style={{ color: pnl > 0 ? 'green' : 'red', cursor: 'pointer' }}>
                  {toFixed(pnl, 2)}
                </span>
              )
          );
        }
        return (
          <LoadingDots />
        );
      },
      omit: omitColumn(columnConfig, 'PNL'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swap')}>
          <div className="d-flex flex-row">
            <span>Swap</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swap')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '100px',
      cell: (row) => (<span>{`${row?.swap} %`}</span>),
      omit: omitColumn(columnConfig, 'Swap'),
      selector: () => 'swap',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('commission')}>
          <div className="d-flex flex-row">
            <span>Commission</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('commission')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      cell: (row) => (
        <span>
          {`${row?.commission} %`}
        </span>
      ),
      omit: omitColumn(columnConfig, 'Commission'),
      selector: () => 'commission',
    },
    {
      name: 'Actions',
      omit: omitColumn(columnConfig, 'Actions'),
      minWidth: '180px',
      cell: (row) => (
        <>
          {isUserCanEditOpenOrders && (
            <button 
              type="button" 
              className="btn btn-success btn-sm me-1 p-1"
              onClick={() => showModal({ 
                bodyContent: <EditOpenOrderModal order={row} hideModal={hideModal} handleRefresh={handleRefresh} />, 
                headerContent: <h5>{`Edit Order #${row.customId}`}</h5>, 
              })}
            >
              <FontAwesomeIcon icon={faPencil} size="sm" style={{ marginRight: '5px' }} />
              Edit
            </button>
          )}
          {isUserCanStopOpenOrders && (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              disabled={!Object.keys(orderIdToPnl).length}
              onClick={() => {
                if (Object.keys(orderIdToPnl).length) {
                  const marketPrice = orderIdToPnl[row._id]?.marketPrice;
                  stopAction(row._id, marketPrice);
                }
              }}
            >
              Stop
            </button>
          )}
        </>
      ),
    },
  ];
  
  return activeOrdersColumns;
};

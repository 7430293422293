import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import * as Flags from 'react-flags-select';
import { BsTrashFill } from 'react-icons/bs';
import currencyFormatter from 'currency-formatter';
import { CopyToClipboardCmp, ModalFilters } from '../components';
import { nonNegativeAmount } from '../helpers/helper';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import SortIcon from '../components/SortIcon';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';

export const AffiliateClientColumns = (
  fullname,
  searchByName,
  searchEmail,
  searchByEmail,
  permissionName,
  copyReferral,
  handleCountryDropdownShow,
  countryDropdownShow,
  countryInput,
  myCountryHandler,
  handleSelectAllCountries,
  countriesToShow,
  isCheckCountry,
  handleCountryClick,
  titleCase,
  handleStatusDropdownShow,
  statusDropdownShow,
  statusInput,
  myStatustHandler,
  handleSelectAllStatuses,
  statusesToShow,
  isCheckStatus,
  handleStatusClick,
  salesStatuses,
  handleStatusChange2,
  handleAssignToDropdownShow,
  assignToDropdownShow,
  assignToInput,
  assignToHandler,
  handleSelectAllAssignTo,
  assignToShow,
  isCheckAssignTo,
  handleAssignToClick,
  formatDate,
  timePassed,
  deleteAction,
  userAction,
  filters,
  setFilters,
  setIsCheckCountry,
  setIsCheckStatus,
  setIsCheckAssignTo,
  handleSort,
) => {
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const clientColumns = [
    // {
    //    name: 'UID',
    //    selector: row => {
    //       return (
    //          <>
    //             <CopyToClipboard text={row?.users._id}>
    //                <span>
    //                   {row?.users._id?.slice(0, 4)}...{row?.users._id?.slice(row?.users._id.length - 4, row?.users._id.length)}
    //                   <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
    //                </span>
    //             </CopyToClipboard>
    //          </>
    //       )
    //    }
    // },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start', height: '80px' }}>
          <SortWrapper handleSort={() => sortFields('firstName lastName')}>
            <div className="d-flex flex-row">
              <span>Full Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstName lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={fullname}
            minLength="3"
            onChange={(e) => searchByName(e)}
          />
          {
            (fullname.length >= 1 && fullname.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter at least 3 characters</span>
            )  
          }
        </div>
      ),
      minWidth: '200px',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => {
        const {
          _id, firstName, lastName, 
        } = row;
        const fullName = `${firstName} ${lastName}`;
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={`${firstName} ${lastName}`}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/user-detail/${_id}`}>
                        <span className="full-name-text">
                          { prettyCutOutputByCellWidth(fullName, 'fullName') }
                        </span>
                      </Link>
                      )}
                  />
                )
                : (
                  <Link to={`/user-detail/${_id}`}>
                    <span className="full-name-text">
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>

        );
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start', height: '80px' }}>
          <SortWrapper handleSort={() => sortFields('email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={searchEmail}
            onChange={(e) => searchByEmail(e)}
          />
          {
            (searchEmail.length >= 1 && searchEmail.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter at least 3 characters</span>
            )  
          }
        </div>
      ),
      minWidth: '174px',
      selector: ({ email: rowEmail }) => (

        permissionName && permissionName.length > 0 && permissionName.includes('user_email')
          ? (
            <div>
              {
                rowEmail.length >= valueOutputCutLength.email 
                  ? (
                    <TooltipComponent
                      title={rowEmail}
                      // eslint-disable-next-line react/no-children-prop
                      children={(
                        <div>
                          <CopyToClipboard text={rowEmail}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(rowEmail, 'email')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : (
                    <CopyToClipboard text={rowEmail}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {rowEmail}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('phone')}>
          <div className="d-flex flex-row">
            <span>Phone Number</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('phone')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '174px',
      selector: (row) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
          ? (
            <div>
              {
                row?.phone.length >= valueOutputCutLength.phoneNumber 
                  ? (
                    <TooltipComponent
                      title={row.phone}
                      // eslint-disable-next-line react/no-children-prop
                      children={(
                        <div>
                          <CopyToClipboard text={row.phone}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(row.phone, 'phoneNumber')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  ) 
                  : (
                    <CopyToClipboard text={row.phone}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {row.phone}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('countryCode')}>
            <div className="d-flex flex-row">
              <span>Country</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('countryCode')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={countriesToShow} 
            filters={isCheckCountry} 
            setFilters={setIsCheckCountry} 
            tabName="Country" 
            searchParams={{ id: 'iso', params: ['nicename', 'iso', 'iso3', 'name'], optionsName: ['nicename'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '160px',
      selector: (row) => {
        if (row.countryCode && row.country?.[0]?.nicename) {
          const flagTitleCase = titleCase(row.countryCode);
          const UserCountryFlag = Flags[flagTitleCase];
          const countryNicename = row.country?.[0]?.nicename;

          return (
            countryNicename.length >= valueOutputCutLength.country
              ? (
                <TooltipComponent 
                  title={countryNicename}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div>
                      <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                      <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(countryNicename, 'country')}</span>
                    </div>
                  )}
                />
              )
              : (
                <>
                  <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                  <span>{countryNicename}</span>
                </>
              )
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('salesStatusLabel')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('salesStatusLabel')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            data={salesStatuses} 
            filters={isCheckStatus} 
            setFilters={setIsCheckStatus} 
            tabName="Status" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '190px',
      selector: (row) => (row.salesStatusId ? row.salesStatusId : ''),
      cell: (row) => {
        let currSalesStatusType = {
          _id: '',
          name: 'Select Status',
          color: '#fff',
        };
        if (row.salesStatusId) {
          currSalesStatusType = salesStatuses.find(
            (stat) => stat._id === row.salesStatusId,
          );
        }
        return (
          <select
            className="form-control user-status-select leads-status-select"
            name="type"
            value={currSalesStatusType?._id}
            onChange={(e) => handleStatusChange2(row._id, e)}
            style={{
              color: currSalesStatusType?.color
                ? currSalesStatusType?.color
                : '#fff',
            }}
          >
            <option value="" style={{ color: '#fff' }} color="#fff">
              Select Status
            </option>
            {salesStatuses?.length > 0
              && salesStatuses?.map((currentStatus) => (
                <option
                  value={currentStatus?._id}
                  key={currentStatus?._id}
                  style={{ color: currentStatus?.color }}
                  color={currentStatus?.color}
                >
                  {' '}
                  {currentStatus?.name}
                </option>
              ))}
          </select>
        );
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('balanceInUSD')}>
          <div className="d-flex flex-row">
            <span>Balance</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('balanceInUSD')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => nonNegativeAmount(row.account?.previousTotalAmount),
      cell: (row) => {
        const balance = row?.account?.previousTotalAmount ?? 0;
        if (balance.toString().length >= valueOutputCutLength.balance) {
          return (
            <TooltipComponent 
              title={balance.toString()}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(nonNegativeAmount(balance), { code: 'USD' }), 'balance')}
                </span>
              )}
            />
          );
        }
        return (
          <span>
            {currencyFormatter.format(nonNegativeAmount(balance), { code: 'USD' })}
          </span>
        );
      },
      minWidth: '110px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('affiliateFullName')}>
          <div className="d-flex flex-row">
            <span>Affiliator</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('affiliateFullName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => {
        if (row.affiliator) { 
          return `${row.affiliator?.[0]?.firstName} ${row.affiliator?.[0]?.lastName}`; 
        }
        return '-';
      },
      cell: (row) => {
        const affiliator = row.affiliator?.[0] ? row.affiliator?.[0] : '';

        if (affiliator) {
          const affiliateFullName = `${affiliator?.firstName} ${affiliator?.lastName}`;
          return (
            affiliateFullName.length >= valueOutputCutLength.affiliateName 
              ? (
                <TooltipComponent 
                  title={affiliateFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${row._id}`} data-tip={`${affiliateFullName}`}>
                      {prettyCutOutputByCellWidth(affiliateFullName, 'affiliateName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" data-for={`aff-${row._id}`} data-tip={`${affiliateFullName}`}>
                  {affiliateFullName}
                </div>
              )
          );
        }
        return '-';
      },
      minWidth: '140px',
      omit:
        !permissionName
        || permissionName.length < 1
        || !permissionName.includes('assign_to_agent'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            data={assignToShow} 
            filters={isCheckAssignTo} 
            setFilters={setIsCheckAssignTo} 
            tabName="Assigned To" 
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={filters}
            setUserFilters={setFilters}
          />
        </div>
      ),
      minWidth: '170px',
      selector: (row) => {
        if (row.assignedToAgent) { return `${row.assignedToAgent?.[0]?.firstName} ${row.assignedToAgent?.[0]?.lastName}`; }
        return '-';
      },
      cell: (row) => {
        const assignedToAgent = row.assignedToAgent?.[0]
          ? row.assignedToAgent?.[0]
          : '';
        const fullNameAsignedTo = `${assignedToAgent?.firstName} ${assignedToAgent?.lastName}`;
        const fullNameExcist = (assignedToAgent?.firstName?.length > 0 && assignedToAgent?.lastName?.length > 0);

        return (
          fullNameAsignedTo.length >= valueOutputCutLength.assignedTo ? (
            <TooltipComponent 
              title={fullNameAsignedTo}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${row._id}`} data-tip={`${fullNameAsignedTo}`}>
                  {prettyCutOutputByCellWidth(fullNameExcist ? fullNameAsignedTo : '-', 'assignedTo')}
                </div>
            )}
            />
          ) : (
            <span>{fullNameExcist ? fullNameAsignedTo : '-'}</span>
          )
        );
      },
      omit:
        !permissionName
        || permissionName.length < 1
        || !permissionName.includes('assign_to_agent'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Registration Date</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => (
        <>
          <span data-tip={formatDate(new Date(row.createdAt))}>
            {formatDate(new Date(row.createdAt))}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
      minWidth: '165px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
          <div className="d-flex flex-row">
            <span>Last Login Date</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lastLoginAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => (
        <>
          <span
            data-tip={
              row.lastLoginAt
                ? formatDate(new Date(row.lastLoginAt))
                : '-'
            }
          >
            {row.lastLoginAt
              ? formatDate(new Date(row.lastLoginAt))
              : '-'}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
      minWidth: '165px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
          <div className="d-flex flex-row">
            <span>Online</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lastLoginAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => (
        <>
          <span
            data-tip={
              timePassed(row.lastLoginAt)
              && row.isLogin === true
                ? timePassed(row.lastLoginAt)
                : 'Offline'
            }
          >
            {timePassed(row.lastLoginAt) && row.isLogin === true
              ? timePassed(row.lastLoginAt)
              : 'Offline'}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
      minWidth: '80px',
    },
    {
      name: 'Delete User',
      minWidth: '176px',
      cell: (row) => (
        <>
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('delete_user') ? (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(row._id)}
            >
              <BsTrashFill color="white" size={18} />
            </button>
            ) : null}
          {/* {row.refCount >= 0 && row.refCount < 2 ?
                         <button className="btn btn-warning btn-sm me-1 p-1" onClick={() => viewReferrals(row?.users?._id, setShow(false))}>Referrals</button>
                         : null
                      } */}
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('block_user') ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {row.status === true ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row._id, 'block')}
                >
                  Block
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row._id, 'unBlock')}
                >
                  Unblock
                </button>
              )}
            </div>
            ) : null}
        </>
      ),
    },
  ];
  return clientColumns;
};

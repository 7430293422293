export const cfdSettingColumns = [
  {
    name: 'Symbol',
    field: 'symbol',
    selected: true,
  },
  {
    name: 'Alias',
    field: 'alias',
    selected: true,
  },
  {
    name: 'Base Currency',
    field: 'secondary',
    selected: true,
  },
  {
    name: 'Market',
    field: 'type.name',
    selected: true,
  },
  {
    name: 'Shift',
    field: 'shift',
    selected: true,
  },
  {
    name: 'Leverage',
    field: 'leverage',
    selected: true,
  },
  {
    name: 'Lot Step',
    field: 'lotStep',
    selected: true,
  },
  {
    name: 'Lot Size',
    field: 'lotSize',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Swap Long',
    field: 'swapLong',
    selected: true,
  },
  {
    name: 'Swap Short',
    field: 'swapShort',
    selected: true,
  },
  {
    name: 'Spread Max',
    field: 'spreadMax',
    selected: true,
  },
  {
    name: 'Spread Min',
    field: 'spreadMin',
    selected: true,
  },
  {
    name: 'Max Volume',
    field: 'maxVolume',
    selected: true,
  },
  {
    name: 'Min Volume',
    field: 'minVolume',
    selected: true,
  },
  {
    name: 'Visible',
    field: 'isVisible',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];

import { toast } from 'react-toastify';
import {
  DELETE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT,
  GET_CFD_ACCOUNTS_QUERY,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_ACCOUNT_PTIONS_BY_USER_ID,
  UPDATE_USER_ACCOUNT,
  MASS_UPDATE_USER_ACCOUNTS,
} from './cfdAccountTypes';
import { apiHelper } from '../apiHelper';

export const getAccountOptionsByUserId = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/cfd-account/options?userId=${userId}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_ACCOUNT_PTIONS_BY_USER_ID,
        payload: res.data.options,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCfdAccountsByQuery = ({
  limit, page, sort = '{ "createdAt": -1 }', query = {},
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/cfd-account/query${url}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CFD_ACCOUNTS_QUERY,
        payload: res.data.cfdAccounts,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const massUpdateUserAccounts = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/cfd-account/mass-update', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: MASS_UPDATE_USER_ACCOUNTS,
        payload: data,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateUserAccount = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/cfd-account/${id}`, data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_USER_ACCOUNT,
        payload: res.data.cfdAccount,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const createUserAccount = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/cfd-account', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: CREATE_USER_ACCOUNT,
        payload: res.data.cfdAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const createSavingAccount = ({ userId, currencyId }) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/saving-account', { userId, currencyId });
    if (res && res.data && res.data.success) {
      const { message, savingAccount } = res.data;

      toast.success(message, {
        autoClose: 1000,
      });

      await dispatch({
        type: CREATE_USER_ACCOUNT,
        payload: savingAccount,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.message);
  }
};

export const deleteUserAccount = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/cfd-account/${id}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_USER_ACCOUNT,
        payload: res.data.cfdAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSavingAccount = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/saving-account/${id}`);
    if (res && res.data && res.data.success) {
      const { message, savingAccount } = res.data;

      toast.success(message, {
        autoClose: 1000,
      });

      await dispatch({
        type: DELETE_USER_ACCOUNT,
        payload: savingAccount,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const exportFilteredDataToFile = (filteredItems, columns, fileType, orderData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/cfd-account/export', {
      filteredItems, columns, fileType, orderData,
    });
    if (res.data) {
      const { data } = res;
      if (data.fileUrl) {
        window.open(data?.fileUrl);
      } else {
        toast.error('Something went wrong.');
      }
    } else {
      toast.error('Something went wrong.');
    }
    dispatch({
      type: EXPORT_FILTERED_DATA_TO_FILE,
      payload: true,
    });
  } catch (error) {
    toast.error(error.message);
  }
};

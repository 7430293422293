import React, { useEffect, useState } from 'react';
import Header from './Header';
import NavigationMenu from './NavigationMenu';

function PrivateLayout({ title, children }) {
  const [authToken, setAuthToken] = useState();
  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'BackOffice';
  }, [title]);
  
  useEffect(() => {
    const token = localStorage.token ?? sessionStorage.token;
    setAuthToken(token);

    if (!token) {
      window.location.href = '/login';
    } else if (localStorage.userId) {
      // const validateUserId = localStorage.userId.slice(1, -1);
      // dispatch(userLastActivity(validateUserId));
      // setInterval(() => {
      // dispatch(userLastActivity(validateUserId));
      // }, 60000);
    }
  }, []);

  return (
    authToken
      ? (
        <>
          <Header />
          <div className="dashboard-wrapper main-padding">
            <div className="row">
              <NavigationMenu />
              {children}
            </div>
          </div>
        </>
      )
      : null
  );
}
export default PrivateLayout;

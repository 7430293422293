import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_All_VARIABLES, CREATE_NEW_VARIABLES, UPDATE_ALERT_MESSAGE_VARIABLES, DELETE_ALERT_MESSAGE_VARIABLES,
} from './variablesTypes';

export const getAllVariables = ({
  page, limit, sort, query,
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/variables${url}`);
    if (res && res.data && res.data.success) {
      const { data } = res;
      dispatch({
        type: GET_All_VARIABLES,
        payload: data.variables,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't get variables list");
  }
};

export const createNewVariables = (variablesData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/variables', variablesData);
    if (res && res.data && res.data.success) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: CREATE_NEW_VARIABLES,
        payload: data.variables,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't create new variables");
  }
};

export const updateVariables = ({ id, ...variablesData }) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/variables/${id}`, variablesData);
    if (res && res.data && res.data.success) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_ALERT_MESSAGE_VARIABLES,
        payload: data.variables,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't update variables");
  }
};

export const deleteAlertMessageVariables = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/variables/${id}`);
    if (res && res.data && res.data.success) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: DELETE_ALERT_MESSAGE_VARIABLES,
        payload: data.variables,
      });
    }
  } catch (error) {
    console.log(error.message);

    toast.error("Error: cant't delete variables");
  }
};

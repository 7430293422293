import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, MenuItem, Autocomplete, TextField, styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { getAssetQuote } from '../../redux/assetQuote/assetQuoteActions';
import { massOrderCreation } from '../../redux/cfdOrder/cfdOrderActions';

export function MassOpenOrdersModal({ accounts, hideModal, clearRows }) {
  const dispatch = useDispatch();
  const { cfdSettings: settings } = useSelector((state) => state.cfdSettings);
  const assetQuote = useSelector((state) => state.assetQuote);
  const { massCreationResults } = useSelector((state) => state.cfdOrders);

  const [formData, setFormData] = useState({
    symbolData: null,
    volumePercent: 0,
    tradeStartPrice: 0,
    takeProfitPrice: 0,
    stopLossPrice: 0,
    tradeType: null,
  });
  const [step, setStep] = useState(1);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const options = settings.map((setting) => ({ value: setting._id, label: setting.label, brokerSymbol: setting.brokerSymbol }));
  const isBtnEnabled = !!(formData.symbolData && formData.volumePercent && formData.tradeType !== null);

  useEffect(() => {
    dispatch(getCfdSettings());
  }, []);

  useEffect(() => {
    if (formData.symbolData !== null) {
      dispatch(getAssetQuote(formData.symbolData.brokerSymbol));
    }

    setFormData((prev) => ({ ...prev, tradeStartPrice: 0 }));
  }, [formData.symbolData]);

  useEffect(() => {
    if (formData.tradeType !== null) {
      setFormData((prev) => ({ ...prev, tradeStartPrice: formData.tradeType === 1 ? assetQuote.bid : assetQuote.ask }));
    }
  }, [formData.tradeType, assetQuote]);

  const CustomizedInput = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      height: '45px',
      borderRadius: '4px',
      border: 'unset',
      outline: 'unset',
      '&.Mui-focused': {
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiIconButton-root': {
      border: '3px solid #fff)',
      borderRadius: '50%',
      height: '24px',
      width: '24px',
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      padding: 0,
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      color: '#fff',
    },
  }));

  const handleNumberValue = (value) => {
    let sanitizedValue = value.replace(/[^\d.]/g, '');

    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      sanitizedValue = `${parts[0]}.${parts.slice(1).join('')}`;
    }

    sanitizedValue = sanitizedValue.replace(/^0+(?=\d)/, '');

    if (sanitizedValue === '.') {
      sanitizedValue = '';
    }

    return sanitizedValue;
  };

  const submit = async () => {
    const data = { 
      ...formData, 
      accountIds: accounts.map(({ _id }) => (_id)), 
      settingId: formData.symbolData.value,
      isTPSLSet: !!(formData.takeProfitPrice || formData.stopLossPrice),
    };

    setIsBtnLoading(true);
    await dispatch(massOrderCreation(data));
    setIsBtnLoading(false);
    setStep(2);
    clearRows();
  };

  return step === 1 ? (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Symbol</label>
          <Autocomplete
            sx={{ maxHeight: '45px', border: 'none' }}
            freeSolo
            placeholder="Select Symbol"
            options={options}
            onChange={(_, value) => setFormData((prev) => ({ ...prev, symbolData: value }))}
            value={formData.symbolData}
            renderInput={(params) => <CustomizedInput sx={{ border: '1px solid #2d3440', backgroundColor: '#2d3440', maxHeight: '45px' }} {...params} />}
            renderOption={(props, { label, value }) => (
              <MenuItem
                {...props}
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            )}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume (0-100% of balance)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            value={formData.volumePercent}
            name="tradeStartPrice"
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, volumePercent: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Take Profit</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Take Profit"
            aria-label=""
            aria-describedby=""
            name="takeProfitPrice"
            value={formData.takeProfitPrice}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, takeProfitPrice: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Stop Loss</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Stop Loss"
            aria-label=""
            aria-describedby=""
            name="stopLossPrice"
            value={formData.stopLossPrice}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, stopLossPrice: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Direction</label>
          <Select
            className="form-control"
            value={formData.tradeType}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, tradeType: value }))}
            name="tradeType"
            style={{ height: '40px' }}
          >
            <MenuItem value={null} />
            <MenuItem value={1}>Buy</MenuItem>
            <MenuItem value={0}>Sell</MenuItem>
          </Select>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={formData.tradeStartPrice}
            name="tradeStartPrice"
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, stopLossPrice: handleNumberValue(value) }))}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={submit} disabled={!isBtnEnabled || isBtnLoading}>
          {
            !isBtnLoading ? 'Create' : <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          }
        </button>
        <button type="button" className="btn btn-danger" onClick={hideModal}>Cancel</button>
      </div>
    </>
  ) : (
    <div className="row mb-3">
      {
        massCreationResults.map((result) => (
          <div
            key={result.id}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', 
            }}
          >
            <span>{`Account #${result.id}`}</span>
            <span style={{ color: `${result.success ? 'green' : 'red'}` }}>{result.message}</span>
          </div>
        ))
      }
    </div>
  );
}

import React from 'react';
import styles from './styles.module.css';

export function CalendarFilter({ sortQuery, setSortQuery, agentUsers }) {
  const sortOptions = [
    { label: 'Start At', value: 'startAt' },
    { label: 'Manager', value: 'manager' },
    { label: 'User', value: 'user' },
    { label: 'End At', value: 'endAt' },
    { label: 'User Country', value: 'userCountry' },
    { label: 'User Timezone', value: 'userTimezone' },
    { label: 'Comment', value: 'comment' },
    { label: 'User Credit Total', value: 'userCreditTotal' },
    { label: 'User Net Deposit', value: 'userNetDeposit' },
    { label: 'User Min Deposit', value: 'userMinDeposit' },
    { label: 'User Last Trade', value: 'userLastTrade' },
    { label: 'User Last Online', value: 'userLastOnline' },
    { label: 'User Created', value: 'userCreated' },
    { label: 'Duration', value: 'duration' },
    { label: 'User Birthday', value: 'userBirthday' },
  ];

  const handleChangeSort = (e) => {
    const { name, value } = e.target;
    setSortQuery((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className={styles.calendarSubHeader}>
      <div className={styles.selectorBlock}>
        <div>
          <span className="white-text">Sort by:</span>
          <select 
            className={styles.selector}
            onChange={handleChangeSort}
            name="sortBy"
            value={sortQuery.sortBy}
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <span className="white-text">
            Manager:
          </span>
          <select
            className={styles.selector}
            onChange={handleChangeSort}
            name="manager"
            value={sortQuery.manager}
          >
            <option value="">All</option>
            {agentUsers.map((agent) => (
              <option key={agent.value} value={agent.value}>
                {agent.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react/no-children-prop */
import React, {
  useState, useMemo, useContext,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone, faComment, faEnvelope, faPhone, faExternalLink, faSms,
} from '@fortawesome/free-solid-svg-icons';
import * as Flags from 'react-flags-select';
import { toast } from 'react-toastify';
import { BsTrashFill } from 'react-icons/bs';
import { editUser } from '../redux/users/userActions';
import { ErrorSpan, ModalFilters, getLocalDataByCountryCode } from '../components';
import { dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { ENV } from '../config/config';
import { FIND_ONE_USER_AND_EDIT_IN_USERS } from '../redux/users/userTypes';
import { createUserStatusChangesHistory } from '../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import { apiHelper } from '../redux/apiHelper';
import SendSmtpEmailModal from '../pages/Users/UserDetailComponents/SendSmtpEmailModal/SendSmtpEmailModal';
import { ModalContext } from '../context';
import SendSmsModal from '../pages/Users/UserDetailComponents/SendSmsModal/SendSmsModal';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import SortIcon from '../components/SortIcon';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import { RangeModalDebounceFilter } from '../pages/Transactions/modalFilters';

const currencyFormatter = require('currency-formatter');

export const UsersSchema = (
  permissionName,
  formatDate,
  allAffiliates,
  affiliateFilter,
  setAffiliateFilter,
  salesStatuses,
  countriesToShow,
  userAction,
  isCheckStatus,
  setIsCheckStatus,
  handleStatusChange2,
  assignToShow,
  isCheckAssignTo,
  deleteAction,
  clientFullname,
  searchByName,
  clientEmail,
  searchByEmail,
  clientPhone,
  searchByPhone,
  isCheckCountry, 
  setIsCheckCountry,
  setIsCheckAssignTo,
  columnConfig,
  usersFilters,
  setUserFilters,
  setTimeInitiatedFilter,
  timeInitiatedFilter,
  lastCommentDate,
  setLastCommentDate,
  lastActionDate,
  setLastActionDate,
  firstTimeDepositFilter,
  setFirstTimeDepositFilter,
  isCheckFtdOwner,
  setIsCheckFtdOwner,
  isCheckStdOwner,
  setIsCheckStdOwner,
  lastPromotion,
  setLastPromotion,
  lastDemotion,
  setLastDemotion,
  lastAssignment,
  setLastAssignment,
  isCheckPreviousOwner,
  setIsCheckPreviousOwner,
  lastLoginAt,
  setLastLoginAt,
  lastOnline,
  setLastOnline,
  lastCallDate,
  isCheckOnlineStatuses,
  setIsCheckOnlineStatuses,
  setLastCallDate,
  brands,
  selectedBrands,
  setSelectedBrands,
  currencyRates,
  addCommentHandler = () => {},
  handleSort,
  onlineUsers,
  refresh,
  totalCallsRange,
  setTotalCallsRange,
  setDebounceTotalCallsRange,
  ibdeskToShow,
  ibdeskFilterPreset,
  setIbdeskFilterPreset,
  managerFilterPreset,
  setManagerFilterPreset,
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const CRMUser = useSelector((state) => state.crmUser.crmUserInfo);
  const users = useSelector((state) => state.users.users);
  const apiKey = process.env.REACT_APP_VOISO_API_KEY;
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanSeeComments = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('comments');

  const [showComment, setShowComment] = useState('');
  const onlineUserIds = Object.values(onlineUsers);

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const onlineFilterPresets = [
    { _id: 'online', firstName: 'Online', lastName: '' },
    { _id: 'offline', firstName: 'Offline', lastName: '' },
  ];

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };
  const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();
  const timePassed = (date) => {
    let status = '';
    if (date) {
      const first = new Date(date);
      const end = new Date(Date.now());
      const elapsed = (end - first);
      const CalcElapsed = elapsed / 1000;
      if (CalcElapsed > 0 && CalcElapsed < 50) {
        status = 'Online';
      } else {
        status = 'Offline';
      }
      return status;
    }
    return status;
  };

  const handleShowComment = (rowId) => {
    setShowComment(rowId);
  };

  const handleDropdownChange = async (data, id) => {
    try {
      dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleStatusChange = async (e, id) => {
    try {
      const data = {
        salesStatusId: e.target.value,
      };
      await dispatch(editUser(id, data));

      const noStatusMock = { _id: '', name: 'Select Status', color: '#fff' };
      const previousStatus = users.find((user) => user._id === id)?.salesStatus ?? noStatusMock;
      const newSalesStatus = salesStatuses.find((status) => status._id === data.salesStatusId) ?? noStatusMock;

      if (previousStatus?._id !== newSalesStatus?._id) {
        dispatch(createUserStatusChangesHistory({
          user: id,
          agent: CRMUser._id,
          agentName: `${CRMUser.firstName} ${CRMUser.lastName}`,
          previousStatus: previousStatus.name,
          actualStatus: newSalesStatus.name,
        }));
      }

      dispatch({
        type: FIND_ONE_USER_AND_EDIT_IN_USERS,
        payload: {
          userId: id,
          ...data,
          salesStatus: newSalesStatus,
        },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const loginAsUser = (user) => {
    if (user?._id) {
      window.open(`${process.env.REACT_APP_SERVER_URL}/login?email=${user?.email}&userByAdmin=${1}`, '_blank');
    }
  };

  const omitColumn = (columnName) => {
    if (columnName === 'Brand' && !ENV.hasMultibrand) return true;

    let omit = false;

    columnConfig.forEach((column) => {
      if (column.name === columnName) {
        omit = !column.selected;
      }
    });

    return omit;
  };

  // const handleClickToCall = async (phone) => {
  //   const agent = CRMUser?.voipToken;

  //   try {
  //     await axios.get(`https://cc-ams03.voiso.com/api/v1/${apiKey}/click2call?agent=${agent}&number=${phone}`);
  //   } catch (error) {
  //     console.error('Error initiating click-to-call:', error);
  //   }
  // };


  const handleClickToCall = async ({ id }) => {
    try {
      const res = await apiHelper('post', `/api/voip/call/${id}`);
      if (res && res.data && res.data.success) {
        const { message } = res.data;
  
        toast.success(message);
        refresh();
      }
    } catch (error) {
      console.log(error.message);
  
      toast.error("Error: cant't make call");
    }
  };

  const handleSendEmailBtn = ({
    userId, showModal, hideModal, brandId, 
  }) => {
    showModal({
      headerContent: <h3>SEND EMAIL</h3>,
      bodyContent: (
        <SendSmtpEmailModal
          userId={userId}
          handleClose={hideModal}
          brandId={brandId}
        />
      ),
    });
  };

  const handleSendSmsBtn = (user, showModal, hideModal) => {
    showModal({
      headerContent: (
        <h3>
          Send SMS to 
          {' '}
          {`${user?.firstName} ${user?.lastName}`} 
        </h3>
      ),
      bodyContent: <SendSmsModal
        userId={user._id}
        handleClose={hideModal}
        toPhoneNumber={user?.phone}
      />,
    });
  };

  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('affiliates')), [permissionName]);

  const columns = useMemo(() => [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('customId')}>
          <span>ID</span>
          <button type="button" className="sort-btn">
            <SortIcon image={getSortIcon('customId')} />
          </button>
        </SortWrapper>
      ),
      minWidth: '110px',
      omit: omitColumn('ID'),
      cell: ({ customId }) => {
        if (customId.length >= valueOutputCutLength.id) {
          return (
            <TooltipComponent 
              title={customId}
              children={(
                <CopyToClipboard text={customId}>
                  <span style={{ margin: '5px' }}>
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
              )}
            />
          );
        } 
        return (
          <CopyToClipboard text={customId}>
            <span style={{ margin: '5px' }}>
              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
              {customId}
            </span>
          </CopyToClipboard>
        );
      },
    },
    {
      name: 'Actions',
      minWidth: '170px',
      omit: omitColumn('Full Name'),
      cell: (row) => {
        const {
          lastComment, _id, lastCommentAt, brandId,
        } = row;
        const { showModal, hideModal } = useContext(ModalContext);
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {_id === showComment && (
              <div
                className="depositsAdditionalInfoModal"
                style={{
                  width: '200px', display: 'flex', padding: '10px', left: 50, top: -80, 
                }}
              >
                <h6>Comments</h6>
                <br />
                <br />
                {lastComment?.length ? (
                  <>
                    {`Date: [${formatDate(new Date(lastCommentAt))}]`}
                    <br />
                    <br />
                    <div style={{ whiteSpace: 'pre-line' }}>{`Comment: ${lastComment.replace(/<br\s*\/?>/gi, '\n')}`}</div>
                  </>
                ) : (
                  'No Comments'
                )}
              </div>
            )}
  
            {isUserCanSeeComments && (
              <button
                type="button"
                className="datatable-action__btn"
                onClick={() => addCommentHandler(row)}
                onMouseEnter={() => handleShowComment(_id)} 
                onMouseLeave={() => setShowComment('')}
              >
                <FontAwesomeIcon icon={faComment} size="sm" />
              </button>
            )}
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleSendSmsBtn(row, showModal, hideModal)}
            >
              <FontAwesomeIcon icon={faSms} size="sm" />
            </button>
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleSendEmailBtn({
                userId: _id, 
                showModal,
                hideModal,
                brandId, 
              })}
            >
              <FontAwesomeIcon icon={faEnvelope} size="sm" />
            </button>
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleClickToCall({ id: _id })}
            >
              <FontAwesomeIcon icon={faPhone} size="sm" />
            </button>
            <Link 
              to={`/user-detail/${_id}`} 
              target="blank" 
              className="datatable-action__btn"
            >
              <FontAwesomeIcon icon={faExternalLink} size="sm" />
            </Link>
          </div>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('firstName lastName')}>
            <div className="d-flex flex-row">
              <span>Full Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstName lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientFullname}
            minLength="3"
            onChange={(e) => searchByName(e)}
          />
          <ErrorSpan filter={clientFullname} message="Please enter at least 3 characters" />
        </div>
      ),
      minWidth: '200px',
      omit: omitColumn('Full Name'), 
      sortField: 'firstName',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => {
        const {
          _id, firstName, lastName, 
        } = row;
        const fullName = `${firstName} ${lastName}`;
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={`${firstName} ${lastName}`}
                    children={(
                      <Link to={`/user-detail/${_id}`}>
                        <span className="full-name-text" style={{ color: onlineUserIds.includes(_id) ? 'green' : 'white' }}>
                          { prettyCutOutputByCellWidth(fullName, 'fullName') }
                        </span>
                      </Link>
                      )}
                  />
                )
                : (
                  <Link to={`/user-detail/${_id}`}>
                    <span className="full-name-text" style={{ color: onlineUserIds.includes(_id) ? 'green' : 'white' }}>
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>

        );
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            className="leads-search-bar-filter"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={clientEmail}
            onChange={(e) => searchByEmail(e)}
          />
          <ErrorSpan filter={clientEmail} message="Please enter at least 3 characters" />
        </div>
      ),
      minWidth: '174px',
      omit: omitColumn('Email'),
      selector: ({ email: rowEmail }) => (

        permissionName && permissionName.length > 0 && permissionName.includes('user_email')
          ? (
            <div>
              {
                rowEmail.length >= valueOutputCutLength.email 
                  ? (
                    <TooltipComponent
                      title={rowEmail}
                      children={(
                        <div>
                          <CopyToClipboard text={rowEmail}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(rowEmail, 'email')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : (
                    <CopyToClipboard text={rowEmail}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {rowEmail}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('phone')}>
            <div className="d-flex flex-row">
              <span>Phone Number</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('phone')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={clientPhone}
            onChange={(e) => searchByPhone(e)}
          />
          <ErrorSpan filter={clientPhone} message="Please enter at least 3 characters" />
        </div>
      ),
      minWidth: '174px',
      omit: omitColumn('Phone Number'), 
      selector: (row) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
          ? (
            <div>
              {
                row?.phone.length >= valueOutputCutLength.phoneNumber 
                  ? (
                    <TooltipComponent
                      title={row.phone}
                      children={(
                        <div>
                          <CopyToClipboard text={row.phone}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(row.phone, 'phoneNumber')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  ) 
                  : (
                    <CopyToClipboard text={row.phone}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {row.phone}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('country.nicename')}>
            <div className="d-flex flex-row">
              <span>Country</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('country.nicename')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Country" 
            data={countriesToShow} 
            filters={isCheckCountry} 
            setFilters={setIsCheckCountry} 
            searchParams={{ id: 'iso', params: ['nicename', 'iso', 'iso3', 'name'], optionsName: ['nicename'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '160px',
      omit: omitColumn('Country'),
      selector: (row) => {
        if (row.countryCode && row.country?.[0]?.nicename) {
          const flagTitleCase = titleCase(row.countryCode);
          const UserCountryFlag = Flags[flagTitleCase];
          const countryNicename = row.country?.[0]?.nicename;

          return (
            countryNicename.length >= valueOutputCutLength.country
              ? (
                <TooltipComponent 
                  title={countryNicename}
                  children={(
                    <div>
                      <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                      <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(countryNicename, 'country')}</span>
                    </div>
                  )}
                />
              )
              : (
                <>
                  <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                  <span>{countryNicename}</span>
                </>
              )
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Created</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Created" 
            onClick={(e) => e.stopPropagation()}
            data={dateOptions} 
            filters={timeInitiatedFilter} 
            setFilters={setTimeInitiatedFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      minWidth: '154px',
      omit: omitColumn('Created'),
      selector: (row) => (
        <span>{prettyCutOutputByCellWidth(formatDate(new Date(row.createdAt), 'created'))}</span>
      ),
      sortField: 'createdAt',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('affiliateFullName')}>
            <div className="d-flex flex-row">
              <span>Affiliate Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('affiliateFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Affiliate Name" 
            data={allAffiliates} 
            filters={affiliateFilter} 
            setFilters={setAffiliateFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ affiliateFullName = '-' }) => affiliateFullName ?? '-',
      cell: ({ _id, affiliateFullName = '' }) => {
        if (affiliateFullName) {
          return (
            affiliateFullName.length >= valueOutputCutLength.affiliateFullName 
              ? (
                <TooltipComponent 
                  title={affiliateFullName}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${_id}`} data-tip={`${affiliateFullName}`}>
                      {prettyCutOutputByCellWidth(affiliateFullName, 'affiliateFullName')}
                    </div>
                  )}
                />
              )
              : (
                <div
                  className="truncate-text"
                  data-for={`aff-${_id}`}
                  data-tip={`${affiliateFullName}`}
                >
                  {affiliateFullName}
                </div>
              )
          );
        }
        return '-';
      },
      omit: omitColumn('Affiliate Name') || !isAffiliateRule,
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('source')}>
            <div className="d-flex flex-row">
              <span>Funnel Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('source')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn('Funnel Name'), 
      selector: (row) => (
        <div>
          {
            row?.source?.length >= valueOutputCutLength.funnelName 
              ? (
                <TooltipComponent 
                  title={row.source}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row.source}`}>
                      {prettyCutOutputByCellWidth(row.source, 'funnelName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row.source}>
                  {row.source ? row.source : '-'}
                </div>
              )
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('additionalInfo')}>
            <div className="d-flex flex-row">
              <span>Additional Info</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('additionalInfo')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn('Additional Info'),
      selector: (row) => (
        <div>
          {
            row?.additionalInfo?.length >= valueOutputCutLength.additionalInfo
              ? (
                <TooltipComponent
                  title={row.additionalInfo}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row.additionalInfo}`}>
                      {prettyCutOutputByCellWidth(row.additionalInfo, 'funnelName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row.additionalInfo}>
                  {row.additionalInfo ?? '-'}
                </div>
              )
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('managerUser.fullName')}>
            <div className="d-flex flex-row">
              <span>Manager</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('managerUser.fullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Manager"
            data={assignToShow}
            filters={managerFilterPreset}
            setFilters={setManagerFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn('Manager'),
      selector: (row) => (
        <div>
          {
            row?.uplineCRMUsers?.length ? (
              row.uplineCRMUsers.find((uplineCrmUser) => uplineCrmUser.role === 'Manager')?.fullName ?? '-'
            ) : '-'
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('ibdeskUser.fullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdeskUser.fullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdesk"
            data={ibdeskToShow}
            filters={ibdeskFilterPreset}
            setFilters={setIbdeskFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn('Desk/Ib'),
      selector: (row) => (
        <div>
          {
            row?.uplineCRMUsers?.length ? (
              row.uplineCRMUsers.find((uplineCrmUser) => uplineCrmUser.role === 'IBDesk')?.fullName ?? '-'
            ) : '-'
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Assigned To" 
            data={assignToShow} 
            filters={isCheckAssignTo} 
            setFilters={setIsCheckAssignTo} 
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '190px',
      selector: ({ assignedToFullName = '-' }) => assignedToFullName ?? '-',
      cell: ({ _id, assignedTo = '', assignedToFullName = '' }) => {
        if (permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent')) {
          return (
            <select 
              className="form-control user-status-select leads-status-select text-color-options-white"
              name="type"
              value={assignedTo ?? ''}
              style={{ color: '#fff' }}
              onChange={(e) => handleChangeAssignTo(e, _id)}
            >
              <option value="" style={{ color: '#fff' }} color="#fff">
                Select Manager
              </option>
              {assignedTo && (
                <option value={assignedTo} style={{ color: '#fff' }} color="#fff">
                  {assignedToFullName}
                </option>
              )}
              {assignToShow?.length > 0 && assignToShow.map((item) => {
                if (item._id !== assignedTo) {
                  return (
                    <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                  ); 
                }
                return null;
              })}
            </select>
          );
        }
        return assignedTo ? <div>{`${assignedToFullName}`}</div> : <div>-</div>;
      },
      omit: omitColumn('Assigned To'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('salesStatusLabel')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('salesStatusLabel')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Status" 
            data={salesStatuses} 
            filters={isCheckStatus} 
            setFilters={setIsCheckStatus} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn('Status'),
      selector: (row) => (row.salesStatusId ? row.salesStatusId : ''),
      cell: (row) => {
        let currSalesStatusType = { _id: '', name: 'Select Status', color: '#fff' };
        if (row.salesStatusId) {
          currSalesStatusType = salesStatuses.find((stat) => stat._id === row.salesStatusId);
        }
        return (
          <select 
            className="form-control user-status-select leads-status-select"
            name="status"
            value={currSalesStatusType?._id}
            onChange={(e) => handleStatusChange(e, row._id)}
            style={{ color: currSalesStatusType?.color ? currSalesStatusType?.color : '#fff' }}
          >
            <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
            {salesStatuses?.length && salesStatuses.map((currentStatus) => (
              <option value={currentStatus._id} key={currentStatus._id} style={{ color: currentStatus.color }} color={currentStatus.color}>
                {' '}
                {currentStatus.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brand.name')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brand.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand" 
            data={brands} 
            filters={selectedBrands} 
            setFilters={setSelectedBrands}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn('Brand'), 
      cell: ({ brandId, _id }) => (
        !!brands.length && (
        <select 
          className="form-control user-status-select leads-status-select text-white"
          name="brand"
          value={brandId || ''}
          onChange={({ target: { value } }) => handleDropdownChange({ brandId: value }, _id)}
        >
          <option value="" className="text-white">-</option>
          {brands.length && brands.map((brand) => (
            <option value={brand._id} key={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
        )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('balanceInUSD')}>
          <div className="d-flex flex-row">
            <span>Balance</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('balanceInUSD')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Balance'),
      minWidth: '110px',
      cell: ({ balanceInUSD }) => (
        balanceInUSD?.toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={balanceInUSD.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(balanceInUSD, { code: 'USD' }), 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(balanceInUSD, { code: 'USD' })}
            </span>
          )
      ),
      sortField: 'totalBalance',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('creditInUSD')}>
          <div className="d-flex flex-row">
            <span>Credit</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('creditInUSD')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Credit'),
      minWidth: '110px',
      cell: ({ creditInUSD }) => (
        creditInUSD?.toString()?.length >= valueOutputCutLength.credit 
          ? (
            <TooltipComponent 
              title={creditInUSD.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(creditInUSD, { code: 'USD' }), 'credit')}
                </span>
              )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(creditInUSD, { code: 'USD' })}
            </span>
          )
      ),
      sortField: 'totalCredit',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('netDeposits')}>
          <div className="d-flex flex-row">
            <span>Net Deposits</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('netDeposits')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Net Deposits'),
      minWidth: '140px',
      cell: ({ netDeposits }) => (
        netDeposits?.toString()?.length >= valueOutputCutLength.netDeposits 
          ? (
            <TooltipComponent 
              title={netDeposits.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(netDeposits, { code: 'USD' }), 'netDeposits')}
                </span>
            )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(netDeposits, { code: 'USD' })}
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('firstTimeDeposit')}>
            <div className="d-flex flex-row">
              <span>First Time Deposit</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstTimeDeposit')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="First Time Deposit" 
            data={dateOptions}
            filters={firstTimeDepositFilter}
            setFilters={setFirstTimeDepositFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('First Time Deposit'),
      minWidth: '160px',
      sortField: 'firstTimeDeposit',
      selector: ({ firstTimeDeposit = '' }) => (
        <span>{firstTimeDeposit ? formatDate(new Date(firstTimeDeposit)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('ftdOwnerFullName')}>
            <div className="d-flex flex-row">
              <span>FTD Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ftdOwnerFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="FTD Owner" 
            data={assignToShow}
            filters={isCheckFtdOwner}
            setFilters={setIsCheckFtdOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      omit: omitColumn('FTD Owner'),
      minWidth: '150px',
      sortField: 'ftdOwnerFullName',
      cell: ({ ftdOwnerFullName = '' }) => {
        ftdOwnerFullName = ftdOwnerFullName?.length === 1 ? '' : ftdOwnerFullName;

        return ftdOwnerFullName.length >= valueOutputCutLength.ftdOwnerFullName
          ? (
            <TooltipComponent
              title={ftdOwnerFullName}
              children={(
                <span className="full-name-text">
                  { prettyCutOutputByCellWidth(ftdOwnerFullName, 'ftdOwnerFullName') }
                </span>
            )}
            />
          )
          : (
            <span className="full-name-text">
              {ftdOwnerFullName.length ? ftdOwnerFullName : '-'}
            </span>
          );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('stdOwnerFullName')}>
            <div className="d-flex flex-row">
              <span>STD Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('stdOwnerFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="STD Owner" 
            data={assignToShow}
            filters={isCheckStdOwner}
            setFilters={setIsCheckStdOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      omit: omitColumn('STD Owner'),
      minWidth: '150px',
      sortField: 'stdOwnerFullName',
      cell: ({ stdOwnerFullName = '' }) => {
        stdOwnerFullName = stdOwnerFullName?.length === 1 ? '' : stdOwnerFullName;

        return stdOwnerFullName.length >= valueOutputCutLength.stdOwnerFullName
          ? (
            <TooltipComponent
              title={stdOwnerFullName}
              children={(
                <span className="full-name-text">
                  { prettyCutOutputByCellWidth(stdOwnerFullName, 'stdOwnerFullName') }
                </span>
              )}
            />
          )
          : (
            <span className="full-name-text">
              {stdOwnerFullName.length ? stdOwnerFullName : '-'}
            </span>
          );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isOnline')}>
            <div className="d-flex flex-row">
              <span>Online</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isOnline')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Online" 
            data={onlineFilterPresets} 
            filters={isCheckOnlineStatuses} 
            setFilters={setIsCheckOnlineStatuses} 
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '120px',
      omit: omitColumn('Online'),
      sortField: 'online',
      selector: ({ _id }) => (
        onlineUserIds.includes(_id)
          ? (
            <span className="online">
              Online
            </span>
          )
          : (
            <span className="offline">
              Offline
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastPromotionDate')}>
            <div className="d-flex flex-row">
              <span>Last Promotion</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastPromotionDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Last Promotion" 
            data={dateOptions}
            filters={lastPromotion}
            setFilters={setLastPromotion}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Promotion'),
      minWidth: '185px',
      sortField: 'lastPromotionDate',
      selector: ({ lastPromotionDate }) => (
        <span>{lastPromotionDate ? formatDate(new Date(lastPromotionDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastDemotionDate')}>
            <div className="d-flex flex-row">
              <span>Last Demotion</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastDemotionDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Demotion" 
            data={dateOptions} 
            filters={lastDemotion} 
            setFilters={setLastDemotion} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Demotion'),
      minWidth: '180px',
      sortField: 'lastDemotionDate',
      selector: ({ lastDemotionDate }) => (
        <span>{lastDemotionDate ? formatDate(new Date(lastDemotionDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastAssignmentDate')}>
            <div className="d-flex flex-row">
              <span>Last Assignment</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastAssignmentDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Assignment" 
            data={dateOptions} 
            filters={lastAssignment} 
            setFilters={setLastAssignment} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Assignment'),
      minWidth: '190px',
      sortField: 'lastAssignmentDate',
      selector: ({ lastAssignmentDate }) => (
        <span>{lastAssignmentDate ? formatDate(new Date(lastAssignmentDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('prevAssignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Previous Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('prevAssignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Previous Owner" 
            data={assignToShow}
            filters={isCheckPreviousOwner}
            setFilters={setIsCheckPreviousOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      omit: omitColumn('Previous Owner'),
      minWidth: '190px',
      sortField: 'prevAssignedToFullName',
      cell: ({ prevAssignedToFullName = '' }) => (prevAssignedToFullName?.length >= valueOutputCutLength.prevAssignedToFullName
        ? (
          <TooltipComponent
            title={prevAssignedToFullName}
            children={(
              <span className="full-name-text">
                { prettyCutOutputByCellWidth(prevAssignedToFullName, 'prevAssignedToFullName') }
              </span>
              )}
          />
        )
        : (
          <span className="full-name-text">
            {prevAssignedToFullName?.length ? prevAssignedToFullName : '-'}
          </span>
        )),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
            <div className="d-flex flex-row">
              <span>Last Login Date</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastLoginAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Login Date" 
            data={dateOptions} 
            filters={lastLoginAt} 
            setFilters={setLastLoginAt}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Login Date'),
      selector: (row) => (
        <span>
          {row.lastLoginAt 
            ? prettyCutOutputByCellWidth(formatDate(new Date(row.lastLoginAt), 'lastLoginAt')) 
            : '-'}
        </span>
      ),
      minWidth: '190px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastOnline')}>
            <div className="d-flex flex-row">
              <span>Last Online</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastOnline')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Online" 
            data={dateOptions}
            filters={lastOnline} 
            setFilters={setLastOnline}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Online'),
      selector: (row) => (
        <span>
          {row.lastOnline 
            ? prettyCutOutputByCellWidth(formatDate(new Date(row.lastOnline), 'lastOnline')) 
            : '-'}
        </span>
      ),
      minWidth: '190px',
      sortField: 'lastOnline',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('totalCalls')}>
            <div className="d-flex flex-row">
              <span>Total Calls</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('totalCalls')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter
            value={totalCallsRange ?? [0, 10000]}
            setValue={setTotalCallsRange}
            setFilters={setDebounceTotalCallsRange}
            filters={{}}
            maxValue={10000}
            filedName="totalCalls"
            debounceAmountRange
          />
        </div>
      ),
      omit: omitColumn('Total Calls'),
      minWidth: '140px',
      sortField: 'totalCalls',
      selector: ({ totalCalls = '-' }) => (
        <span>{totalCalls ?? '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastCallDate')}>
            <div className="d-flex flex-row">
              <span>Last Call</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastCallDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Call" 
            data={dateOptions} 
            filters={lastCallDate} 
            setFilters={setLastCallDate}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Call'),
      minWidth: '140px',
      sortField: 'lastCallDate',
      selector: ({ lastCallDate = '' }) => (
        <span>{lastCallDate ? formatDate(new Date(lastCallDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('updatedAt')}>
            <div className="d-flex flex-row">
              <span>Last Action</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('updatedAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Action" 
            data={dateOptions} 
            filters={lastActionDate} 
            setFilters={setLastActionDate} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      omit: omitColumn('Last Action'),
      minWidth: '160px',
      sortField: 'updatedAt',
      selector: ({ updatedAt = '' }) => (
        <span>{updatedAt ? formatDate(new Date(updatedAt)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastCommentAt')}>
            <div className="d-flex flex-row">
              <span>Last Comment</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastCommentAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Last Comment" 
            data={dateOptions}
            filters={lastCommentDate}
            setFilters={setLastCommentDate}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      cell: ({ lastCommentAt = '' }) => (
        <span>
          {lastCommentAt?.length 
            ? prettyCutOutputByCellWidth(formatDate(new Date(lastCommentAt)), 'lastComment') 
            : '-'}
        </span> 
      ),
      minWidth: '176px',
      omit: omitColumn('Last Comment'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('countryCode')}>
          <div className="d-flex flex-row">
            <span>Local Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('countryCode')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Local Time'),
      minWidth: '150px',
      selector: (row) => (
        <span>
          {row.countryCode 
            ? prettyCutOutputByCellWidth(formatDate(getLocalDataByCountryCode(row.countryCode) || new Date()), 'localTime') 
            : 'No country selected'}
        </span>
      ),
    },
    {
      name: 'Delete User',
      omit: omitColumn('Delete User'),
      minWidth: '176px',
      cell: (row) => (
        <>
          {(permissionName && permissionName.length) > 0 && permissionName.includes('delete_user')
          && (
            <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>
              <BsTrashFill role="button" color="white" size={18} />
            </button>
          )}
          {(permissionName && permissionName.length) > 0 && permissionName.includes('block_user')
            && (
              row.status === true
                ? <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'block')}>Block</button>
                : <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'unBlock')}>Unblock</button>
            )}
        </>
      ),
      sortable: false,
    },
  ], [
    clientFullname, 
    clientEmail, 
    clientPhone, 
    countriesToShow, 
    isCheckCountry,
    timeInitiatedFilter, 
    lastActionDate,
    firstTimeDepositFilter,
    isCheckFtdOwner,
    isCheckStdOwner,
    lastPromotion,
    lastDemotion,
    lastAssignment,
    isCheckPreviousOwner,
    lastLoginAt,
    lastCallDate,
    isCheckOnlineStatuses,
    lastCommentDate,
    allAffiliates, 
    affiliateFilter, 
    isCheckAssignTo, 
    assignToShow,
    salesStatuses,
    isCheckStatus,
    usersFilters,
    omitColumn,
    showComment,
    dateOptions,
    isAffiliateRule,
  ]);

  return columns;
};

import { 
  GET_USER_WITHDRAWAL_TYPE_BY_ID, 
  CREATE_USER_WITHDRAWAL_TYPE, 
  UPDATE_USER_WITHDRAWAL_TYPE, 
  DELETE_USER_WITHDRAWAL_TYPE, 
} from './userWithdrawalTypesTypes';

const initialState = {
  paginatedData: [],
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_WITHDRAWAL_TYPE_BY_ID:
      return {
        paginatedData: action.payload.paginatedData,
        totalCount: action.payload.totalCount,
      };
    case DELETE_USER_WITHDRAWAL_TYPE:
      return {
        ...state,
        paginatedData: state.paginatedData.filter((item) => item._id !== action.payload._id),
      };
    case CREATE_USER_WITHDRAWAL_TYPE:
      const plusOneTotalCount = state.totalCount + 1;
      return {
        paginatedData: [...state.paginatedData, action.payload],
        totalCount: plusOneTotalCount,
      };
    case UPDATE_USER_WITHDRAWAL_TYPE:
      return {
        ...state,
        paginatedData: state.paginatedData.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    default:
      return state;
  }
};

import React, {
  useCallback, useContext, useEffect,
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { ModalContext } from '../context';
import {
  selectWithdrawalMethodsOptions,
} from '../redux/withdrawalMethods/withdrawalMethodsSelectors';
import {
  fetchWithdrawalMethodsOptions,
  setBrandFilterCreator,
  setCountriesIncludedFilterCreator,
  setCountriesExcludedFilterCreator,
  setWithdrawalMethodFilterCreator,
  updateWithdrawalMethod,
} from '../redux/withdrawalMethods/withdrawalMethodsActions';
import { getBrands } from '../redux/brand/brandActions';
import { fetchCounties } from '../redux/countries/countriesActions';
import EditWithdrawalMethods from '../pages/WithdrawalMethods/EditWithdrawalMethods/EditWithdrawalMethods';
import { ModalFilters } from '../components';
import { useTableSorting, SortWrapper } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
import { prettyCutOutputByCellWidth, valueOutputCutLength, omitColumn } from './helper';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';

export const WithdrawalMethodsColumns = ({
  withdrawalMethodsQuery,
  setWithdrawalMethodsQuery,
  brandSearchFilter,
  countriesIncludedSearchFilter,
  countriesExcludedSearchFilter,
  withdrawalMethodSearchFilter,
  handleSort,
}) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditPermission = permissions && permissions.length > 0 && permissions.includes('withdrawal_methods_edit'); 

  const brands = useSelector((state) => state.brands);
  const countries = useSelector((state) => state.countries.countries);
  const withdrawalMethodsOptions = useSelector(selectWithdrawalMethodsOptions);

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    if (!countries.length) dispatch(fetchCounties()); 
    if (!withdrawalMethodsOptions.length) dispatch(fetchWithdrawalMethodsOptions());
  }, []);

  const getAllBrandsOptions = useCallback((brands) => brands.map(({ _id, name }) => ({
    _id,
    name,
  })), []);

  const getAllIncludedCountryOptions = useCallback((countries) => countries.map(({ _id, nicename }) => ({
    _id,
    nicename,
  })), []);

  const [brandsOptions, setBrandsOptions] = useState([]);
  const [includedCountriesOptions, setIncludedCountriesOptions] = useState([]);
  const [excludedCountriesOptions, setExcludedCountriesOptions] = useState([]);

  useEffect(() => {
    const options = getAllBrandsOptions(brands);
    setBrandsOptions(options);
  }, [brands]);
  
  useEffect(() => {
    const options = getAllIncludedCountryOptions(countries);
    setIncludedCountriesOptions(options);
    setExcludedCountriesOptions(options);
  }, [countries]);

  const handleBrandSearchFilterChange = (value) => {
    dispatch(setBrandFilterCreator(value));
  };

  const handleWithdrawalMethodChange = (value) => {
    dispatch(setWithdrawalMethodFilterCreator(value));
  };

  const handleIncludedCountiesChange = (value) => {
    dispatch(setCountriesIncludedFilterCreator(value));
  };

  const handleExcludedCountiesChange = (value) => {
    dispatch(setCountriesExcludedFilterCreator(value));
  };

  const editAction = ({ _id, name, ...rest }) => {
    showModal({
      headerContent: <h3>{`Edit "${name}" Withdrawal Method`}</h3>,
      bodyContent: (
        <EditWithdrawalMethods
          id={_id}
          name={name}
          {...rest}
        />
      ),
    });
  };


  const handleStatusChange = async (_id, name, status) => {
    await dispatch(
      updateWithdrawalMethod(_id, {
        name,
        status,
      }),
    );
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('name')}>
            <div className="d-flex flex-row">
              <span>Withdrawal Method</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={withdrawalMethodsOptions} 
            filters={withdrawalMethodSearchFilter} 
            setFilters={handleWithdrawalMethodChange} 
            tabName="Withdrawal Method" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={withdrawalMethodsQuery}
            setUserFilters={setWithdrawalMethodsQuery}
            style={{ left: '5px' }}
          />
        </div>
      ),
      minWidth: '210px',
      selector: ({ name }) => `${name}`,
      cell: ({ name }) => (
        <span>
          {name}
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brands')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brands')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={brandsOptions} 
            filters={brandSearchFilter} 
            setFilters={handleBrandSearchFilterChange} 
            tabName="Brand" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={withdrawalMethodsQuery}
            setUserFilters={setWithdrawalMethodsQuery}
          />
        </div>
      ),
      cell: ({ brand_details = [] }) => {
        const brandNames = brand_details.map(({ name }) => name).join(', ') || 'All';

        if (brandNames.length >= valueOutputCutLength.pspBrands) {
          return (
            <TooltipComponent
              title={brandNames}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(brandNames, 'pspBrands')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {brandNames}
          </span>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('included_country_first_name')}>
            <div className="newTablePositionHeader">
              <span>Included Countries</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('included_country_first_name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={includedCountriesOptions} 
            filters={countriesIncludedSearchFilter} 
            setFilters={handleIncludedCountiesChange} 
            tabName="Included Countries" 
            searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
            usersFilters={withdrawalMethodsQuery}
            setUserFilters={setWithdrawalMethodsQuery}
          />
        </div>
      ),
      cell: ({ included_countries_names = [] }) => {
        const includedCOuntriesNames = included_countries_names.join(', ') || 'All';

        if (includedCOuntriesNames.length >= valueOutputCutLength.country) {
          return (
            <TooltipComponent
              title={includedCOuntriesNames}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(includedCOuntriesNames, 'country')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {includedCOuntriesNames}
          </span>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('excluded_country_first_name')}>
            <div className="newTablePositionHeader">
              <span>Excluded Countries</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('excluded_country_first_name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={excludedCountriesOptions} 
            filters={countriesExcludedSearchFilter} 
            setFilters={handleExcludedCountiesChange} 
            tabName="Excluded Countries" 
            searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
            usersFilters={withdrawalMethodsQuery}
            setUserFilters={setWithdrawalMethodsQuery}
          />
        </div>
      ),
      cell: ({ excluded_countries_names = [] }) => {
        const excludedCOuntriesNames = excluded_countries_names.join(', ') || 'All';

        if (excludedCOuntriesNames.length >= valueOutputCutLength.country) {
          return (
            <TooltipComponent
              title={excludedCOuntriesNames}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(excludedCOuntriesNames, 'country')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {excludedCOuntriesNames}
          </span>
        );
      },
    },
    {
      name: 'Actions',
      cell: (row) => (
        isUserHasEditPermission && (
          <>
            <Switch 
              checked={row.status ?? false}
              color="success"
              onChange={({ target: { checked } }) => {
                handleStatusChange(row._id, row.name, checked);
              }}
            />
            <button
              className="btn btn-success btn-sm me-1 p-1"
              type="button"
              onClick={() => editAction(row)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </>

        )
      ),
    },
  ];

  return columns;
};

import React, {
  useState, useMemo, useEffect, useContext, useCallback, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Button, Row, Col, InputGroup, Form, 
} from 'react-bootstrap';
import Select from 'react-select';
import { createSpreadSetting } from '../../../../redux/userSpread/userSpreadActions';
import { getCfdSettings } from '../../../../redux/cfdSettings/cfdActions';
import { ModalContext } from '../../../../context';
import { colourStyles } from '../../../../helpers/styles';
import './style.css';
    
export function AddSpreadSettingModal({ userId }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [spreadSetting, setSpreadSetting] = useState({
    pairName: '',
    spreadMin: 0,
    spreadMax: 0,
  });
    
  const { cfdSettings: settings } = useSelector((state) => state.cfdSettings);
    
  useEffect(() => {
    dispatch(getCfdSettings());
  }, []);
    
  const isBtnDisabled = useMemo(() => !spreadSetting.pairName.length, [spreadSetting.pairName]);
    
  const closeModal = () => {
    setSpreadSetting({
      pairName: '',
      spreadMin: 0,
      spreadMax: 0,
    });
    hideModal();
  };

  const handleChangeField = (field, value) => {
    const changedValue = field !== 'pairName' ? value.replace(/[^-\d.]/g, '').replace(/^0+(\d)/, '$1') : value;

    setSpreadSetting((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
  };
    
  const submit = async () => {
    if (!spreadSetting.pairName) return;
  
    const { brokerSymbol } = settings.find(({ label }) => label === spreadSetting.pairName);
    const data = {
      userId,
      brokerSymbol,
      ...spreadSetting,
    };
    
    await dispatch(createSpreadSetting(data));
    closeModal();
  };
  
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !isBtnDisabled) {
      submit();
    }
  }, [isBtnDisabled, spreadSetting]);
  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
    
  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair Name</label>
            <Select
              styles={colourStyles}
              options={settings.map((setting) => ({
                value: setting._id,
                label: setting.label,
              }))}
              isSearchable
              onChange={({ label }) => handleChangeField('pairName', label)}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Spread Max</label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <InputGroup className="t-settings_input-group">
                <Form.Control 
                  value={spreadSetting.spreadMax} 
                  onChange={({ target: { value } }) => handleChangeField('spreadMax', value)}
                  // onKeyDown={(evt) => {
                  //   if (['e', 'E', '+'].includes(evt.key) || (evt.key === '-' && evt.target.selectionStart !== 0)) {
                  //     evt.preventDefault();
                  //   }
                  // }}
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Spread Min</label>
            <div className="input-wrapper" style={{ paddingBottom: 0 }}>
              <InputGroup className="mb-3 t-settings_input-group">
                <Form.Control 
                  value={spreadSetting.spreadMin} 
                  onChange={({ target: { value } }) => handleChangeField('spreadMin', value)}
                  // onKeyDown={(evt) => {
                  //   if (['e', 'E', '+'].includes(evt.key) || (evt.key === '-' && evt.target.selectionStart !== 0)) {
                  //     evt.preventDefault();
                  //   }
                  // }}
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </div>
  );
};
    
  

/* eslint-disable default-param-last */
import {
  GET_AFFILIATES,
  GET_AFFILIATE,
  ADD_AFFILIATE,
  EDIT_AFFILIATE,
  DELETE_AFFILIATE,
  GET_AFFILIATED_DEPOSITS,
  UPDATE_AFFILIATE_TOKEN,
  TOGGLE_AFFILIATE_STATE,
  ERROR_AFFILIATE_STATE,
  GET_AFFILIATES_TABLE_DATA,
} from './affiliateTypes';

const initialState = {
  affiliates: [],
  affiliate: {},
  totalCount: 0,
  affiliatedDeposits: [],
  updatedAffiliateToken: '',
  affiliatesFetched: false,
  affiliateAdded: false,
  affiliateFetched: false,
  affiliatedDepositsFetched: false,
  affiliateEditted: false,
  affiliateDeleted: false,
  error: false,
};

const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AFFILIATES_TABLE_DATA:
      return {
        ...state,
        affiliates: action.payload.affiliates,
        totalCount: action.payload.totalCount,
      };
    case GET_AFFILIATES:
      return {
        ...state,
        affiliates: action.payload,
        affiliatesFetched: true,
      };
    case GET_AFFILIATE:
      return {
        ...state,
        affiliate: action.payload,
        affiliateFetched: true,
      };
    case GET_AFFILIATED_DEPOSITS:
      return {
        ...state,
        affiliatedDeposits: action.payload,
        affiliatedDepositsFetched: true,
      };
    case ADD_AFFILIATE:
      return {
        ...state,
        affiliates: [...state.affiliates, action.payload],
        affiliateAdded: true,
      };
    case EDIT_AFFILIATE:
      return {
        ...state,
        affiliate: action.payload,
        affiliateEditted: true,
      };
    case DELETE_AFFILIATE:
      return {
        ...state,
        affiliates: state.affiliates.filter((item) => item._id !== action.payload),
        affiliateDeleted: true,
      };
    case UPDATE_AFFILIATE_TOKEN:
      return {
        ...state,
        updatedAffiliateToken: action.payload,
      };
    case TOGGLE_AFFILIATE_STATE:
      return {
        ...state,
        affiliatesFetched: false,
        affiliateAdded: false,
        affiliateFetched: false,
        affiliateEditted: false,
        affiliateDeleted: false,
        error: false,
      };
    case ERROR_AFFILIATE_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default affiliateReducer;

import {
  GET_SALES_STATUSES,
  GET_SALES_STATUS,
  ADD_SALES_STATUS,
  EDIT_SALES_STATUS,
  DELETE_SALES_STATUS,
  TOGGLE_STATE,
  ERROR_STATE,
  GET_SALES_STATUSES_TABLE_DATA,
} from './salesStatusTypes';

const initialState = {
  salesStatuses: [],
  salesStatus: {},
  statuses: [],
  totalCount: 0,
  salesStatusesfetched: false,
  salesStatusAdded: false,
  salesStatusfetched: false,
  salesStatusEditted: false,
  salesStatusDeleted: false,
  error: false,
};

// eslint-disable-next-line default-param-last
const salesStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_STATUSES_TABLE_DATA:
      return {
        ...state,
        statuses: action.payload.statuses,
        totalCount: action.payload.totalCount,
      };
    case GET_SALES_STATUSES:
      return {
        ...state,
        salesStatuses: action.payload,
        salesStatusesfetched: true,
      };
    case GET_SALES_STATUS:
      return {
        ...state,
        salesStatus: action.payload,
        salesStatusfetched: true,
      };
    case ADD_SALES_STATUS:
      return {
        ...state,
        salesStatuses: [...state.salesStatuses, action.payload],
        statuses: [action.payload, ...state.statuses],
        totalCount: state.totalCount + 1,
        salesStatusAdded: true,
      };
    case EDIT_SALES_STATUS:
      return {
        ...state,
        salesStatuses: state.salesStatuses.map((status) => {
          if (status._id === action.payload._id) return { ...action.payload };

          return status;
        }),
        statuses: state.statuses.map((status) => {
          if (status._id === action.payload._id) return { ...action.payload };

          return status;
        }),
        salesStatusEditted: true,
      };
    case DELETE_SALES_STATUS:
      return {
        ...state,
        salesStatuses: state.salesStatuses.filter((item) => item._id !== action.payload),
        statuses: state.statuses.filter((item) => item._id !== action.payload),
        totalCount: state.totalCount - 1,
        salesStatusDeleted: true,
      };
    case TOGGLE_STATE:
      return {
        ...state,
        salesStatusesfetched: false,
        salesStatusAdded: false,
        salesStatusfetched: false,
        salesStatusEditted: false,
        salesStatusDeleted: false,
        error: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default salesStatusReducer;

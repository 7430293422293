import {
  GET_USER_PSP, GET_PSP_OPTIONS, CREATE_USER_PSP, DELETE_USER_PSP, UPDATE_USER_PSP,
} from './userPspTypes';
import { apiHelper } from '../apiHelper';

export const getPSPOptions = () => async (dispatch) => {
  const res = await apiHelper('get', '/api/user-psp/options');

  if (res && res.data && res.data.success) {
    const { options } = res.data;

    dispatch({
      type: GET_PSP_OPTIONS,
      payload: options,
    });
  }
};

export const getUserPsps = (userId, sortParams) => async (dispatch) => {
  const res = await apiHelper('get', `/api/user-psp/query?userId=${userId}&sort=${sortParams}`);

  if (res && res.data && res.data.success) {
    const { psps } = res.data;

    dispatch({
      type: GET_USER_PSP,
      payload: psps,
    });
  }
};

export const createUserPsp = (data) => async (dispatch) => {
  const res = await apiHelper('post', '/api/user-psp', data);

  if (res && res.data && res.data.success) {
    const { psp } = res.data;

    dispatch({
      type: CREATE_USER_PSP,
      payload: psp,
    });
  }
};

export const updateUserPsp = (id, data) => async (dispatch) => {
  const res = await apiHelper('patch', `/api/user-psp/${id}`, data);

  if (res && res.data && res.data.success) {
    const { psp } = res.data;

    dispatch({
      type: UPDATE_USER_PSP,
      payload: psp,
    });
  }
};

export const deleteUserPsp = (id) => async (dispatch) => {
  const res = await apiHelper('delete', `/api/user-psp/${id}`);

  if (res && res.data && res.data.success) {
    const { psp } = res.data;

    dispatch({
      type: DELETE_USER_PSP,
      payload: psp,
    });
  }
};

export const statusColumns = [
  {
    name: 'Name',
    field: 'name',
    selected: true,
  },
  // {
  //   name: 'Type',
  //   field: 'type',
  //   selected: true,
  // },
  {
    name: 'Color',
    field: 'color',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
  
  

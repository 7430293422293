import React, {
  useContext, useEffect, useRef, useState, 
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { faPlus, faColumns, faCheck } from '@fortawesome/free-solid-svg-icons';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { ModalContext } from '../../context';
import { DownloadFile } from '../../components/DownloadFile';
import { getVoips, getVoipProviders, exportFilteredDataToFile } from '../../redux/voip/voipActions';
import { AddVoipModal } from './modals/addVoipModal';
import { getBrands } from '../../redux/brand/brandActions';
import {
  createCRMFilter, deleteCRMFilter, getCRMUsers, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { voipSchema } from '../../DataTableSchemas/voipSchema';
import { voipColumns } from '../../columnsDefaultConfig/voipColumns';
import { useDebounce } from '../../hooks/useDebounce';
import { DatatableFilters } from '../../components';
import { generateSortParams } from '../../helpers/sortHelper';


export function Voip() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);
  const { showModal } = useContext(ModalContext);

  const filtersStorageName = 'DataTable_users/voip_filters'; 
  const filterIdStorageName = 'DataTable_users/voip_filter_id'; 
  const columnsStorageName = 'DataTable_users/voip_columns';
  const paginationStorageName = 'DataTable_users/voip_pagination';
  const voipFilterJSON = localStorage.getItem(filtersStorageName);
  const voipColumnsJSON = localStorage.getItem(columnsStorageName);

  const voips = useSelector((state) => state.voip.voips);
  const totalCount = useSelector((state) => state.voip.totalCount);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const [loader, setLoader] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [columnConfig, setColumnConfig] = useState(voipColumns);
  
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [usersFilters, setUserFilters] = useState({});
  const [fullNameFilter, setFullNameFilter] = useState(null);
  const [providerFilter, setProviderFilter] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);
  const [extensionFilter, setExtensionFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditVoip = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('voip_add');


  const fullNameDounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0) && usersFilters[key] !== value) {
      setUserFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  const extensionDounceCallback = ({ value, key }) => {
    if ((value.length > 1 || value.length === 0) && usersFilters[key] !== value) {
      setUserFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(fullNameFilter, 1000, (value) => fullNameDounceCallback({ value, key: 'fullName' }));
  useDebounce(extensionFilter, 1000, (value) => extensionDounceCallback({ value, key: 'extension' }));

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setTableLoading(true);
    
    await dispatch(getVoips({
      page, limit: rowsPerPage, sort: sortParams, query: usersFilters, 
    }));

    setTableLoading(false);
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setCRMFilters = (filter) => {
    const {
      fullName, provider, extension, status, brand,
    } = filter;

    setUserFilters({
      fullName,
      provider,
      extension,
      status,
      'brand name': brand,
    } || {});

    setFullNameFilter(fullName || null);
    setProviderFilter(provider || []);
    setExtensionFilter(extension || null);
    setBrandFilter(brand || []);
    setStatusFilter(status || []);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };


  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    if (!filters) {
      toastError('Select atleast one filter to complete this action.');

      return;
    }

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || null,
      extension: filters.fullName || null,
      provider: filters.provider || [],
      status: filters.status || [],
      brand: filters['brand name'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const handleClear = () => {
    setExtensionFilter(null);
    setFullNameFilter(null);
    setStatusFilter([]);
    setBrandFilter([]);
    setProviderFilter([]);
    setUserFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };


  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        status: filters.status || [],
        brand: filters['brand name'] || [],
        extension: filters.extension || '',
        provider: filters.provider || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handlePageChange = async (page) => {
    setPage(page);
    setTableLoading(true);
    
    await dispatch(getVoips({
      page, limit: rowsPerPage, sort: sortParams, query: usersFilters, 
    }));

    setTableLoading(false);
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getVoips({ page, limit: rowsPerPage, sort: sortParams }));
    setTableLoading(false);
  };

  const handleExportVoips = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (voips.length && columns.length) {
      setTableLoading(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportFilteredDataToFile(voips, columns, fileType); 
      setTableLoading(false);
    } else {
      toast.error('There is nothing to download.');
    }
  };

  const setStoredPagination = () => {
    const pagePaginationJSON = localStorage.getItem(paginationStorageName);
    if (pagePaginationJSON) {
      const pagePagination = JSON.parse(pagePaginationJSON);
      setRowsPerPage(pagePagination.limit || 25);
      setPage(pagePagination.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    if (voipFilterJSON) {
      const filters = JSON.parse(voipFilterJSON);
      setUserFilters(filters || {});

      setFullNameFilter(filters.fullName || null);
      setProviderFilter(filters.provider || []);
      setExtensionFilter(filters.extension || null);
      setBrandFilter(filters['brand name'] || []);
      setStatusFilter(filters.status || []);
    }
  };


  const setStoredColumnsData = () => {
    if (voipColumnsJSON) {
      const columns = JSON.parse(voipColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredPagination();
    setStoredFilterData();
    setStoredColumnsData();
  };

  useEffect(() => {
    getAllStoredData();

    Promise.allSettled([
      dispatch(getVoipProviders()),
      dispatch(getBrands()),
      dispatch(getCRMUsers()),
    ]);

    setLoader(false);
  }, []);


  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(usersFilters));
        setTableLoading(true);
  
        await dispatch(getVoips({
          page, limit: rowsPerPage, sort: sortParams, query: usersFilters, 
        }));
  
        setTableLoading(false);
      }
    }
   
    fetchData();
  }, [page, rowsPerPage, sortParams, isPaginationDT, usersFilters]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const columns = voipSchema({
    columnConfig,
    fullNameFilter,
    setFullNameFilter,
    providerFilter,
    setProviderFilter,
    usersFilters,
    setUserFilters,
    extensionFilter,
    setExtensionFilter,
    brandFilter,
    setBrandFilter,
    statusFilter,
    setStatusFilter,
    handleSort,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>VoIP Configuration</h3>

        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanEditVoip && (
                <button
                  className="btn-primary_light"
                  type="button"
                  onClick={() => showModal({
                    bodyContent: <AddVoipModal />,
                    headerContent: <h3>Add VoIP</h3>, 
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="xs"
                  />
                  Add New
                </button>
              )}
            </div>
            <div className="secondary_btn-row">
              {crmFilters && (
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              )}
              <button
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button 
                type="button" 
                className="btn-secondary_dark iconed"
                onClick={handleRefresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <Dropdown className="leads-columns-dropdown">
                <Dropdown.Toggle 
                  variant="" 
                  className="btn-secondary_dark"
                >
                  Columns
                </Dropdown.Toggle>
                <Dropdown.Menu className="primary-menu">
                  <ul className="leads-columns-list">
                    {columnConfig.map((column) => (
                      <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                        {column.selected ? (
                          <FontAwesomeIcon
                            size="xs"
                            icon={faCheck}
                            color="#6E7F95"
                          />
                        ) : null}
                        <span className="leads-columns-option__name">{column.name}</span>
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <DownloadFile handleExport={handleExportVoips} />
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          <DataTable
            columns={columns}
            data={voips}
            pagination
            paginationServer
            highlightOnHover
            paginationTotalRows={totalCount}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[25, 50, 200, 500]}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            persistTableHead
            theme="solarizedd"
            progressPending={tableLoading}
            progressComponent={<div className="datatable-loader__background" />}
          />
        </div>
          
      </div>
    </div>
  );
}


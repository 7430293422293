import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDebounce } from '../../hooks/useDebounce';
import { OpenOrdersSchema } from '../../DataTableSchemas/OpenOrdersSchema/OpenOrdersSchema';
import { openOrdersColumns } from '../../columnsDefaultConfig';
import { userOpenOrdersColumns } from '../../columnsDefaultConfig/userOpenOrdersColumns';
import { DatatableColumns, DatatableFilters } from '..';
import { SelectComponent } from '../SelectComponent/SelectComponent';
import {
  createCRMFilter, deleteCRMFilter, getSubCRMUsers, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { ModalContext } from '../../context';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { DownloadFile } from '../DownloadFile';
import {
  getCfdOpenOrders, exportFilteredDataToFile, massStopOrders, 
} from '../../redux/cfdOrder/cfdOrderActions';
import socket from '../../services/socket';
import { EditOpenOrderModal } from './EditOpenOrderModal';
import { MassEditOpenOrdersModal } from './MassEditOpenOrdersModal';
import { OpenOrderModal } from '../../pages/Users/OpenOrderModal';
import { generateSortParams } from '../../helpers/sortHelper';

export function OpenOrdersTable({
  userId = '', localStorageConfig: {
    filtersStorageName = '',
    filterIdStorageName = '', 
    columnsStorageName = '',
    paginationStorageName = '', 
  }, 
  isPositionUnset = false,
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);
  const { showModal, hideModal } = useContext(ModalContext);

  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState({});

  const columnConfigFile = userId && userId.length ? userOpenOrdersColumns : openOrdersColumns;

  const [columnConfig, setColumnConfig] = useState(columnConfigFile);
  const [filters, setFilters] = useState({});
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [crmUserFilter, setCrmUserFilter] = useState([]);
  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [userNameFilter, setUserNameFilter] = useState(null);
  const [openOrderId, setOpenOrderId] = useState(null);
  const [timeOpenedFilter, setTimeOpenedFilter] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [actionName, setActionName] = useState('');
  const [orderIdToPnl, setOrderIdToPnl] = useState({});
  const [inputFieldError, setInputFieldError] = useState({
    userIdIsNotValid: false,
    fullNameIsNotValid: false,
    errorMessage: '',
  });
  const [toggleCleared, setToggleCleared] = useState(false);
  const [ordersStore, setOrdersStore] = useState([]);
  const [isCheckOnlineStatuses, setIsCheckOnlineStatuses] = useState([]);

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const { orders, totalCount } = useSelector((state) => state.cfdOrders);
  const isUserCanSeeOpenOrders = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('open_orders_add');
  const isUserCanEditOpenOrders = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('open_orders_edit');
  const isUserCanStopOpenOrders = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('open_orders_edit');

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(
    userNameFilter, 
    1000,
    (value) => debounceCallback({ value, key: 'clientName' }),
  );

  useDebounce(
    openOrderId, 
    1000,
    (value) => debounceCallback({ value, key: 'customId' }),
  );

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedOrders([]);
  };

  const setStoredPagination = () => {
    const openOrdersRowsJSON = localStorage.getItem(paginationStorageName);
    if (openOrdersRowsJSON) {
      const filterRows = JSON.parse(openOrdersRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };
  
  const handleClear = () => {
    setCoinPairsFilter([]);
    setUserNameFilter(null);
    setOpenOrderId(null);
    setCrmUserFilter([]);
    setTradingTypeFilter([]);
    setTimeOpenedFilter([]);
    setIsCheckOnlineStatuses([]);

    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersJSON);
    setFilters(filters);

    setCoinPairsFilter(filters.symbol || []);
    setTradingTypeFilter(filters['trading type'] || []);
    setIsCheckOnlineStatuses(filters['online status'] || []);
    setUserNameFilter(filters.clientName || null);
    setOpenOrderId(filters.customId || null);
    setCrmUserFilter(filters['assigned to'] || []);
    setTimeOpenedFilter(filters['time opened'] || []);
  };

  const setCRMFilters = (filter) => {
    const {
      currency,
      assignedTo,
      type,
      timeOptionIds,
      fullName,
      transactionId,
      userOnlineStatus,
    } = filter;

    setUserNameFilter(fullName || null);
    setOpenOrderId(transactionId || null);
    setCoinPairsFilter(currency || []);
    setTradingTypeFilter(type || []);
    setTimeOpenedFilter(timeOptionIds || []);
    setCrmUserFilter(assignedTo || []);
    setIsCheckOnlineStatuses(userOnlineStatus || []);
    setFilters({
      clientName: fullName,
      customId: transactionId,
      symbol: currency,
      'trading type': type,
      'assigned to': assignedTo,
      'time opened': timeOptionIds,
      'online status': userOnlineStatus,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
  }; 

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getCfdOpenOrders({
      page, rowsPerPage, sortParams, filters, userId, 
    }));
    setTableLoading(false);
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      transactionId: filters?.customId || '',
      currency: filters.symbol || [],
      assignedTo: filters['assigned to'] || [],
      type: filters['trading type'] || [],
      timeOptionIds: filters['time opened'] || [],
      fullName: filters.clientName || '',
      userOnlineStatus: filters['online status'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.symbol || [],
        assignedTo: filters['assigned to'] || [],
        type: filters['trading type'] || [],
        timeOptionIds: filters['time opened'] || [],
        fullName: filters.clientName || '',
        transactionId: filters?.customId || '',
        userOnlineStatus: filters['online status'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (orders.length && columns.length) {
      setTableLoading(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportFilteredDataToFile(orders, columns, fileType); 
      setTableLoading(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    setTableLoading(true);
    
    Promise.allSettled([
      dispatch(getSubCRMUsers(id, uid)),
      dispatch(getCfdSettings(sortParams)),
    ]);

    if (filtersJSON) {
      setStoredFilterData();
    }

    setStoredColumnsData();
    setStoredPagination();

    setTableLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      socket.emit('getOnlineCRMUsers');
    }, 1000);
    
    socket.on('recieveOnlineCRMUsers', ({ onlineUsers = {} }) => {
      setOnlineUsers(onlineUsers);
    });

    return () => {
      socket.off('recieveOnlineCRMUsers');
    };
  }, [socket]);

  useEffect(() => {
    setOrdersStore((store) => {
      const updatedStore = store.filter(
        (storeItem) => !orders.some((orderItem) => orderItem._id === storeItem._id),
      );
      return [...updatedStore, ...orders];
    });
  }, [orders]);

  useEffect(async () => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));

      setTableLoading(true);
      await dispatch(getCfdOpenOrders({
        page, rowsPerPage, sortParams, filters, userId, 
      }));
      setTableLoading(false);
    }
  }, [page, rowsPerPage, sortParams, isPaginationDT, filters]);

  useEffect(() => {
    if (!ordersStore.length) return;
    const uniqueId = Date.now();

    socket.emit('getOpenOrderMarket', { orders: ordersStore, uniqueId });

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setOrderIdToPnl(data);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      socket.emit('removeOpenOrdersMarketListener');
      setOrderIdToPnl([]);
    };
  }, [ordersStore]);

  const handleOrderTableSelect = async ({ selectedRows }) => {
    setSelectedOrders(selectedRows);
  };

  const handleMassStop = () => {
    Swal.fire({
      title: `Are you sure want to stop ${selectedOrders.length} orders?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const orderIds = selectedOrders.map(({ _id }) => (_id));
        const stopData = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(orderIdToPnl)) {
          if (orderIds.includes(key)) {
            stopData[key] = value.marketPrice;
          }
        }

        dispatch(massStopOrders(stopData));
        clearRows();
      }
    });
  };

  const handleMassEdit = () => {
    showModal({ 
      bodyContent: <MassEditOpenOrdersModal 
        orders={selectedOrders} 
        hideModal={hideModal} 
        clearRows={clearRows}
        handleRefresh={handleRefresh}
      />, 
      headerContent: <h5>Edit Orders</h5>, 
    });
  };

  const massActionNameToMethod = {
    'Mass Stop Orders': handleMassStop,
    'Mass Edit Orders': handleMassEdit,
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const columns = OpenOrdersSchema({
    onlineUsers,
    columnConfig,
    isCheckOnlineStatuses,
    setIsCheckOnlineStatuses,
    userNameFilter,
    setUserNameFilter,
    filters,
    setFilters,
    tradingTypeFilter,
    setTradingTypeFilter,
    crmUserFilter,
    setCrmUserFilter, 
    coinPairsFilter, 
    setCoinPairsFilter,
    EditOpenOrderModal,
    timeOpenedFilter, 
    setTimeOpenedFilter,
    orderIdToPnl,
    userId,
    openOrderId,
    setOpenOrderId,
    inputFieldError,
    setInputFieldError,
    handleRefresh,
    handleSort,
  });

  const setDefaultOptions = () => {
    const options = [
      { value: 'Select', label: 'Select' },
    ];

    if (isUserCanEditOpenOrders) options.push({ value: 'Mass Edit Orders', label: 'Mass Edit Orders' });
    if (isUserCanStopOpenOrders) options.push({ value: 'Mass Stop Orders', label: 'Mass Stop Orders' });

    return options;
  };

  const defaultOptions = setDefaultOptions();

  return (
    <div>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {isUserCanSeeOpenOrders && (
            <button 
              type="button" 
              className="btn-primary_light"
              onClick={() => showModal({ 
                bodyContent: <OpenOrderModal hideModal={hideModal} />, 
                headerContent: <h5>Open Order</h5>, 
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add New
            </button>
            )}
          </div>
          <div className="secondary_btn-row">
            {crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            />
            )}
            <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
              <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
              <RefreshIcon sx={{ fontSize: '20px' }} />
            </button>
          </div>
          <div className="secondary_btn-row">
            <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          </div>
          <div className="secondary_btn-row">
            <DownloadFile isPositionUnset={isPositionUnset} handleExport={handleExportOrders} />
          </div>
        </div>
        {((isUserCanStopOpenOrders || isUserCanEditOpenOrders) && selectedOrders.length > 0) && (
          <div className="dt-actions__container">
            <div className="dt-actions__selected-counter">{`Actions: selected ${selectedOrders.length} ${selectedOrders.length > 1 ? 'records' : 'record'}`}</div>
            <div className="main_actions-row">
              <SelectComponent
                value={actionName || 'Select'}
                options={defaultOptions}
                onChange={({ target: { value } }) => setActionName(value)}
              />
              {!!actionName.length && actionName !== 'Select' && (
              <button 
                disabled={!selectedOrders.length}
                type="button"
                className="secondary-btn"
                onClick={massActionNameToMethod[actionName]}
              >
                Apply
              </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
        {isPaginationDT && (
          <DataTable
            columns={columns}
            data={orders}
            pagination
            paginationServer
            highlightOnHover
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            paginationTotalRows={totalCount}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            onSelectedRowsChange={handleOrderTableSelect}
            clearSelectedRows={toggleCleared}
            selectableRows
            sortServer
            persistTableHead
            selectableRowsHighlight
            selectableRowsVisibleOnly
            theme="solarizedd"
            className="specific-field-table custome-table-scroll"
            progressPending={tableLoading}
            progressComponent={<div className="datatable-loader__background" />}
          />
        )}
      </div>
    </div>
  );
}
